import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

import PrivateRoute from './auth/PrivateRoute';
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import Loader from './components/common/loader';
import ProductList from './pages/product/list';
import PDVList from './pages/pdv/list';
import PDVForm from './pages/pdv/form';
import SurveysList from './pages/surveys/list';
import VisitsList from './pages/visits/list';
import UserDetails from './pages/users/details';
import UsersList from './pages/users/list';
import UsersGroups from './pages/users/groups/list';
import UsersGroupsForm from './pages/users/groups/form';
import CampaignsList from './pages/campaigns/list';
import CampaignsReport from './pages/campaigns-report/campaigns-report';
import CampaignForm from './pages/campaigns/form';
import VisitDetails from './pages/visits/details';
import ComboList from './pages/combo/list';
import CombosGenerate from './pages/combo/generate/index';
import PowerBiReport from './pages/powerbi/powerbiReport';
import Error404 from './pages/errors/error404';
import Error401 from './pages/errors/error401';
// import CampaignVisits from './pages/campaigns/visits';

import './assets/css/styles.css';
import './assets/css/form-styles.css';
import './assets/css/loader.css';
import ExportVisitsPage from './pages/visits/export/export-visits';
import DownloadExportVisitsPage from './pages/visits/export/index';

function Root() {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="App">
            <Provider store={store}>
                <App>
                    <Switch>
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`} component={CampaignsList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/campaigns/list`} component={CampaignsList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/campaigns/new`} component={CampaignForm} />
                        {/* <PrivateRoute path={`${process.env.PUBLIC_URL}/campaigns/:id/visits`} exact component={CampaignVisits} /> */}
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/campaigns/details/:id`} component={CampaignForm} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/campaigns/:id`} component={CampaignForm} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/campaigns/:id/report`} component={CampaignsReport} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/combo/generate`} component={CombosGenerate} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/product/list`} component={ProductList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/pdv/list`} component={PDVList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/pdv/:id`} component={PDVForm} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/surveys/list`} component={SurveysList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/visits/list/campaign/:campaignId`} component={VisitsList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/visits/list`} component={VisitsList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/visits/details/:id`} component={VisitDetails} />
                        {/* <PrivateRoute exact path={`${process.env.PUBLIC_URL}/visits/report/export`} component={ExportVisitsPage} /> */}
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/visits/reports`} component={DownloadExportVisitsPage} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/users/list`} component={UsersList} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/users/groups`} component={UsersGroups} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/users/groups/:id`} component={UsersGroupsForm} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/users/groups/details/:id`} component={UsersGroupsForm} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/users/details/:id`} component={UserDetails} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/combo/list`} component={ComboList} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/report/:reportId/:pageName`} component={PowerBiReport} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/401`} component={Error401} />
                        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/404`} component={Error404} />
                        <PrivateRoute component={Error404} />
                    </Switch>
                </App>
            </Provider>
        </div>
    );
}

ReactDOM.render(
    <BrowserRouter>
        <Auth0ProviderWithHistory>
            <Root />
        </Auth0ProviderWithHistory>
    </BrowserRouter>, document.getElementById('root')
);

serviceWorker.unregister();
