import React, { Fragment, useState } from 'react';
import axios from "axios";
import Breadcrumb from '../../components/common/breadcrumb';
import ImportButton from '../../components/common/buttons/import';
import UploadModal from '../../components/common/upload/modal';
import { Alert } from "../../components/common/modals/modals.js";
import { useAuth0 } from "@auth0/auth0-react";

const ProductsList = () => {
    const { getAccessTokenSilently } = useAuth0();
    // import modal configuration
    const [uploadModal, setUploadModal] = useState();
    const [modalData, setModalData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [init, setInit] = React.useState(true);

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
        setModalData(undefined);
        setLoading(false);
        setError(undefined);
        setInit(true);
    }

    const importProducts = async(file) => {
        setLoading(true);
        var formData = new FormData();
        formData.append("file", file);
        var accessToken = await getAccessTokenSilently();

        axios.put(`/api/Product/Import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              }
        })
        .then(response => {
            setLoading(false);
            if(response.data.status === 200){
                setModalData(response.data.data)
            }else{
                setError(response.data.title);
            }
            setInit(false);
          })
        .catch(error => {
            setUploadModal(false);
            Alert("Não foi possível completar sua solicitação", "", "error");
        })
      };
    
    return (
        <Fragment>
            <Breadcrumb title="Produtos" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <h5>Listagem de Produtos</h5>
                                <ImportButton value="Produtos" onclick={toggleUploadModal} />
                            </div>
                            <div className="card-body datatable-react">
                            </div>
                            <UploadModal value="Produtos" isOpen={uploadModal} loading={loading} toggle={toggleUploadModal} sendImport={importProducts} error={error} data={modalData} init={init} setInit={setInit} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductsList;