import { states } from "../../../utils/helper";

export const surveysFilter = [
    {
        name: 'routeIdentifier',
        value: '',
        title: 'Combo ID'
    },
    {
        name: 'pointOfSaleName',
        value: '',
        title: 'Nome do Ponto de Venda'
    },
    {
        name: 'state',
        value: -1,
        title: 'Estado',
        type: "select",
        options: states
    },
    {
        name: 'address',
        value: '',
        title: 'Endereço'
    },
    {
        name: 'city',
        value: '',
        title: 'Cidade'
    },
    {
        name: 'region',
        value: '',
        title: 'Região'
    },
    {
        name: 'value',
        value: '',
        title: 'Valor (R$)',
        type: 'money'
    },
    {
        name: 'visitsAmount',
        value: null,
        title: 'Qntd de Visitas',
        type: 'number'
    },
    {
        name: 'routeExpirationDate',
        value: '',
        title: 'Data de expiração',
        type: 'date'
    },
    {
        name: 'registerDate',
        valueStart: null,
        valueEnd: null,
        title: 'Data de criação',
        type: 'date-range-picker'
    },
    {
        name: 'surveyStatus',
        value: -1,
        title: 'Status da Pesquisa',
        type: "select",
        options: [
            {
                name: 'Pesquisa ativa com combo expirado',
                value: 1
            },
            {
                name: 'Pesquisa ativa com combo não expirado',
                value: 2
            },
        ],
        width: '300px'
    },
    {
        name: 'campaigns',
        value: [],
        title: 'Campanhas',
        options: [],
        type: 'multiselect'
    },
];