import React from 'react';
import { XCircle } from 'react-feather';

const Survey = ({item, handleChange, removeItem, brands}) => {

    return (
        <div className="flex">
     
            <div className="field-filter-item">

                <label className="label-filter">Pergunta</label>
                <br/>                
                <textarea className="form-control" type="text" placeholder="Pergunta" value={item.title} onChange={handleChange} id={"title-" + item.id} />

            </div>


            <div className="field-filter-item">

                <label className="label-filter">Formato resposta</label>
                <br/>
                <select style={{width: '200px'}} value={item.answerType} onChange={handleChange} id={"answerType-" + item.id}>
                    <option value="0">Texto</option>
                    <option value="1">Câmera</option>
                    <option value="2">Decimal com S/N</option>
                    <option value="3">Sim/Não</option>
                    <option value="4">QRCode</option>
                    <option value="5">Código de barras</option>
                    <option value="6">Câmera - a granel</option>
                </select>

            </div>

            <div className="field-filter-item">

                <label className="label-filter" value={item.title}>Marca</label>
                <br/>
                <select style={{width: '200px'}} id={"brandId-" + item.id} defaultValue="0" onChange={handleChange}>
                    <option value="0" disabled>Selecione</option>
                    {brands.map((option, index) =>
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                    )}
                </select>

            </div>

            <div className="field-filter-item">
                <label className="label-filter" htmlFor="maxDistance">Altura dos produtos (cm)</label>
                <br/>
                <input className="form-control" name="productHeight" type="number" min={0} placeholder="0" value={item.productHeight}  onChange={handleChange} id={"productHeight-" + item.id} />
            </div>

            <div className="field-filter-item">
                <label className="label-filter" htmlFor="maxDistance">Largura dos produtos (cm)</label>
                <br/>
                <input className="form-control" name="productWidth" type="number" min={0} placeholder="0" value={item.productWidth}  onChange={handleChange} id={"productWidth-" + item.id} />
            </div>
            
            <div className="field-filter-item remove-item" onClick={() => removeItem(item.id)}>
                <XCircle/>
            </div>

        </div>
      );
}

export default Survey;