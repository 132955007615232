import React from 'react';

const DefaultButton = ({description, icon, onClick}) => {
    return (
        <button className="btn btn-pill btn-outline-secondary flex btn-icon alg-center" type="button" onClick={onClick}>
            {icon}
            {description}
        </button>
    )
}

export default DefaultButton;