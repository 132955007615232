import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import BlackMarker from '../../../assets/images/map_marker_black.png';

const LoadingContainer = (props) => (
    <div>Carregando mapa...</div>
)

const style = {
    position: 'relative',
    width: '100%',
    height: '360px',
}
export class MapContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
        markers: 
          [  
            {
                name: "Localização atual",
                id: '123',
                position: {
                    lat: this.props.lat,
                    lng: this.props.lon
                }
            }
          ],
        center: { lat: this.props.lat, lng: this.props.lon },
        activeMarker: {},
        selectedPlace: {},
        showingInfoWindow: false,
        bounds: new this.props.google.maps.LatLngBounds()
        }

        if(this.props.searchedItems.length > 0){
            this.state.showingInfoWindow = false;
            this.props.setLatitude(this.props.searchedItems[0].latitude);
            this.props.setLongitude(this.props.searchedItems[0].longitude);
        }

    }

    // updateMap = (items) => {
    //   if(items.length > 0) {
    //     var newBounds = new this.props.google.maps.LatLngBounds();

    //     items.forEach((place) => {
    //       newBounds.extend(new this.props.google.maps.LatLng(
    //         place.latitude,
    //         place.longitude,
    //       ));
    //     });
    //     console.log('new bounds', newBounds)
    //     this.setState({
    //       bounds: newBounds
    //     })
    //   }
    // }

    shouldComponentUpdate(nextProps, nextState){
      if(this.props.alwaysRender)
        return true;
        
      if(nextProps.lat !== this.props.lat){
        return true
      }

      if(nextProps.searchedItems.length !== this.props.searchedItems.length){
        return true
      }

      if(nextState.bounds !== this.state.bounds){
        return true
      }

      if(nextState.markers[0].position?.lat !== this.state.markers[0].position?.lat){
        return true
      }
      
      if(nextState.showingInfoWindow !== this.state.showingInfoWindow){
        return true
      }

      if(nextState.selectedPlace?.name !== this.state.selectedPlace?.name){
        return true
      }

      if(nextProps.searchedItems?.length > 10)
        return true;

      return false;
    }

    componentDidUpdate(prevProps) {
      // observing when locations items changes to update initial location
      if (prevProps.searchedItems !== this.props.searchedItems) {
        
        if(this.props.searchedItems.length > 0){
          var lat = this.props.searchedItems[0].latitude;
          var lon = this.props.searchedItems[0].longitude;

          if(lat !== undefined || lon !== undefined)
          this.setState({
            center: { lat: lat, lng: lon }
          });
          
          var bounds = new this.props.google.maps.LatLngBounds();
          for (var i = 0; i < this.props.searchedItems.length; i++) {
            var bound = {
              lat: this.props.searchedItems[i].latitude,
              lng: this.props.searchedItems[i].longitude
            }
            bounds.extend(bound);
          }
  
          this.setState({bounds: bounds});
          
        }
      }
    }

    onMarkerDragEnd (coord, index)  {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        this.setState(prevState => {
            const markers = [...this.state.markers];
            markers[index] = {
                ...markers[index],
                position: { lat, lng }
            };
            return { markers };
        });

        this.props.setLatitude(lat);
        this.props.setLongitude(lng);
    };


  onMarkerClick = (props, marker) => {
    this.setState({
        activeMarker: marker,
        selectedPlace: props,
        showingInfoWindow: true
      });
  }

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = (props, marker, e) => {

        this.props.setLatitude(e.latLng.lat());
        this.props.setLongitude(e.latLng.lng());

        var lat = e.latLng.lat();
        var lon = e.latLng.lng();

        this.setState(prevState => {
            const markers = [...this.state.markers];
            markers[0] = {
                ...markers[0],
                position: { lat, lon }
            };
            return { markers };
        });
        

    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });

  };

  render() {
    if (!this.props.loaded) return <div>Loading...</div>;


    return (
      <div>
        {this.props.showPDVTypeLegend && <div className="flex mt-10 mb-5 align-items">
          <img src={BlackMarker} alt='legend-icon' />
          <p><b>SUPER PET</b> ou <b>KEY PET</b></p>
        </div>}
          <Map  
              initialCenter={this.state.center}       
              center={this.state.center}       
              google={this.props.google}
              onClick={this.onMapClicked}
              style={{ height: "100%", position: "relative", width: "100%" }}
              zoom={13}
              defaultZoom={0}
              maxZoom={100}
              minZoom={0}
              bounds={this.state.bounds}
              containerStyle={style}
            >

        {this.props.searchedItems.map((location, index) => 
          location.PDVType === 'SUPER PET' || location.PDVType === 'KEY PET'
          ?
            <Marker
              key={location.id}
              name={location.name}
              type={location.type}
              onClick={this.onMarkerClick}
              icon={BlackMarker}
              position={{ lat: location.latitude, lng: location.longitude }}
            />
            :
            <Marker
              key={location.id}
              name={location.name}
              type={location.type}
              onClick={this.onMarkerClick}
              position={{ lat: location.latitude, lng: location.longitude }}
            />
        )}

        {/* Current location */}	
        {this.props.showCurrentLocation != false && <Marker
            onClick={this.onMarkerClick}	
            name="Localização atual"	
            position={{lat: this.props.lat, lng: this.props.lon}}   
            draggable={true}	
            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, 0)}	
            icon={{
                url: 'https://maps.google.com/mapfiles/ms/icons/' + 'ltblue-dot' + '.png'
              }}
        />	}

        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >
          <div>
                <span className="maps-title">{this.state.selectedPlace.name}</span>
                {this.state.selectedPlace.type?.length > 0 && <p className="maps-type">{this.state.selectedPlace.type}</p> }
          </div>
        </InfoWindow>
      </Map>      
      </div>
    );
  }
}
export default GoogleApiWrapper({
    apiKey: ("AIzaSyABXLpuiOBStO3XJh8aoyNaIlEILGZ7pss"),
    LoadingContainer: LoadingContainer
  })(MapContainer)