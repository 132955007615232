import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useService } from "../../../hooks/useService";
import { Check } from 'react-feather';

const AuthorizePDVModal = ({toggle, isOpen, id}) => {

    const [loading, setLoading] = React.useState(false);
    const [observation, setObservation] = React.useState("");
    const [obsError, setObsError] = React.useState(false);
    const service = useService();

    React.useEffect(() => {
        setObservation('');
    }, [])


    const authorizePDV = async (approved) => {

        if(loading)
            return;

        if(observation === ''){
            setObsError(true);
            return;
        }

        const payload = {
            authorizedByName: observation,
            whenDate: new Date(),
            pointOfSaleId: id
        }

        setLoading(true);

        await service.post(`/api/pointOfSale/authorize`, payload)
            .then(response => {
                let success = false;
                if (response.status === 200 && response.data.status == 200) {
                    success = true;
                }

                setObservation("");
                setLoading(false);

                toggle(null, success, approved);
            })
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '50%' }}>
                <ModalHeader toggle={toggle}>Autorizar Ponto de Venda</ModalHeader>
                <ModalBody>

                    <div className="field-filter-item">
                        <p>Nome do responsável pela autorização: </p>
                        <input type="text" className="w-100 form-control" disabled={loading} value={observation} onChange={({target}) => setObservation(target.value)} style={{border: obsError && observation == '' ? '2px solid red' : 'none'}} />
                    </div>


                    {obsError && observation === '' && <p className="error-color">*Campo obrigatório</p>}
                     
                </ModalBody>

                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }

                <ModalFooter className="modal-footer">
                    <div className="centralize flex">
                        
                        <div className="confirm-button"  style={{ cursor: loading ? 'not-allowed' : 'pointer' }} onClick={authorizePDV}>
                            <Check className="" /><span className="pl-10">Confirmar</span>
                        </div>
                        
                    </div>
                </ModalFooter>
            </Modal>
            
        </>
    );
}

export default AuthorizePDVModal;