import React from 'react';
import './switch.css';

export const Switch = ({ isOn, handleToggle, id, isOnColor = '#1ea6ec', isOffColor = '#adb5bd'  }) => {

  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch` + id}
        type="checkbox"
      />
      <label
        style={{background: isOn ? isOnColor : isOffColor }}
        className="react-switch-label"
        htmlFor={`react-switch` + id}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;