import React from 'react';
import { X } from 'react-feather';
import DatePicker from '../date-picker/date-range-picker';
import MultiSelect from "../form/multi-select/index";
import { inputCurrencyConvertion } from '../../../utils/helper';

const delay = ms => new Promise(res => setTimeout(res, ms));

const DynamicFilter = ({ handleSearch, filters, handleFilterChange, handleReset, localStorageName,  saveFilterState}) => {

    const [showDataPicker, setShowDataPicker] = React.useState(true);

    React.useEffect(() => {
        async function cleanDatePicker() {
            setShowDataPicker(false);
            await delay(0.01);
            setShowDataPicker(true);
        }

        filters.map(async t => {
            if (t.type === 'date-range-picker' && (t.name === 'finishDate' || t.name === 'registerDate')) {
                if(t.valueStart === null && t.valueEnd === null) {
                    await cleanDatePicker();
                }
            }
        });        
    }, [filters])

    const setValue = (newValue, item, propertyName = 'value') => {

        var filtersCopy = [...filters];
        var filter = filtersCopy.find(f => f.name === item.name);
        filter[propertyName] = newValue;

        if(localStorageName)
            localStorage.setItem(localStorageName, JSON.stringify([...filtersCopy]));

        handleFilterChange([...filtersCopy])
    }

    const getInputType = ( item) => {
        switch(item.type){
            case 'date':
                return (
                <div className="flex">
                    <input 
                        className="form-control" 
                        name={item.name} 
                        type="date" id={item.name}
                        value={item.value || ''}
                        onChange={({target}) => setValue(target.value, item)} />
                    <div onClick={() => setValue('', item)} className="clean-date">
                        <X/>
                    </div>
                </div>
                )
            
            case 'select':
                return (<select onChange={({target}) => setValue(target.value, item)} style={{width: item.width ?? '200px'}} value={item.value}>
                    <option value={-1}>Selecione</option>
                    {item.options.map((option, index) =>
                    <option key={option.value + '-' + option.name} value={option.value}>
                        {option.name}
                    </option>
                    )}
                </select>)

            case 'money':
                return (<input 
                    className="form-control"
                    name={item.name}
                    style={{width: item.width ?? '80px'}}
                    type="text"
                    // placeholder={item.title}
                    id={item.name} value={item.value}
                    onChange={({target}) => setValue(inputCurrencyConvertion(target.value), item)}/>)
                    
            case 'number':
                return (<input 
                    className="form-control"
                    name={item.name}
                    style={{width: item.width ?? '80px'}}
                    type="text"
                    // placeholder={item.title}
                    id={item.name} value={item.value}
                    onChange={({target}) => setValue(target.value, item)} />)

            case 'phoneNumber':
                return (<input 
                    className="form-control"
                    name={item.name}
                    style={{width: item.width ?? '140px'}}
                    type="text"
                    placeholder="(55)55555-5555"
                    id={item.name} value={item.value}
                    onChange={({target}) => {
                        let mask = target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
                        let phoneNumber = !mask[2] ? mask[1] : '(' + mask[1] + ')' + mask[2] + (mask[3] ? '-' + mask[3] : '');
                        setValue(phoneNumber, item);
                    }} />)

            case 'date-range-picker':
                return (showDataPicker && 
                    <DatePicker
                        id="date-range-picker-filter"
                        label={item.name}
                        startDate={item.valueStart} 
                        endDate={item.valueEnd} 
                        currentStartDate={(item.valueStart != null && item.valueStart != '' && item.valueStart !== "1970-01-01T00:00:00.000Z") ? new Date(item.valueStart).toLocaleDateString('pt-BR') : 'Data inicial'}
                        currentEndDate={item.valueEnd != null && item.valueEnd !== "1970-01-01T00:00:00.000Z" ? new Date(item.valueEnd).toLocaleDateString('pt-BR') : 'Data final'}
                        setCurrentStartDate={(target) => setValue(target, item, 'valueStart')}
                        setCurrentEndDate={(target) => setValue(target, item, 'valueEnd')}
                        backgroundPageColor={true}
                        blockPastDays={false} 
                    />)               
            case 'multiselect':
                return (
                    <div className="form-control" style={{minWidth: '300px'}}>
                        <MultiSelect
                            options={item.options.filter(o => o.show !== false)}
                            overrideStrings={{
                                selectSomeItems: "Selecione",
                                allItemsAreSelected: "Todas selecionadas",
                                selectAll: "Selecionar todas",
                                search: "Buscar",
                            }}
                            isLoading={false}
                            value={item.value}
                            onChange={(target) => setValue([...target], item)}
                        />
                    </div>
                )

            default:
                return (<input 
                className="form-control"
                name={item.name}
                style={{width: item.width ?? '100%'}}
                type="text"
                // placeholder={item.title}
                id={item.name} value={item.value}
                onChange={({target}) => setValue(target.value, item)} />);
        }
    }

    return (
            <div>
                <p className="filter-txt">Filtrar por: </p>

                <div className="flex">
                    {filters.map(f => (
                        <div className="field-filter-item" key={f.name}>
                            <label className="label-filter" htmlFor={f.name}>{f.title}</label>
                            <br/>
                            {getInputType(f)}
                        </div>
                        ))
                    }

                    <div className="filter-container-button">
                        <button onClick={handleReset} className="btn btn-pill btn-outline-secondary alg-center">Limpar</button>
                        <button onClick={() => handleSearch()} className="btn btn-pill btn-secondary">Filtrar</button>
                    </div>
                </div>

            </div>
    )
}
export default DynamicFilter;