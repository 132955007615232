import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useService } from "../../../hooks/useService";
import { reportedErrorsPdv as columns } from "../../../utils/tablesHelper";
import Datatable from "../../../components/tables/table";
import './reportedErrorsModal.css';
// toggle
const ReportedErrorsModal = ({toggle, isOpen, id, pointOfSaleName}) => {

    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);
    const service = useService();

    React.useEffect(() => {
        setData([]);
        setCurrentPage(0);
        getRecordedErrors();
    }, [id])

    const getRecordedErrors = async (approved) => {

        if(loading || id == null)
            return;

        setLoading(true);

        await service.get(`/api/reportError/pointOfSale/${id}`)
            .then(response => {
                let success = false;
                if (response.status === 200 && response.data.status === 200) {
                    success = true;
                    var items = response.data?.data?.data;

                    items.forEach(item => {
                        item.registerDate = new Date(item.registerDate).toLocaleDateString('pt-BR');
                        item.reportedErrorTypeName = (<p className="color-primary">{item.reportedErrorTypeName}</p>)
                    });
                    setData(items)
                }

                setLoading(false);
            })
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '85%' }}>
                <ModalHeader toggle={toggle}>Erros Reportados</ModalHeader>
                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }
                <ModalBody>
                
                <div className="align-center">
                    <span className="color-primary" style={{fontSize: '1.2em'}}>Erros reportados no Ponto de Venda</span>
                    <span className="color-gray" style={{marginLeft: '5px', fontSize: '1.2em'}}> {pointOfSaleName}</span>                     
                </div>

                <br/>
                {!loading && <div className="reported-errors-modal">
                    <Datatable
                    multiSelectOption={false}
                    firstLoad={true}
                    loading={false}
                    pageSize={pageSize}
                    pagination={true}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    className="-striped -highlight"
                    columns={columns}
                    totalPages={Math.ceil(data.length/pageSize)}
                    myData={data}
                    setPageSize={setPageSize}
                    manual={false}
                    />    
                </div>}

                </ModalBody>

                
            </Modal>
            
        </>
    );
}

export default ReportedErrorsModal;