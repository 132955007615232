import React from 'react';
import { FileText } from 'react-feather';

const DownloadVisitsPDFButton = ({ exportPDF, loadingExportPDF }) => {

  return (
    <button className="btn btn-pill btn-secondary btn-icon alg-center" type="button" onClick={exportPDF} disabled={loadingExportPDF}>
      <FileText style={{ display: 'flex', marginRight: '6px' }} />
      {loadingExportPDF === false ?
        <p>Exportar para PDF</p>
        :
        <p>Criando arquivo...</p>
      }
    </button>
  )
}

export default DownloadVisitsPDFButton;

