import React from "react";
import { ThumbsUp, ThumbsDown } from 'react-feather';
import { Alert } from "../../../components/common/modals/modals";
import ProgressiveImage from "../../../components/common/progressiveImage";
import { getStorageUrl } from "../../../config.js";
import { SURVEY_ANSWERS_TYPE, SURVEY_ANSWERS_TYPE_TEXT } from "../../../utils/enums";

export const getAnswerValue = (answerType, answer) => {
    switch (answerType) {
        case SURVEY_ANSWERS_TYPE.Bool:
            return answer?.answerBool;

        case SURVEY_ANSWERS_TYPE.OptionalDecimal:
            return answer?.answerDecimal;

        default:
            return answer?.answer ?? "Não informado";
    }
}

export const getAnswerType = (answerType) => {
    return SURVEY_ANSWERS_TYPE_TEXT[answerType] ?? '-';
}

export const getImagePath = (path) => {
    if (path?.startsWith('http') || path?.startsWith('data:'))
        return path;

    return getStorageUrl() + path;
}

export const getImageRate = (status) => {
    switch (status) {
        case 0:
            return ''
        case 1:
            return <ThumbsUp />
        case 2:
            return <ThumbsDown />
        default:
            return '';
    }
}


export const getImageRateBackground = (status) => {
    switch (status) {
        case 0:
            return 'none-color-background'
        case 1:
            return 'main-color-background'
        case 2:
            return 'error-color-background'
        default:
            return '';
    }
}

const renderGondolaStyles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    gondolasImages: {
        margin: '15px 5px',
        overflow: 'hidden'
    },
    imageCount: {
        zIndex: '5',
        cursor: 'pointer',
        padding: '2px 10px',
        background: '#01edca',
        position: 'absolute',
        textAlign: 'center',
        color: 'white',
        fontWeight: '600',
        borderRadius: '50%',
        opacity: '0.8'
    },
    imageFullWidth: {
        width: "100%",
        height: "100%",
        objectFit: 'contain'
    },
};

export const renderGondola = (imgs, question, setPhotoView, imagesViewBox, setImagesViewBox, open) => {
    var itens = imgs?.sort((a, b) => b.order - a.order).reverse();
    return (<div className={renderGondolaStyles.container}>
        {itens?.map((img, i) => (
            <div className={renderGondolaStyles.gondolasImages} key={img.id} onClick={() => {
                setPhotoView && setPhotoView(true);
            }}>
                <div className={renderGondolaStyles.imageCount}>{img.order}</div>

                {imagesViewBox !== undefined ?
                    <div className={imagesViewBox !== undefined ? 'image-gallery image-gallery-gondola' : ''} key={i} onClick={() => {
                        if (!imagesViewBox) return;
                        let imageIndex = imagesViewBox.findIndex(g => g.id === img.id);
                        let images = [...imagesViewBox];
                        if (imageIndex !== -1) {
                            images[imageIndex].title = question;
                            setImagesViewBox([...images]);
                            open(imageIndex)
                        } else {
                            Alert('Ops, não foi possível carregar a imagem');
                        }
                    }}>
                        <ProgressiveImage src={getImagePath(img.imagePath)} />

                    </div>
                    :
                    <div style={renderGondolaStyles.fullWidth}>
                        <p>teste</p>
                        <img src={getImagePath(img.imagePath)} style={{
                            height: 'auto',
                            display: 'block',
                            maxWidth: '-webkit-fill-available'
                        }} alt='gondola' />
                    </div>
                }

                {imagesViewBox !== undefined && <div>
                    <div className={`image-rate ${getImageRateBackground(img.evaluationStatus)}`}>
                        <div>
                            {getImageRate(img.evaluationStatus)}
                        </div>
                    </div>
                </div>}
            </div>
        ))}
    </div>)
}
