import React, { Fragment, useState, useEffect } from 'react';
import {  LogOut } from 'react-feather';
import { withRouter } from 'react-router';
import { useAuth0 } from "@auth0/auth0-react";
import { User } from 'react-feather';

const UserMenu = () => {

    const { isAuthenticated, logout, user } = useAuth0();
    const [saudation, setSaudation] = useState("Olá!");
    
    useEffect(() => {
        if(user !== undefined){
            let name = user.nickname.split(' ');
            let initial = name.length > 1 ? name[name.length - 1][0] : '';       
            setSaudation(`Olá ${name[0]} ${initial}!`);
        }
    }, [user]);

    const handleLogout = () => logout({returnTo: `https://${window.location.host}`});

    return (
        <Fragment>
            <li className="onhover-dropdown" style={{borderRight: 'none'}}>
                <div className="media align-items-center">
                    <User className="user-icon" />
                    <p className="saudation">{saudation}</p>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    {isAuthenticated && (
                        <li onClick={handleLogout}><p><span style={{marginTop:'-2px'}}><LogOut /></span>Sair</p></li>
                    )}                    
                </ul>
            </li>
        </Fragment>
    );
};

export default withRouter(UserMenu);