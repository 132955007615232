import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Alert } from "../../components/common/modals/modals.js";
import { useService } from "../../hooks/useService";
import { visitsColumns as columns } from "../../utils/tablesHelper";
import { hasFullAccess, updateFilterValues } from "../../utils/helper";
import emptyImage from '../../assets/images/emptylist.png';
import { getPercentColor, getAiStatus } from "../../utils/validations";
import { ThumbsUp } from 'react-feather';
import { visitsFilters } from './components/filters';
import ReportedErrorsModal from '../../components/common/reported-errors/reportedErrorsModal.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate } from "../../utils/helper.js";
import DynamicFilter from '../../components/common/filters/dynamic_filter';
import GoogleApiWrapper from '../../components/common/pointer-locations-map/map-filter';
import { VisitsTable, approveTooltip } from './components/table';
import { getEnvironment } from "../../config";

const VisitsList = (props) => {
    const service = useService();
    var env = getEnvironment();
    const localStorageFilterKey = `visits-filters-${env}`;
    const [canManage, setcanManage] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [data, setData] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const [tableFilters, setTableFilters] = React.useState([]);
    var visitsTableFilterKey = `visits-table-config-${env}`;

    // errors modal state
    const [showReportErrorsModal, setShowReportErrorsModal] = useState(null);
    const [reportErrorPDVName, setReportErrorPDVName] = useState(null);

    // Approve modal state
    const [approveModalIsOpen, setApproveModalIsOpen] = React.useState(false);
    const [approveModalVisitId, setApproveModalVisitId] = React.useState(false);
    const [selectedVisits, setSelectedVisits] = useState([]);

    const getVisits = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter !== null)
            sortColumn = null;

        setSortColumn(sortColumn);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumn, orderDirection, page, currentPageSize);
        setSelectedVisits([]);

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        for (var i = 0; i < filterItems.length; i++) {
            let filter = filterItems[i];
            if (filter.type === 'multiselect') {
                if (filter.value.length > 0) {
                    if (filter.value[0]?.value !== undefined && filter.value[0] !== 0) {
                        filters.push({
                            name: filter.name,
                            values: filter.value.map(v => v.value)
                        })
                    }
                }
            }
            else if (filter.name === 'clientIds') {
                if (filter.value !== -1 && filter.value !== 'empty' && filter.value !== "") {
                    filters.push({
                        name: filter.name,
                        values: [filter.value]
                    })
                }
            }
            else if (filter.name === 'finishDate') {

                if (filter.valueStart !== null && filter.valueEnd !== null && filter.valueStart !== "1970-01-01T00:00:00.000Z" && filter.valueEnd !== '' && filter.valueEnd !== "1970-01-01T00:00:00.000Z") {
                    filters.push({
                        name: filter.name,
                        value: filter.valueStart + '|' + filter.valueEnd
                    })
                } else {
                    // clean the fields cause they are only considered when the both are filled
                    if ((filter.valueStart !== null && (filter.valueEnd === null || filter.valueEnd === "1970-01-01T00:00:00.000Z")) || ((filter.valueStart === null || filter.valueStart === "1970-01-01T00:00:00.000Z") && filter.valueEnd !== null)) {
                        var filtersCopy = [...tableFilters];
                        filter.valueStart = null;
                        filter.valueEnd = null;

                        setTableFilters([...filtersCopy]);
                        localStorage.setItem(localStorageFilterKey, JSON.stringify([...filtersCopy]));
                        window.location.reload()
                    }
                }
            }
            else if (filter.value !== '' && filter.value !== null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value !== 'empty' ? filter.value : null
                })
            }
        }

        var skip = 0;

        if (filters.length === 0 && sortColumn === null) {
            sortColumn = 'FinishDate';
            orderDirection = 1;
            setSortColumn('FinishDate');
            setSortDirection(1);
        }

        if (!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);

        if (skip === 0) {
            setCurrentPage(0);

            if (filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/visit/list", payload)
            .then(async response => {
                if (response?.data?.status === 56 || response?.data?.status === 0) {
                    return props.history.push("/404")
                }
                if (response?.data?.data === null) {
                    return await Alert("", "Falha ao buscar os dados", "error");
                }
                var items = response?.data?.data?.data.map((c, index) => {
                    return {
                        id: c.id,
                        isActive: c.isActive,
                        posId: c.posId,
                        posName: c.posName,
                        posTypeName: c.posTypeName,
                        posCity: c.posCity,
                        posRegion: c.posRegion,
                        posState: c.posState,
                        latitude: c.posLatitude,
                        longitude: c.posLongitude,
                        finishDate: new Date(c.finishDate).toLocaleDateString('pt-BR'),
                        userEmail: c.userEmail,
                        approved: <div className="align-center">{c.approval === null ? '-' : approveTooltip(c.approval)}</div>,
                        status: <p className={`visit-${c.status}`}>{getAiStatus(c.status)}</p>,
                        percentEvaluation: (
                            <div className={`align-center ${getPercentColor(c.percentEvaluation)}`}>
                                {c.percentEvaluation}{c.percentEvaluation !== null && '%'}
                            </div>),
                        details: <a href={`/visits/details/${c.id}`} className="align-center pointer"><img className="user-icon" src={require("../../assets/images/more.svg")} alt="magnifying glass" /></a>,
                        approve: (<div className="align-center pointer" onClick={() => {
                            setApproveModalIsOpen(true);
                            setApproveModalVisitId(c.id);
                        }}>
                            <ThumbsUp className="main-color" />
                        </div>),
                        // reportedErrorsAmount: getErrorsStyle(c.posId, c.reportedErrorsAmount, c.name)
                    }
                })

                setData(items);
                if (response?.data?.data?.recordsFiltered)
                    setRecordsFiltered(response.data.data.recordsFiltered);

            }).finally(() => {
                setLoadingTable(false);
            });
    }

    const getClients = async () => {
        let list;
        await service.get("/api/client/list")
            .then(async response => {
                list = response?.data?.data?.map(c => ({
                    name: c.name,
                    value: c.id
                }));
            });
        return list;
    }

    const getCampaigns = async () => {
        let list;
        await service.get("/api/campaign/simplelist")
            .then(async response => {
                list = response?.data?.data.map(c => ({
                    name: c.name,
                    value: c.id,
                    text: c.name,
                    startDate: c.startDate,
                    endDate: c.endDate,
                    clientName: c.clientName,
                    clientId: c.clientId
                }));
            });
        return list;
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);
        setSelectedVisits([]);
        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem(visitsTableFilterKey);

        let cleanedFilter = tableFilters.map(t => {
            if (t.name === 'campaigns') {
                t.value = []
                t.options.forEach(c => {
                    c.show = true;
                }
                );
            }
            else if (t.name === 'finishDate') {
                t.valueStart = null;
                t.valueEnd = null;
            }
            else
                t.value = '';
            return t;
        });
        setTableFilters([...cleanedFilter])
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);

        await getVisits();
        setLoadingTable(false);
    }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }
        localStorage.setItem(visitsTableFilterKey, JSON.stringify(tableConfig));
    }

    const getFilterData = async (filters, filtersOfTable = tableFilters) => {

        // clients filtering
        var clients = await getClients();
        var clientSavedFilter = filters?.find(f => f.name === 'clientIds')?.value ?? -1;

        let clientFilter = {
            name: 'clientIds',
            value: clientSavedFilter,
            title: 'Contratante',
            options: clients,
            type: 'select'
        }

        // campaigns filtering
        var campaigns = await getCampaigns();

        let result = campaigns?.map(c => ({
            label: (<div className='combo-panel-select' style={{ wordBreak: c.name.split(' ')[0].length > 18 ? 'break-all' : 'normal' }}>
                <p>{c.name}&nbsp;</p>
                <b>{formatDate(new Date(c.startDate))} a {formatDate(new Date(c.endDate))}</b>
            </div>),
            value: c.value,
            text: c.text,
            clientId: c.clientId,
            clientName: c.clientName,
            show: true
        }))

        var validCampaignsIds = [];

        // delete current filter if campaign id does not exist in response list
        (campaigns).forEach(campaign => {
            var campaignSavedFilter = filters?.find(f => f.name === 'campaigns')?.value ?? [];
            if (campaignSavedFilter) {
                campaignSavedFilter.forEach(campaignIdSelected => {
                    if (campaignIdSelected.value === campaign?.id)
                        validCampaignsIds.push(campaignIdSelected);
                });
            }
        });

        let campaignFilter = {
            name: 'campaigns',
            value: validCampaignsIds,
            title: 'Campanhas',
            options: result,
            type: 'multiselect'
        }

        if (clients.length > 1) {
            setTableFilters([...filtersOfTable, clientFilter, campaignFilter]);
            updateCampaignOptions([...filtersOfTable, clientFilter, campaignFilter]);
        } else {
            setTableFilters([...filtersOfTable, campaignFilter]);
            updateCampaignOptions([...filtersOfTable, campaignFilter]);
        }
    }

    const getPointsOfSaleTypes = async () => {
        let responseData = [];
        await service.get("/api/pointofsaletype/list")
            .then(async response => {
                if (response?.data?.status !== 200) {
                    return await Alert("Por favor tente novamente mais tarde!", "Falha ao buscar os tipos de Ponto de Venda", "error");
                }
                responseData = response.data.data;
            });
        return responseData;
    }

    React.useEffect(() => {
        async function init() {
            var filtersResult = localStorage.getItem(localStorageFilterKey);
            var page = 0;
            var pageSize = 10;
            var sortColumn = null;
            var sortDirection = 0;

            let filters = [];
            if (filtersResult !== null) {
                filters = JSON.parse(filtersResult);
            }

            var campaignFilter = window.location.search.split('?campaign=');
            var tableConfigResult = localStorage.getItem(visitsTableFilterKey);

            if (tableConfigResult !== null && (campaignFilter === '' || campaignFilter.length === 1)) {

                var tableConfig;
                if (tableConfigResult)
                    tableConfig = JSON.parse(tableConfigResult);

                if (tableConfig?.page) {
                    setCurrentPage(tableConfig.page);
                    page = tableConfig.page;
                }

                if (tableConfig?.pageSize) {
                    setPageSize(tableConfig.pageSize);
                    pageSize = tableConfig.pageSize;
                }

                if (tableConfig?.ordenation?.sortColumn) {
                    setSortColumn(tableConfig.ordenation.sortColumn);
                    sortColumn = tableConfig.ordenation.sortColumn;
                }

                if (tableConfig?.ordenation?.sortDirection) {
                    setSortDirection(tableConfig.ordenation.sortDirection);
                    sortDirection = tableConfig.ordenation.sortDirection;
                }
            } else {
                let campaign = {
                    name: 'campaigns',
                    value: [{
                        label: 'campaign',
                        value: campaignFilter[1]
                    }],
                    title: 'Campanhas',
                    options: [],
                    type: 'multiselect'
                }
                filters = [campaign];
                setTableFilters([...filters])
            }

            var tf = await loadTableColumns(filters);
            await getFilterData(filters, tf);
            await getVisits(page, pageSize, sortColumn, sortDirection, null, filters);
        }

        init();
    }, []);

    const loadTableColumns = async (filters) => {
        let pdvTypes = await getPointsOfSaleTypes();
        let isMaster = hasFullAccess();
        var cols = [...columns];
        var filterFields = visitsFilters(pdvTypes);

        setcanManage(isMaster);

        if (!isMaster) {
            cols[0].columns = columns[0].columns.filter(c => c.accessor !== 'approve');
            cols[0].columns = columns[0].columns.filter(c => c.accessor !== 'approved');
            cols[0].columns = columns[0].columns.filter(c => c.accessor !== 'userEmail');
            filterFields = filterFields.filter(f => f.name !== 'UserEmail');
        }

        let tf = updateFilterValues(filterFields, filters);
        setTableColumns(cols);
        setTableFilters([...tf])
        return tf;
    }

    // to show campaigns created to the specific client select
    const updateCampaignOptions = (updatedFilter) => {
        let clientId = updatedFilter.find(f => f.name === 'clientIds')?.value;
        if (clientId) {
            let campaignsFilter = updatedFilter.find(f => f.name === 'campaigns');
            if (campaignsFilter.options.length > 0) {
                campaignsFilter.options.forEach(c => {
                    c.clientId === clientId ? c.show = true : c.show = false
                }
                );

                if (campaignsFilter.value !== "" && campaignsFilter.value !== -1 && campaignsFilter.value !== null) {
                    const filterSelected = campaignsFilter.options.filter(option => campaignsFilter.value.some(filterItem => filterItem.value === option.value));
                    filterSelected.map(f => {
                        if (f?.clientId !== clientId && f?.show === false) {
                            campaignsFilter.value = [];
                        }
                    })
                }
            }
        }
        setTableFilters([...updatedFilter])
    }

    return (
        <Fragment>
            <Breadcrumb title="Visitas" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <span className="d-flex">
                                    <h5>Listagem de Visitas</h5>
                                    <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                </span>

                                <DynamicFilter
                                    handleSearch={getVisits}
                                    filters={tableFilters}
                                    localStorageName={localStorageFilterKey}
                                    handleReset={handleFilterReset}
                                    handleFilterChange={(updatedFilter) => {
                                        updateCampaignOptions(updatedFilter)
                                    }}
                                    saveFilterState={saveFilterConfig}
                                />
                                <div style={{ width: '100%' }}>
                                    {data !== null && data.length > 0 && <GoogleApiWrapper
                                        showCurrentLocation={false}
                                        showPDVTypeLegend={true}
                                        setLatitude={() => { }}
                                        setLongitude={() => { }}
                                        lat={data[0].latitude} lon={data[0].longitude}
                                        searchedItems={data.map(i => { i.name = i.posName; i.PDVType = i.posTypeName; return i; })}
                                        alwaysRender={true}></GoogleApiWrapper>}
                                </div>
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhuma visita encontrada</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            <VisitsTable
                                loadingTable={loadingTable}
                                data={data}
                                setData={setData}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                tableColumns={tableColumns}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                recordsFiltered={recordsFiltered}
                                getVisits={getVisits}
                                canManage={canManage}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                toast={toast}
                                approveModalIsOpen={approveModalIsOpen}
                                setApproveModalIsOpen={setApproveModalIsOpen}
                                approveModalVisitId={approveModalVisitId}
                                selectedVisits={selectedVisits}
                                setSelectedVisits={setSelectedVisits}
                                multiSelectOption={true}
                            />

                        </div>
                    </div>
                </div>
            </div>

            <ReportedErrorsModal
                isOpen={showReportErrorsModal !== null}
                id={showReportErrorsModal}
                pointOfSaleName={reportErrorPDVName}
                toggle={async (event, success = null) => {
                    setShowReportErrorsModal(null);
                }}
            />

            <ToastContainer />
        </Fragment>
    );
};

export default VisitsList;