export const campaignsFilters = [
  {
      name: 'name',
      value: '',
      title: 'Nome'
  },
  {
      name: 'startDate',
      value: null,
      type: 'date',
      title: 'Data de Início'
  },
  {
      name: 'endDate',
      value: null,
      type: 'date',
      title: 'Data de Término'
  },
  {
      name: 'clientId',
      value: -1,
      title: 'Contratante',
      type: "select",
      options: [],
      width: '250px'
  },
  {
      name: 'campaignProgress',
      value: -1,
      title: 'Status da Campanha',
      type: "select",
      options: [
          {
              name: 'Dentro do prazo',
              value: 1
          },
          {
              name: 'Com atraso',
              value: 2
          },
      ],
      width: '250px'
  }
]
