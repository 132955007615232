import { Server, MapPin, Map, FileText, List, Briefcase, User, Users, BarChart, Download } from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Campanhas', id: 'Campanhas', path: '/campaigns/list', icon: Briefcase, type: 'link', active: false, permission: 1
    },
    {
        title: 'Combos', id: 'Combos', path: '/combo/list', icon: Map, type: 'link', active: false, permission: 0
    },
    {
        title: 'Grupo de usuários', id: 'Grupos', path: '/users/groups', icon: Users, type: 'link', active: false, permission: 0
    },
    {
        title: 'Pesquisas', id: 'Pesquisas', path: '/surveys/list', icon: List, type: 'link', active: false, permission: 0
    },
    {
        title: 'PDVs', id: 'PDVs', path: '/pdv/list', icon: MapPin, type: 'link', active: false, permission: 0
    },
    {
        title: 'Produtos', id: 'Produtos', path: '/product/list', icon: Server, type: 'link', active: false, permission: 0
    },
    {
        title: 'Usuários', id: 'Usuários', path: '/users/list', icon: User, type: 'link', active: false, permission: 0
    },
    {
        title: 'Visitas', id: 'Visitas', path: '/visits/list', icon: FileText, type: 'link', active: false, permission: 1
    },
    {
        title: 'Visitas exportadas', id: 'VisitasExportadas', path: '/visits/reports', icon: Download, type: 'link', active: false, permission: 1
    },
    {
        title: 'Dashboard', id: 'report', path: '/report', icon: BarChart, type: 'sub', active: false, permission: 2, children: []
    }
]
