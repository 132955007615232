import React, { Fragment } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Switch } from '../../components/common/switch/switch.js';
import { VisitsTable, approveTooltip } from '../visits/components/table';
import ReportedErrorsTable from './components/reportedErrorsTable';
import { visitsColumns, reportedErrorsColumns } from "../../utils/tablesHelper";
import { getTimeSince, getAddressLink, getMoneyFormat, getConvertedDateTimeFromUTC } from "../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import GoogleApiWrapper from '../../components/common/pointer-locations-map/map-filter';
import { ThumbsUp } from 'react-feather';
import { getPercentColor, getAiStatus } from "../../utils/validations";
import { Activity, Gift, Calendar, MapPin, Mail, Phone } from 'react-feather';
import { useService } from "../../hooks/useService";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
import { USER_WALLET_TRANSACTION_REFERENCE } from '../../utils/enums';
import emptyImage from '../../assets/images/emptylist.png';
import './details.css';

const UserDetails = ({ history }) => {
    const service = useService();
    const { user } = useAuth0();
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    // Visits
    const visitsTableRef = React.useRef(null);
    const [loadingVisits, setLoadingVisits] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [visitsData, setVisitsData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [recordsFiltered, setRecordsFiltered] = React.useState('...');
    const [visitSortColumn, setVisitsSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);

    // Approve modal state
    const [approveModalIsOpen, setApproveModalIsOpen] = React.useState(false);
    const [approveModalVisitId, setApproveModalVisitId] = React.useState(false);

    // Reported errors
    const [loadingReportedErrors, setLoadingReportedErrors] = React.useState(true);
    const [reportErrorsPageSize, setReportErrorsPageSize] = React.useState(10);
    const [reportErrorsData, setReportErrorsData] = React.useState([]);
    const [reportErrorsCurrentPage, setReportErrorsCurrentPage] = React.useState(0);
    const [reportedErrorsFiltered, setReportedErrorsFiltered] = React.useState('...');
    const [reportErrorsSortColumn, setReportErrorsSortColumn] = React.useState(null);
    const [reportErrorSortDirection, setReportErrorSortDirection] = React.useState(0);

    // walletTransactions
    const [userWalletTransactions, setUserWalletTransactions] = React.useState([]);
    const [userWalletTransactionsLoading, setUserWalletTransactionsLoading] = React.useState(true);

    const params = useParams();

    const getDetail = async (id) => {

        await service.get(`/api/user/detail/${id}`)
            .then(async response => {
                if (response.data.status === 56)
                    return history.replace(`/404`)
                var detail = response.data.data;

                if (detail === null)
                    return history.replace(`/404`)

                let user = {
                    id: detail?.id,
                    isActive: detail?.isActive,
                    name: detail?.name,
                    username: detail?.username,
                    isSocialGoogleUser: detail?.isSocialGoogleUser,
                    age: detail?.age ? `${detail?.age} anos de idade` : 'Não informado',
                    phoneNumber: detail?.phoneNumber,
                    registerDate: new Date(detail?.registerDate).toLocaleDateString('pt-BR'),
                    walletTotalAvailable: detail?.wallet?.totalAvailable ? parseFloat(detail?.wallet?.totalAvailable).toFixed(2).replace('.', ',') : '0,00',
                    walletTotalPendingApproval: detail?.wallet?.totalPendingApproval ? parseFloat(detail?.wallet?.totalPendingApproval).toFixed(2).replace('.', ',') : '0,00',
                    walletTotalPaid: detail?.wallet?.totalPaid ? parseFloat(detail?.wallet?.totalPaid).toFixed(2).replace('.', ',') : '0,00',
                    location: '...'
                };
                setData({ ...user });
                setLoading(false);

                let visits = await getVisits(0, 10, 'FinishDate', 1)

                if (visits.length > 0) {
                    user.location = `${visits[0].posRegion} - ${visits[0].posCity}`;
                    user.lastVisit = getTimeSince(new Date(visits[0].finishDateNoFormat)); // todo
                    setData({ ...user })
                }

                await getReportedErrors(0, 10, 'FinishDate', 1)
            })
    }

    const getTransactionAmountColorClassname = (transactionReference, amount) => {
        if (transactionReference === 0) { // visit approval
            if (amount < 0) {
                return 'user-wallet-transaction-negative-value';
            }
        }
        else if (transactionReference === 1) { // visit done (by app)
            return 'waiting-color';
        }
        else if (transactionReference === 2) { // payment done
            if (amount < 0) {
                return 'user-wallet-transaction-negative-value';
            } else {
                return 'third-color';
            }
        }
        else if (transactionReference === 3) { // transaction done
            if (amount < 0) {
                return 'user-wallet-transaction-negative-value';
            } else {
                return 'third-color';
            }
        }
        else if (transactionReference === 4) { // invite reward available
            return 'primary-color';
        }
        else if (transactionReference === 5) { // invite reward available
            return 'primary-color';
        }
    }

    const getWalletTransactions = async (id) => {

        await service.get(`/api/userWallet/transactions/${id}`)
            .then(async response => {
                var result = response?.data?.data;

                var items = result?.map((t, index) => {
                    var redirectUrl;

                    switch (USER_WALLET_TRANSACTION_REFERENCE[t.reference]) {
                        case USER_WALLET_TRANSACTION_REFERENCE[0]:
                            redirectUrl = `/visits/details/${t.referenceId}`;
                            break;
                        case USER_WALLET_TRANSACTION_REFERENCE[1]:
                            redirectUrl = `/visits/details/${t.referenceId}`;
                            t.description = 'Valor pendente de aprovação';
                            break;
                        case USER_WALLET_TRANSACTION_REFERENCE[2]:
                            break;
                        case USER_WALLET_TRANSACTION_REFERENCE[3]:
                            break;
                        case USER_WALLET_TRANSACTION_REFERENCE[4]:
                            redirectUrl = `/users/details/${t.referenceId}`;
                            break;
                        case USER_WALLET_TRANSACTION_REFERENCE[5]:
                            redirectUrl = `/users/details/${t.referenceId}`;
                            break;
                        default:
                            break;
                    }

                    return {
                        id: t.id,
                        description: t.description,
                        registerDate: getConvertedDateTimeFromUTC(t.registerDate),
                        transaction: USER_WALLET_TRANSACTION_REFERENCE[t.reference],
                        amount: t.amount,
                        amountFormated: getMoneyFormat(t.amount).replace('.', ','),
                        redirectUrl: redirectUrl,
                        referenceType: t.reference,
                        pointOfSaleName: t.pointOfSaleName,
                    }
                });

                setUserWalletTransactions(items);
                setUserWalletTransactionsLoading(false);
            })
    }

    const getVisits = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null) => {

        setVisitsSortColumn(sortColumn);
        setSortDirection(orderDirection);
        setPageSize(currentPageSize);
        setCurrentPage(page);

        var filters = [];

        var skip = 0;

        if (!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        let visits = [];

        await service.post(`/api/visit/user/${params.id}`, payload)
            .then(async response => {
                if (response?.data?.status === 56 || response?.data?.status === 0 || response?.data?.data === null) {
                    toast.error("Não foi possível buscar as visitas do usuário");
                    return;
                }

                var items = response?.data?.data?.data.map((c, index) => {
                    return {
                        id: c.id,
                        isActive: c.isActive,
                        posId: c.posId,
                        posName: c.posName,
                        posTypeName: c.posTypeName,
                        posCity: c.posCity,
                        posRegion: c.posRegion,
                        latitude: c.posLatitude,
                        longitude: c.posLongitude,
                        finishDate: new Date(c.finishDate).toLocaleDateString('pt-BR'),
                        finishDateNoFormat: c.finishDate,
                        userEmail: c.userEmail,
                        approved: <div className="align-center">{c.approval == null ? '-' : approveTooltip(c.approval)}</div>,
                        status: <p className={`visit-${c.status}`}>{getAiStatus(c.status)}</p>,
                        percentEvaluation: (
                            <div className={`align-center ${getPercentColor(c.percentEvaluation)}`}>
                                {c.percentEvaluation}{c.percentEvaluation != null && '%'}
                            </div>),
                        details: <div className="align-center pointer" onClick={() => { history.push(`/visits/details/${c.id}`) }}><img className="user-icon" src={require("../../assets/images/more.svg")} alt="empty list" /></div>,

                        approve: (<div className="align-center pointer" onClick={() => {
                            setApproveModalIsOpen(true);
                            setApproveModalVisitId(c.id);
                        }}>
                            <ThumbsUp className="success-color" />
                        </div>),
                        // reportedErrorsAmount: getErrorsStyle(c.posId, c.reportedErrorsAmount, c.name)
                    }
                })

                visits = items;
                setVisitsData([...items]);
                if (response?.data?.data?.recordsFiltered)
                    setRecordsFiltered(response.data.data.recordsFiltered);
                else
                    setRecordsFiltered(0);
                return visits;
            }).finally(() => {
                setLoadingVisits(false);
            });
        return visits;
    }

    const getReportedErrors = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null) => {

        setReportErrorsSortColumn(sortColumn);
        setReportErrorSortDirection(orderDirection);
        setReportErrorsPageSize(currentPageSize);
        setReportErrorsCurrentPage(page);

        var filters = [];

        var skip = 0;

        if (!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        let reportedErrors = [];

        await service.post(`/api/ReportError/user/${params.id}`, payload)
            .then(async response => {
                if (response?.data?.status === 56 || response?.data?.status === 0 || response?.data?.data === null) {
                    toast.error("Não foi possível buscar os relatos de erros do usuário");
                    return;
                }

                var items = response?.data?.data?.data.map((c, index) => {
                    return {
                        id: c.id,
                        registerDate: new Date(c.registerDate).toLocaleDateString('pt-BR'),
                        reportedErrorTypeName: (<p className="color-primary">{c.reportedErrorTypeName}</p>),
                        pointOfSaleName: c.pointOfSaleName,
                        pointOfSaleAddress: getAddressLink(c.pointOfSaleAddress, c.pointOfSaleLatitude, c.pointOfSaleLongitude),
                        routeIdentifier: c.routeIdentifier,
                        observation: c.observation
                    }
                })

                reportedErrors = items;
                setReportErrorsData([...items]);
                if (response?.data?.data?.recordsFiltered)
                    setReportedErrorsFiltered(response.data.data.recordsFiltered);
                else
                    setReportedErrorsFiltered(0);
                return reportedErrors;
            }).finally(() => {
                setLoadingReportedErrors(false);
            });
        return reportedErrors;
    }

    const handleChangeUserStatus = async () => {
        await service.post(`/api/user/active/${data.id}/${!data.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    toast.success(`Usuário ${data.isActive ? "inativado" : "ativado"} com sucesso!`);
                    setData({ ...data, isActive: !data.isActive });
                } else {
                    toast.error(`Não foi possível ${!data.isActive ? "inativar" : "ativar"} o usuário`);
                }
            })
    }

    const getUserWalletHeight = () => {
        switch (pageSize) {
            case 5:
                return '410px';
            case 20:
                return '990px';
            case 25:
                return '1150px';
            case 50:
                return '2030px';
            case 100:
                return '3800px';
            default: 
                return'620px';
        }
    }

    React.useEffect(() => {
        let id = params.id;
        if (id) {
            if (user) {
                getDetail(id);
                getWalletTransactions(id);
            }
        } else {
            history.push(`/users`);
        }
    }, [user]);

    return (
        <Fragment>
            <Breadcrumb title="Detalhes" parent="Usuário" />
            <div className="container-fluid">
                <div className="email-wrap">
                    <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <div className="email-left-aside">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="email-app-sidebar user-personal-data">
                                            <div className="media">
                                                <div className="flex-no-wrap">
                                                    <img className="mr-3 rounded-circle" src={require("../../assets/images/user-solid.svg")} alt="avatar icon" />
                                                    <h2 className="f-w-600 pl-10 user-name">{data?.name}</h2>
                                                </div>
                                            </div>

                                            <div className="flex space-around user-personal-data-numbers mt-5">
                                                <div className="visits-counter align-center">
                                                    <p>{recordsFiltered}</p>
                                                    <p>Visitas Realizadas</p>
                                                </div>

                                                <div className="visits-counter align-center">
                                                    <p>{reportedErrorsFiltered}</p>
                                                    <p>Erros Reportados</p>
                                                </div>
                                            </div>

                                            <div className="flex-no-wrap pb-15">
                                                {data?.isSocialGoogleUser ? <img className="mr-10 google-icon" src={require("../../assets/images/gmail-24x24.png")} alt="gmail icon" />
                                                    : <Mail className="contact-icon" />}
                                                <i className="fa fa-google-plus"></i>
                                                <input name="username" className="custom-input-item w-100" type="text" defaultValue={data?.username} disabled={true} />
                                            </div>

                                            <div className="flex-no-wrap pb-15">
                                                <Phone className="contact-icon" />
                                                <input name="phoneNumber" className="custom-input-item w-100" type="text" defaultValue={data?.phoneNumber} disabled={true} />
                                            </div>

                                            {recordsFiltered !== 0 && <>
                                                <p className="pl-30">Última visita:</p>
                                                <div className="flex-no-wrap pb-15">
                                                    <Activity className="contact-icon" />
                                                    <div>
                                                        <input name="lastVisit" className="custom-input-item w-100" type="text" defaultValue={data?.lastVisit} disabled={true} />
                                                    </div>
                                                </div>
                                            </>}

                                            {recordsFiltered !== 0 && <div className="flex-no-wrap pb-15">
                                                <MapPin className="contact-icon" />
                                                <input name="location" className="custom-input-item w-100" type="text" value={data?.location} disabled={true} />
                                            </div>}

                                            <div className="flex-no-wrap pb-15">
                                                <Gift className="contact-icon" />
                                                <input name="age" className="custom-input-item w-100" type="text" defaultValue={data?.age} disabled={true} />
                                            </div>

                                            <p className="pl-30">Cadastrado em:</p>
                                            <div className="flex-no-wrap pb-15">
                                                <Calendar className="contact-icon" />
                                                <div>
                                                    <input name="registerDate" className="custom-input-item w-100" type="text" defaultValue={data?.registerDate} disabled={true} />
                                                </div>
                                            </div>

                                            <div className="flex">
                                                <Switch
                                                    handleToggle={handleChangeUserStatus}
                                                    isOn={data?.isActive}
                                                    isOnColor="#01edca"
                                                    isOffColor="#ffc30f"
                                                    id="user-toggle" />
                                                <label style={{ paddingLeft: '15px', marginTop: '-5px' }}>
                                                    Usuário {data?.isActive ? 'Ativo' : 'Inativo'}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="email-left-aside">
                                <h3 className="section-title">Extrato</h3>
                                <div className="card">
                                    <div className="card-body scroll-container user-wallet-container">
                                        <div className="email-app-sidebar user-personal-data">
                                            <ul className="user-wallet-transaction-list" style={{ maxHeight: getUserWalletHeight() }}>
                                                {userWalletTransactions?.map(transaction =>
                                                    <li key={transaction.id}>
                                                        {transaction.redirectUrl !== null ?
                                                            <a target="_blank" rel="noopener noreferrer" href={transaction.redirectUrl}>
                                                                <h3 className="user-wallet-transaction-title">{transaction.transaction}</h3>
                                                            </a>
                                                            :
                                                            <h3 className="user-wallet-transaction-title">{transaction.transaction}</h3>
                                                        }
                                                        <div className="flex space-between">
                                                            <div style={{ maxWidth: '75%' }}>
                                                                <div>
                                                                    {transaction.description}
                                                                    {transaction.pointOfSaleName && <p>Ponto de Venda: <b>{transaction.pointOfSaleName}</b></p>}
                                                                </div>
                                                                <p className="user-wallet-transaction-date">{transaction.registerDate}</p>
                                                            </div>
                                                            {transaction.amount === 0 ? '-' :
                                                                <b><p className={`user-wallet-transaction-value ${getTransactionAmountColorClassname(transaction.referenceType, transaction.amount)}`}>{transaction.amountFormated}</p></b>
                                                            }
                                                        </div>
                                                    </li>)}
                                                {!userWalletTransactionsLoading && userWalletTransactions?.length <= 0 && (
                                                    <div className="align-center">
                                                        <div className="empty-list-txt">Nenhuma transação efetuada</div>
                                                        <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                                    </div>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-xl-8 col-md-12">
                            <div className="email-right-aside">

                                <h3 className="section-title">Carteira</h3>
                                <div className="card email-body radius-left pl-10 pr-10 pt-15 wallet-section">
                                    <div className="flex space-between user-wallet-value">
                                        <div className="visits-counter align-center">
                                            <p style={{ color: 'var(--third-color)' }}>R${data?.walletTotalPaid}</p>
                                            <p>pagamento efetuado</p>
                                        </div>

                                        <div className="visits-counter align-center">
                                            <p style={{ color: 'var(--waiting-color)' }}>R${data?.walletTotalPendingApproval}</p>
                                            <p>pendente{data?.walletTotalPendingApproval === 1 ? '' : 's'} de aprovação</p>
                                        </div>

                                        <div className="visits-counter align-center">
                                            <p>R${data?.walletTotalAvailable}</p>
                                            <p>{data?.walletTotalAvailable === 1 ? 'disponível' : 'disponíveis'}</p>
                                        </div>
                                    </div>
                                </div>

                                {recordsFiltered > 0 && <h3 className="section-title">Pontos de Venda Visitados</h3>}
                                <div className="card email-body radius-left pl-10 pr-10 pt-5 p5-10">
                                    <div className="pt-15">
                                        {recordsFiltered > 0 &&
                                            <div style={{ width: '100%' }}>
                                                {visitsData.length > 0 && <GoogleApiWrapper
                                                    showCurrentLocation={false}
                                                    setLatitude={() => { }}
                                                    setLongitude={() => { }}
                                                    lat={visitsData[0].latitude} lon={visitsData[0].longitude}
                                                    searchedItems={visitsData.map(i => { i.name = i.posName; return i; })}
                                                    alwaysRender={true}></GoogleApiWrapper>}
                                            </div>
                                        }

                                        {recordsFiltered === 0 &&
                                            <div className="align-center">
                                                <div className="empty-list-txt mt-10">Nenhuma visita realizada até o momento</div>
                                                <br />
                                                <MapPin width={100} height={100} className="contact-icon" />
                                                <br />
                                                <br />
                                            </div>}
                                    </div>
                                </div>

                                {recordsFiltered > 0 &&
                                    <>
                                        <h3 className="section-title">Histórico de Visitas</h3>
                                        <div className="card pl-10 pr-10 pt-5">
                                            <VisitsTable
                                                ref={visitsTableRef}
                                                loadingTable={loadingVisits}
                                                data={visitsData}
                                                setData={setVisitsData}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                tableColumns={visitsColumns}
                                                setCurrentPage={setCurrentPage}
                                                currentPage={currentPage}
                                                recordsFiltered={recordsFiltered}
                                                getVisits={getVisits}
                                                canManage={true}
                                                sortColumn={visitSortColumn}
                                                sortDirection={sortDirection}
                                                toast={toast}
                                                approveModalIsOpen={approveModalIsOpen}
                                                setApproveModalIsOpen={setApproveModalIsOpen}
                                                approveModalVisitId={approveModalVisitId}
                                                multiSelectOption={false}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {reportedErrorsFiltered > 0 && reportedErrorsFiltered !== '...' && <div className="col-sm-12">
                <h3 className="section-title">Erros Reportados</h3>
                <div className="card pl-10 pr-10 pt-5">
                    <ReportedErrorsTable
                        loadingTable={loadingReportedErrors}
                        data={reportErrorsData}
                        setData={setReportErrorsData}
                        pageSize={reportErrorsPageSize}
                        setPageSize={setReportErrorsPageSize}
                        tableColumns={reportedErrorsColumns}
                        setCurrentPage={setReportErrorsCurrentPage}
                        currentPage={reportErrorsCurrentPage}
                        recordsFiltered={reportedErrorsFiltered}
                        getData={getReportedErrors}
                        sortColumn={reportErrorsSortColumn}
                        sortDirection={reportErrorSortDirection}
                    />
                </div>
            </div>}

            <ToastContainer />
        </Fragment>
    );
};

export default UserDetails;