import React from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useService } from "../../hooks/useService";
import { Alert } from "../../components/common/modals/modals.js";
import { getPowerBIReportId } from "../../config.js";
import 'powerbi-report-authoring';
import { Maximize } from 'react-feather';
import './report.css';
import { Link } from 'react-router-dom';

const CampaignReport = ({ campaignId }) => {
  const service = useService();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [reportPages, setReportPages] = React.useState([
    {displayName: 'Presença', index: 0},
    {displayName: 'Status Visitas', index: 1},
  ]);
  // PowerBI Report object (to be received via callback)
  const [powerbiReport, setpowerbiReport] = React.useState();
  const [powerbiReportConfig, setpowerbiReportConfig] = React.useState({
    type: 'report',
    id: undefined, 
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined, // Keep as empty string, null or undefined
    settings: {	
      panes: {
        filters: {
          expanded: false,
          visible: false
        }
      },
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth
      }
    }
  });

  React.useEffect(() => {
    getPowerBIAccessToken();
  }, [])

  async function changePage(rpt, newIndexPage) {
    if (!rpt || rpt == null)
      return;
    const pages = await rpt?.getPages();
  
    const visiblePages = pages.filter(function (page) {
      return page.visibility === 0
    });
  
    if(!reportPages[0].name) {
      setReportPages(visiblePages.map((p, index) => ({
        name: p.name,
        displayName: p.displayName,
        index: index
      })));
    }
    setCurrentPage(newIndexPage);
    powerbiReport.setPage(visiblePages[newIndexPage].name)
  }

  const getPowerBIAccessToken = async () => {
    const reportId = getPowerBIReportId();

    await service.get(`/api/powerbi/embedToken/${reportId}`)
    .then(async response => {
      var embedToken = response?.data?.data;
      if (embedToken === null) {
        return await Alert("", "Falha ao buscar token", "error");
      }

      const reportConfig = {
        EmbedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&ctid=7f2f75d8-4e75-4ac6-946f-a62ac439c07f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1iLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`,
        EmbedToken: embedToken
      }

      setpowerbiReportConfig({
        ...powerbiReportConfig,
        embedUrl: reportConfig.EmbedUrl,
        accessToken: reportConfig.EmbedToken,
        filters: [
          {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
              table: "CompleteVisit",
              column: "CampaignId",
            },
    
            operator: "eq",
            values: [campaignId],
            requireSingleSelection: true,
          },
          {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
              table: "CompletePOS",
              column: "CampaignId",
            },
    
            operator: "eq",
            values: [campaignId],
            requireSingleSelection: true,
          },
          {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
              table: "CompletePOSVisits",
              column: "CampaignId",
            },
    
            operator: "eq",
            values: [campaignId],
            requireSingleSelection: true,
          }
        ],
      });
    })
  };

  const selectPage = (newIndexPage) => {
    if (newIndexPage === currentPage)
      return;
    changePage(powerbiReport, newIndexPage)
  }

  return (
		<section>
      <div className="flex space-between alg-center">
        <div className="tab">
          {reportPages.map(page => (
            <div key={page.displayName} className={`tablinks ${page.index === currentPage && 'tab-selected'}`} onClick={() => selectPage(page.index)}>{page.displayName}</div>
          ))}
        </div>
        {window.location.pathname.indexOf('report') === -1 && <div className='mr-10 pointer'>
          <Link to={`/campaigns/${campaignId}/report`} target="_blank" rel="noopener noreferrer">
            <Maximize width="32px" heigth="32px" />
          </Link>
        </div>}
      </div>

			<PowerBIEmbed
				embedConfig = { powerbiReportConfig }
				eventHandlers = {
					new Map([
						// ['loaded', function (event) {
						// 	console.log('*** Report has loaded');
						// }],
						// ['rendered', async (event) => {
						// 	console.log('*** Report has rendered');
						// }],
						['error', function (event) { 
							if (event) {
								console.error(event.detail);
							}
						}]
					])
				}
				cssClassName = { "report-style-class" }
				getEmbeddedComponent = { async (embedObject) => {
					// console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
					setpowerbiReport(embedObject);
				} }
			/>
		</section>
	);

}

export default CampaignReport;
