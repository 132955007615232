import React from 'react';

const TextArea = ({ label, type, name, required, disabled = false, placeholder, value, onChange, error, onBlur, rows, maxLength }) => {
  return (
    <>
        <div className={`custom-input mb-20 ${error && 'field-error'}`}>
            <label htmlFor={name} className={`w-50vw label-filter ${required && "required"}`}>{label}</label>
            <br/>
            { disabled ? 
              <textarea 
                disabled
                id={name}                
                name={name}
                className={`w-50vw`}
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                rows={rows}
                maxLength={maxLength}
              />
              :
              <textarea 
                id={name}                
                name={name}
                className={`w-50vw`}
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                rows={rows}
                maxLength={maxLength}
              />
            }
            {error && <p className='error-field'>{error}</p>}
        </div>
    </>
  );
};

export default TextArea;