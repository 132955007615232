import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import useForm from '../../../hooks/useForm';
import { WEEK_DAY } from "../../../utils/enums";
import Input from '../../../components/common/form/input';
import Checkbox from '../../../components/common/form/Checkbox';

const WorkingHoursModal = ({updateWorkingHours, isOpen, setIsOpen, weekDaysIndexSelected, setWeekDaysIndexSelected, openingTimeDefault, closingTimeDefault}) => {

    const [error, setError] = React.useState('');

    const openingTime = useForm('time');
    const closingTime = useForm('time');
    const allDay = useForm('checkbox', false);
    const closed = useForm('checkbox', false);

    const closeWorkingHoursModal = () => setIsOpen(false);

    const weekDays = Object.entries(WEEK_DAY).map(([k,v]) => ({index: parseInt(k), weekDayInitial: v.substring(0, 1)}));

    React.useEffect(()=>{
        setError('');
        if(openingTimeDefault === '00:00' && closingTimeDefault === '23:59')
            allDay.setValue(true);
        else
            allDay.setValue(false);

        if(openingTimeDefault === '-1' && closingTimeDefault === '-1')
            closed.setValue(true);
        else
            closed.setValue(false);

        openingTime.setValue(openingTimeDefault);
        closingTime.setValue(closingTimeDefault);
       }, [isOpen]);

    const onClickWeekDay = (weekDayIndex) => {
        if(weekDaysIndexSelected.includes(weekDayIndex)) {
            let items = weekDaysIndexSelected.filter(w => w !== weekDayIndex);
            setWeekDaysIndexSelected([...items]);
        } else {
            let items = [...weekDaysIndexSelected];
            items.push(weekDayIndex);
            setWeekDaysIndexSelected([...items]);
        }
    }

    const updateWorkingTime = () => {
        if(weekDaysIndexSelected.length === 0) {
            setError('Selecione pelo menos um dia da semana');
            return;
        }
            

        if(!allDay.value && !closed.value) {
            openingTime.validate();
            closingTime.validate();
            
            if(!openingTime.validate() || !closingTime.validate()) return;
        }
        updateWorkingHours(weekDaysIndexSelected, allDay.value, closed.value, openingTime.value, closingTime.value);
    }

    return (
            <Modal isOpen={isOpen} toggle={closeWorkingHoursModal} className="modal-body" centered={true} style={{ maxWidth: '50%' }}>
                <ModalHeader toggle={closeWorkingHoursModal}>Editar Horário de Funcionamento</ModalHeader>
                <ModalBody>
                    <div className='working-hours-week-day-container mt-10'>
                        {weekDays.map(({index, weekDayInitial}) => (
                            <span onClick={() => onClickWeekDay(index)} key={index}
                                className={`pointer center ${weekDaysIndexSelected.filter(w => w === index).length > 0 ? 'working-hours-week-day-selected' : 'working-hours-week-day-unselected'}`}
                            >
                                {weekDayInitial}
                            </span>
                        ))}
                    </div>

                    <br/>

                    {!allDay.value && !closed.value && <div className="grid-col2">
                        <Input label='Horário de abertura' type='time' name='openingTime' required={false} {...openingTime} inputClassName='w-20vw' />
                        <Input label='Horário de fechamento' type='time' name='closingTime' required={false} {...closingTime} inputClassName='w-20vw' />
                    </div>}

                    <Checkbox label='Aberto 24 horas' checked={allDay.value} name='openedAllDay' disabled={closed.value} {...allDay} />
                    <Checkbox label='Fechado' checked={closed.value} name='closed' disabled={allDay.value} {...closed} />
                    
                    <p className="error-color">{error}</p>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-pill btn-danger" type="button" onClick={closeWorkingHoursModal}>Cancelar</button>
                    <button className="btn btn-pill btn-primary btn-air-primary" type="button" onClick={updateWorkingTime}>Salvar</button>
                </ModalFooter>
            </Modal>
        );
}

export default WorkingHoursModal;