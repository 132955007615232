import React, { Fragment } from "react";
import { Edit3 as Edit } from 'react-feather';
import { ToastContainer } from "react-toastify";
import { useParams, useLocation} from 'react-router-dom';

import useForm from '../../hooks/useForm';
import { states, isJSONString, insertCaractere } from "../../utils/helper";
import { getStorageUrl } from "../../config";
import { ROLES, WEEK_DAY } from "../../utils/enums";
import { useService } from "../../hooks/useService";
import Loading from "../../components/common/loading";
import Input from '../../components/common/form/input';
import Select from "../../components/common/form/select";
import Breadcrumb from "../../components/common/breadcrumb";
import { Alert } from "../../components/common/modals/modals";
import { Switch } from '../../components/common/switch/switch.js';
import WorkingHourModal from "./components/workingHoursModal";

const PointOfSaleForm = ({history}) => {

    const params = useParams();
    const location = useLocation();
    const service = useService();
    const [role, setRole] = React.useState(ROLES.EDIT);
    const [isNewRegister, setIsNewRegister] = React.useState(false);

    // Working hours modal
    const [workingHoursModalIsOpen, setWorkingHoursModalIsOpen] = React.useState(false);
    const [weekDaysIndexSelected, setWeekDaysIndexSelected] = React.useState([]);
    const [workingHourOpeningTime, setWorkingHourOpeningTime] = React.useState('0800');
    const [workingHourClosingTime, setWorkingHourClosingTime] = React.useState('0800');
    
    // PARAMETERS
    const name = useForm('name');
    const externalId = useForm(false);
    const isActive = useForm('isActive');
    const registerDate = useForm('date');
    const pointOfSaleType = useForm('pointOfSaleType', -1);
    const [pointOfSaleTypeOptions, setPointOfSaleTypeOptions] = React.useState([]);
    // location
    const address = useForm('address');
    const city = useForm('city');
    const country = useForm('country');
    const postalCode = useForm('postalCode');
    const state = useForm('state');
    const region = useForm('region');
    const latitude = useForm('latitude');
    const longitude = useForm('longitude');

    const establishmentPhoneNumber = useForm('establishmentPhoneNumber');
    const [workingHours, setWorkingHours] = React.useState([]);


    const [loading, setLoading] = React.useState(true);
    const [establishmentImagePath, setEstablishmentImagePath] = React.useState();

    const handleSubmit = async event => {
        event.preventDefault();

        name.validate();
        pointOfSaleType.validate();

        address.validate();
        city.validate();
        country.validate();
        postalCode.validate();
        state.validate();
        region.validate();
        latitude.validate();
        longitude.validate();
        longitude.validate();

        if (name.validate() && pointOfSaleType.validate() && address.validate() && city.validate() && country.validate() && postalCode.validate() && state.validate() && region.validate() && latitude.validate() && longitude.validate()) {
            isNewRegister? create() : update()
        }
    };

    const getCampaign = async (id) => {
        await service.get(`/api/PointOfSale/details/${id}`)
            .then(response => {
                const result = response?.data?.data;                

                if(result) {
                    name.setValue(result.name)
                    externalId.setValue(result.externalId)
                    isActive.setValue(result.isActive)
                    let rd = new Date(result.registerDate)
                    registerDate.setValue(`${rd.getFullYear()}-${(rd.getMonth()+1).toString().padStart(2, '0')}-${rd.getDate().toString().padStart(2, '0')}`)
                    pointOfSaleType.setValue(result.pointOfSaleTypeId)

                    address.setValue(result.address)
                    city.setValue(result.city)
                    country.setValue(result.country)
                    postalCode.setValue(result.postalCode)
                    state.setValue(result.state)
                    region.setValue(result.region)
                    latitude.setValue(result.latitude)
                    longitude.setValue(result.longitude)
                    establishmentPhoneNumber.setValue(result.establishmentPhoneNumber)

                    if(result.establishmentImagePath) {
                        let imageAddress = getStorageUrl().concat(result.establishmentImagePath);
                        setEstablishmentImagePath(imageAddress.replace(".net//", ".net/"))
                    }

                    if(result.workingHours && result.workingHours !== null && result.workingHours !== "null" && isJSONString(result.workingHours)) {
                        let workingTime = JSON.parse(result.workingHours);
                        setWorkingHours([...workingTime]);
                    };
                }else {
                    console.log('ERROR')
                    history.replace('/pdv/list')
                }
                
                setLoading(false);
            })
            .catch(e => {
                console.log('ERROR: ', e)
                history.replace('/pdv/list')
            })
    }

    const getPDVTypes = async () => {
        await service.get(`/api/PointOfSaleType/list`)
            .then(response => {     
                setPointOfSaleTypeOptions(response?.data?.data);
            })
    }

    const getPayload = () => {
        var payload = {
            name: name.value,
            externalId: externalId.value,
            pointOfSaleTypeId: pointOfSaleType.value,
            isActive: isActive.value,
            address: address.value,
            city: city.value,
            country: country.value,
            postalCode: postalCode.value,
            state: state.value,
            region: region.value,
            latitude: latitude.value,
            longitude: longitude.value,
            establishmentPhoneNumber: establishmentPhoneNumber.value,
            workingHours: workingHours.length > 0 ? JSON.stringify(workingHours) : null
        }

        return payload;
    }

    const create = async () => {        
    }

    const update = async () => {
        var payload = getPayload();
        payload.id = params.id;
  
        await service.put(`/api/PointOfSale/edit`, payload)
            .then(response => {
                const success = response?.data?.status === 200;
  
                if(success){
                  Alert("Ponto de Venda atualizado com sucesso!", "", "success");
                //   history.replace(`/campaigns/list`);
                }else{
                  Alert(response.data.title ? response.data.title : "Não foi possível atualizar o Ponto de Venda", "", "error");
                }
            })
    }

    React.useEffect(() => {
        async function init() {
        
            const create = location.pathname.indexOf("new") !== -1;
            let id = create ? undefined : params.id;
            getPDVTypes();

            if (id) {
                const edit = location.pathname.indexOf("details") === -1;
                setIsNewRegister(false);
                if (edit)
                    setRole(ROLES.EDIT);
                else
                    setRole(ROLES.READ);
    
                getCampaign(id); 
            } else {
                setLoading(false);
            }
        }
        init()
    }, []);

    const postalCodeOnChange = ({target}) => {
        let postalCodeField = target.value.replace(' ', '');
        var re = /^([\d]{5})\-?([\d]{3})/;
        postalCodeField = postalCodeField.replace(re,"$1-$2");
        postalCode.setValue(postalCodeField);
    }

    const updateWorkingHours = (weekDays, allDay, closed, openingTime, closingTime) => {

        let workingHoursEdit = workingHours;
        weekDays.forEach(weekDayIndex => {
            let day = workingHoursEdit.find(w => w.WeekDay === weekDayIndex);

            if (!day)
                Alert("Erro ao atualizar o horário de funcionamento", "", "error");

            if (closed) {
                day.OpeningTime = '-1';
                day.ClosingTime = '-1';
            } else if (allDay) {
                day.OpeningTime = '0000';
                day.ClosingTime = '2359';
            } else {
                day.OpeningTime = openingTime.replace(':', '');
                day.ClosingTime = closingTime.replace(':', '');
            }
        });

        setWorkingHours([...workingHoursEdit])

        setWeekDaysIndexSelected([]);
        setWorkingHoursModalIsOpen(false);

    }

    const addWorkingHours = () => {
        let workingHoursList = [];

        Object.entries(WEEK_DAY).map(([k,v]) => {
            workingHoursList.push(
            {
                WeekDay: parseInt(k),
                OpeningTime: "-1",
                ClosingTime: "-1"
            });
        });
        setWorkingHours([...workingHoursList]);
    }

    return (
        <main className='form-container campaign-form'>
            <div className="ml-5p">
                <Breadcrumb title={isNewRegister ? "Cadastro" : "Edição"} parent="Pontos de Venda" marginLeft={true} />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card form-card">
                            <div className="card-body">

                                {loading && <Loading/>}

                                { !loading && <Fragment>
                                    {establishmentImagePath && <img className="pdv-detail-photo" src={establishmentImagePath} alt='point of sale store' />}
                                    <h1 className='align-center pt-15'>{name.value}</h1>
                                    <div className="card-body">
                                        <form className="needs-validation" noValidate="" onSubmit={handleSubmit}>

                                            <div className="grid-col2">
                                                <Input label='Data de cadastro' type='date' name='registerDate' disabled={true} {...registerDate} inputClassName='w-20vw' />
                                                <Input label='External ID' type='text' name='externalId' required={false} {...externalId} maxLength={50} inputClassName='w-20vw' />
                                            </div>
                                            <Input label='Nome' type='text' name='name' required={true} {...name} maxLength={50} inputClassName='w-50vw' />
                                            <Input label='Telefone' type='text' name='establishmentPhoneNumber' disabled={true} {...establishmentPhoneNumber} inputClassName='w-20vw' />

                                            <Select 
                                                label='Tipo do Ponto de Venda'
                                                name='pointOfSaleType'
                                                required={true}
                                                {...pointOfSaleType}
                                                options={pointOfSaleTypeOptions}
                                                disabled={false}
                                            />

                                            <br/>
                                            <div>
                                                <label style={{ fontSize: '14px'}}>
                                                    Ponto de Venda {isActive.value ? 'Ativo' : 'Inativo'}
                                                </label>
                                                <Switch 
                                                    handleToggle={() => isActive.setValue(!isActive.value)}
                                                    isOn={isActive.value}
                                                    id="point-of-sale-toggle"/>
                                            </div>

                                            <h3 className="color-primary align-center pt-15"><b>Localização</b></h3>
                                            <Input label='Endereço' type='text' name='address' required={true} {...address} maxLength={50} inputClassName='w-50vw' />                                            
                                            
                                            <div className="grid-col2">
                                                <Input label='CEP' type='text' name='postalCode' required={true} {...postalCode} maxLength={9} onChange={postalCodeOnChange} inputClassName='w-20vw' />
                                                <Input label='Cidade' type='text' name='city' required={true} {...city} maxLength={50} inputClassName='w-20vw' />
                                                <Input label='Região' type='text' name='region' required={true} {...region} maxLength={50} inputClassName='w-20vw' />
                                                <Select 
                                                    label='Estado'
                                                    name='state'
                                                    required={true}
                                                    {...state}
                                                    options={states}
                                                    disabled={false}
                                                />
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='Longitude' type='number' name='longitude' required={true} {...longitude} inputClassName='w-20vw' />
                                                <Input label='Latitude' type='number' name='latitude' required={true} {...latitude} inputClassName='w-20vw' />
                                            </div>

                                            <h3 className="color-primary align-center pt-15"><b>Horário de Funcionamento</b></h3>
                                            
                                            <table className="working-hours-table">
                                                <tbody>
                                                    {workingHours.length === 0 ?
                                                    <>
                                                        <p>Não cadastrado para este estabelecimento</p>
                                                        <p className="clickable-p pointer" onClick={addWorkingHours}>Cadastrar</p>
                                                    </>
                                                    :
                                                        workingHours.map(workingHour => (
                                                            <tr key={workingHour.WeekDay}>
                                                                <td><b>{WEEK_DAY[workingHour.WeekDay]}</b></td>
                                                                    {workingHour.OpeningTime === '-1' && workingHour.ClosingTime === '-1' ?
                                                                        <td>
                                                                            <span>Fechado</span>
                                                                        </td>
                                                                    :
                                                                        workingHour.OpeningTime === '0000' && workingHour.ClosingTime === '2359' ?
                                                                        <td>
                                                                            <span>Aberto 24 horas</span>
                                                                        </td>
                                                                    :
                                                                        <td>
                                                                            <span>{insertCaractere(workingHour.OpeningTime, 2, ':')}</span>
                                                                            <span>-</span>
                                                                            <span>{insertCaractere(workingHour.ClosingTime, 2, ':')}</span>
                                                                        </td>
                                                                    }
                                                                <td className='pointer' onClick={() => {
                                                                    let items = [...weekDaysIndexSelected, workingHour.WeekDay];
                                                                    setWeekDaysIndexSelected([...items]);
                                                                    setWorkingHoursModalIsOpen(true);

                                                                    if(workingHour.OpeningTime !== '-1' && workingHour.ClosingTime !== '-1') {
                                                                        setWorkingHourOpeningTime(insertCaractere(workingHour.OpeningTime, 2, ':'));
                                                                        setWorkingHourClosingTime(insertCaractere(workingHour.ClosingTime, 2, ':'));
                                                                    }else {
                                                                        setWorkingHourOpeningTime(workingHour.OpeningTime);
                                                                        setWorkingHourClosingTime(workingHour.ClosingTime);
                                                                    }
                                                                }}>
                                                                    <Edit />
                                                                </td>
                                                            </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div className="grid-col2">
                                            </div>

                                            <br/>

                                            <div className='align-center'>
                                                <button className="btn btn-pill btn-outline-secondary" type="submit">{role === ROLES.EDIT ? "Atualizar" : "Cadastrar"}</button>
                                            </div>

                                        </form>
                                    </div>
                                </Fragment> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WorkingHourModal updateWorkingHours={updateWorkingHours}
                isOpen={workingHoursModalIsOpen} setIsOpen={setWorkingHoursModalIsOpen}
                weekDaysIndexSelected={weekDaysIndexSelected} setWeekDaysIndexSelected={setWeekDaysIndexSelected}
                openingTimeDefault={workingHourOpeningTime} closingTimeDefault={workingHourClosingTime}
            />
            <ToastContainer />
        </main>
    );
};

export default PointOfSaleForm;