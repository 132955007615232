import React, { Fragment, useState } from 'react';
import axios from "axios";
import { useService } from "../../../hooks/useService";
import Breadcrumb from '../../../components/common/breadcrumb';
import { getImagePath } from '../components/visitsHelper';
import { Alert } from "../../../components/common/modals/modals";

import { useAuth0 } from "@auth0/auth0-react";

const ProductsList = () => {
    const service = useService();
    const { getAccessTokenSilently, user } = useAuth0();

    const [visitReports, setVisitReports] = useState([]);

    const getPendingReports = async (id) => {
        await service.get(`/api/visit/pendingExports`)
            .then(async response => {
                var reports = response.data.data;
                setVisitReports(reports);
            })
    }
    let containerName = 'visits-pdf-report-backoffice-temp';
    const downloadReport = async (exportDate, userId) => {
        let link = `${getImagePath("")}${containerName}/${exportDate}_${userId}.pdf`;
        await service.get(link, {
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `visitas${exportDate}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((e) => {
                console.log('e', e)
                Alert('Ops, não foi possível fazer o download das visitas em PDF. Por favor tente novamente mais tarde.');
            })
            .finally(() => {
            });
    }

    React.useEffect(() => {
        getPendingReports();
    }, []);

    return (
        <Fragment>
            <Breadcrumb title="PDF de visitas" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <h5>PDF de visitas para download (gerados nas últimas 24 horas)</h5>
                            </div>
                            <div className="card-body datatable-react">
                                {visitReports?.map(v => (
                                    <>
                                        {v.isConcluded ?
                                            <a href={`${getImagePath("")}${containerName}/${v.exportDate}_${v.userId}.pdf`} target="_blank"
                                                rel="noopener noreferrer">
                                                <p className='add-item'>Baixar visita gerada em {v.exportDate}</p>
                                            </a>
                                            :
                                            <div className='pl-20'>
                                                <p>Visita gerada em {v.exportDate} está sendo processada, por favor aguarde</p>
                                            </div>
                                        }

                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductsList;