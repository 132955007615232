import React from 'react';

const Select = ({ label, name, required, value = -1, onChange, options, error, disabled = false }) => {

  React.useEffect(() => {
  }, [value])

  return (
    <div className={`label-filter${error && ' field-error'}`}>
        <label htmlFor={name} className={`${required && "required"}`} style={{fontSize: '14px'}}>{label}</label>
        <br/>
        <select
          style={{width: '200px'}}
          defaultValue={value}
          disabled={disabled}
          onChange={onChange}>
            <option value={-1} disabled>Selecione</option>
            {options.map((option, index) =>
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
            )}
        </select>

        {error && <p className='error-field'>{error}</p>}
    </div>
  );
};

export default Select;