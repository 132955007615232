import React from 'react';
import Modal from './Modal';
import './style.css';

function PhotoViewer({ images, modalVisible, setModalVisible, activeIndex, setActiveIndex, showProductButtons }) {
    return (
        <Modal 
        images={images} showIndex={true} showPreview={true}
        visible={modalVisible} setVisible={setModalVisible}
        activeIndex={activeIndex} setActiveIndex={setActiveIndex} showProductButtons={showProductButtons}>
        </Modal>
    )
}

export default PhotoViewer;
