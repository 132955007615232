import React from 'react';
import { Upload } from 'react-feather';

const ImportButton = ({value, onclick}) => {
    return <button className="btn btn-pill btn-outline-secondary btn-icon" type="button" onClick={onclick}>
    
        <Upload style={{display: 'flex', marginRight: '6px'}} />
      
        Importar {value}</button>
}

export default ImportButton;