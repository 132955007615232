import React from 'react';
import placeholderSrc from "../../assets/images/loading-buffering.gif";

const ProgressiveImage = ({src, loadedClass = 'visit_details_gondola_loaded'}) => {
  const [sourceLoaded, setSourceLoaded] = React.useState();

  React.useEffect(() => {
    const imageLoader = new Image();
      imageLoader.src = src;
      imageLoader.onload = () => setSourceLoaded(src);;
  }, []);

  return (
    <div
      style={{ backgroundImage: `url(${sourceLoaded || placeholderSrc})` }}
      className={`${sourceLoaded ? loadedClass + ' pointer' : 'visit_details_gondola_loading'}`}>
    </div>
  )
}

export default ProgressiveImage;