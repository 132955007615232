import React from 'react';
import { Eye, EyeOff } from 'react-feather';
import LineTo from 'react-lineto';
import { getPercentage as getNumberPercentage } from '../../utils/helper';
import useWindowDimensions from '../common/windows-dimensions';

const OFFSET_DEFAULT = {
  x: 0,
  y: 0
};

const ZOOM_LEVEL = {
  MIN: 0,
  MAX: 4
};

function Wrapper({ image, showIndex, index, showProductButtons, openContentJsonModal }) {
  const imageRef = React.createRef(null);
  const { screenHeight, screenWidth } = useWindowDimensions();

  const [imageWidth, setImageWidth] = React.useState(200);
  const [imageHeight, setImageHeight] = React.useState(200);
  const [imageOriginalWidth, setImageOriginalWidth] = React.useState(0);
  const [imageOriginalHeight, setImageOriginalHeight] = React.useState(0);
  const [showBoxes, setShowBoxes] = React.useState(false);
  const [productIndex, setProductIndex] = React.useState(0);

  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [widthProportionPercentage, setWidthProportionPercentage] = React.useState(1);
  const [heigthProportionPercentage, setHeigthProportionPercentage] = React.useState(1);

  const [src, setsrc] = React.useState();
  const [mounted, setmounted] = React.useState();
  const [loading, setloading] = React.useState(false);
  const [onload, setonload] = React.useState(false);
  const [zoom, setzoom] = React.useState(0);
  const [offset, setoffset] = React.useState(OFFSET_DEFAULT);
  const [draggable, setdraggable] = React.useState(false);
  const [offsetRange, setoffsetRangeState] = React.useState(OFFSET_DEFAULT);
  const [clientOffset, setclientOffset] = React.useState({
    x: undefined,
    y: undefined
  });

  const loadImage = (laodSource) => {
    setloading(true)
    var source = new Image();
    source.src = laodSource;
    source.onload = () => {
      if (!source) return;
      setImageWidth(source.width);
      setImageHeight(source.height);
      setImageOriginalWidth(source.width);
      setImageOriginalHeight(source.height);
      setloading(false);
      setonload(true);
      resizeComponents();
    }
    source.onerror = () => {
      if (!source) return;
      setloading(false);
      setonload(false);
    }
    setsrc(source);
  }

  const resizeComponents = () => {
    if (imageOriginalWidth === 0) return;

    if (imageRef?.current?.width) {
      let imageName = image?.id + '-img-gallery';

      let width = document.getElementById(imageName).width;
      let height = document.getElementById(imageName).height;

      let decreasePercentage = 0.90;
      while (width > screenWidth || height > screenHeight) {
        width = width * decreasePercentage;
        height = height * decreasePercentage;
      }

      setImageWidth(width);
      setImageHeight(height);

      let proportionPercentageW = getNumberPercentage(imageOriginalWidth, width);
      setWidthProportionPercentage(proportionPercentageW * 0.01);

      let proportionPercentageH = getNumberPercentage(imageOriginalHeight, height);
      setHeigthProportionPercentage(proportionPercentageH * 0.01);
    }
  }

  const resetOffset = () => {
    setoffset({ ...OFFSET_DEFAULT });
  }

  const setOffsetRange = () => {
    if (imageRef?.current) {
      const dx = imageRef?.current?.scrollWidth * (1 + zoom / 2) - imageRef?.current?.clientWidth;
      const dy = imageRef?.current?.scrollHeight * (1 + zoom / 2) - imageRef?.current?.clientHeight;
      setoffsetRangeState({
        x: Math.max(0, dx / 2),
        y: Math.max(0, dy / 2)
      });
    }
  }

  const zoomIn = () => {
    if (!onload) return;
    setzoom(Math.min(zoom + 1, ZOOM_LEVEL.MAX));
    setOffsetRange();
  }

  const zoomOut = () => {
    if (!onload) return;
    setzoom(Math.max(0, zoom - 1));
    resetOffset();
    setOffsetRange();
  }

  const onMoveStart = (axisX, axisY) => {
    if (!offsetRange.x && !offsetRange.y) {
      return;
    }
    setclientOffset({
      x: axisX,
      y: axisY
    });
    setdraggable(true);
  }

  const onMove = (axisX, axisY) => {
    if ((!axisX && !axisY) || !draggable) {
      return;
    }
    const currentoffset = {
      x: axisX - clientOffset.x,
      y: axisY - clientOffset.y,
    };

    setclientOffset({
      x: axisX,
      y: axisY
    });

    setoffset({
      x: offset.x + currentoffset.x,
      y: offset.y + currentoffset.y,
    });
  }

  const onMoveEnd = () => {
    if (!mounted) return;
    setdraggable(false);

    const newoffset = {
      x: Math.abs(offset.x),
      y: Math.abs(offset.y)
    }

    if (Math.abs(newoffset.x) >= offsetRange.x) {
      let currentoffset = offset;
      currentoffset.x = offset.x < 0 ? Math.min(0, -(offsetRange.x)) : Math.max(0, offsetRange.x);
      setoffset(currentoffset);
    }

    if (Math.abs(offset.y) >= offsetRange.y) {
      let currentoffset = offset;
      currentoffset.y = offset.y < 0 ? Math.min(0, -(offsetRange.y)) : Math.max(0, offsetRange.y);
      setoffset(currentoffset);
    }
  };

  const MainImage = ({ imageRef, image }) => {
    return (
      <img
        ref={imageRef}
        className="image"
        width={`${imageWidth}px`}
        height={`${imageHeight}px`}
        src={image?.src}
        id={`${image?.id}-img-gallery`}
        alt={image?.products?.length > 0 ? image?.products[productIndex]?.title : ''}
        draggable={false}
        onDragStart={(e) => e.preventDefault()}
        onMouseMove={(e) => { onMove(e.clientX, e.clientY) }}
        onMouseDown={(e) => { onMoveStart(e.clientX, e.clientY) }}
        onLoad={() => setImageLoaded(true)}
        onMouseUp={(e) => { onMoveEnd(e.clientX, e.clientY) }}
      />
    )
  }

  React.useEffect(() => {
    window.addEventListener('mousemove', () => { });
    setmounted(true);
    loadImage(image?.src);
    document.documentElement.addEventListener('mouseup', (e) => { onMoveEnd(e.clientX, e.clientY) });

    // returned function will be called on component unmount 
    return () => {
      // window.removeEventListener('mousemove', () => {})
      setmounted(false)
      if (!!src) {
        setsrc(undefined);
      }
      document.documentElement.removeEventListener('mouseup', () => { setOffsetRange() });
    }
  }, []);

  React.useEffect(() => {
    if (image?.src !== src) {
      setImageLoaded(false);
      resetOffset();
      loadImage(image?.src);
      setzoom(0);
    }
  }, [image]);

  React.useEffect(() => {
    if (imageLoaded) {
      resizeComponents();
      resetOffset();

      if(showProductButtons) {
        setShowBoxes(image?.boxes?.length > 0);
      }
    }
  }, [imageLoaded]);

  const value = `translate3d(${offset.x}px, ${offset.y}px, 0px)`;
  const imageCls = `zoom-${zoom} ${draggable && zoom > 1 ? 'dragging' : ''}`;

  const getPercentage = (number) => {
    return `${Math.round(number * 100) / 100}%`;
  }

  return (
    <>
      {loading ? (
        <div className="spinner">
          <div className="bounce"></div>
        </div>
      ) :
        <>
          <div className={`image-wrapper`}
            style={{ position: 'relative', minHeight: imageHeight + 'px', maxHeight: imageHeight + 'px', minWidth: imageWidth + 'px', maxWidth: imageWidth + 'px' }}>
            <div className={`image-outer ${imageCls}`} style={{ transform: value, position: 'absolute' }}>
              <MainImage imageRef={imageRef} image={image} />
            </div>
            {showBoxes && (
              <div
                className="box-container"
                style={{ maxWidth: imageWidth + 'px' }}
                width={imageWidth / 2 + 'px'}
                height={imageHeight + 'px'}>
                {image?.boxes?.map(b => (
                  <div key={b.order} style={{ marginLeft: `${b.x * widthProportionPercentage}px`, marginTop: `${b.y * heigthProportionPercentage}px`, minWidth: b.w * widthProportionPercentage, minHeight: `${b.h * heigthProportionPercentage}px` }} className={`shelfItem shelfItem-product-${productIndex} boxItem-${b.itemId}`}>
                    {b?.products?.length > 0 &&
                      <div>
                        <div className='flex space-between flex-column'>
                          <p className="box-title"><b>{b?.products[productIndex]?.name}</b> - {getPercentage(b?.products[productIndex]?.productScore)}</p>
                          <div style={{ maxWidth: imageWidth / 2 + 'px' }}>
                            <p className="box-detail"><b>EAN:</b>&nbsp;{b?.products[productIndex]?.ean}</p>
                            <div className="shelf-tooltip">
                              <p className="box-detail"><b>ACP:</b>&nbsp;{b?.products[productIndex]?.productScore}%</p>
                              <span>Acuracidade do Produto</span>
                            </div>

                            <div className="shelf-tooltip">
                              <p className="box-detail"><b>ACB:</b>&nbsp;{b.boxScore}%</p>
                              <span>Acuracidade da Box</span>
                            </div>
                            <p className="box-detail"><b>Marca:</b>&nbsp;{b.products[productIndex]?.name}</p>
                          </div>
                          <div className='box-detail'>
                            <p
                              onClick={() => openContentJsonModal('PRODUTOS')}
                              className={`pointer visit-details-json-view-button`}>
                              {`{;}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                )
                )}

                {image?.prices?.map(price => (
                  <div key={price.id} style={{ marginLeft: `${price.x * widthProportionPercentage}px`, marginTop: `${price.y * heigthProportionPercentage}px`, minWidth: price.w * widthProportionPercentage, minHeight: `${price.h * heigthProportionPercentage}px` }} className={`shelfItem shelfItem-price ${price.id}`}>
                    <div>
                      <div className='flex space-between flex-column'>
                        <div style={{ maxWidth: imageWidth / 2 + 'px' }}>
                          <p className="box-title"><b>R$</b>{price?.amount}</p>
                          <div className="shelf-tooltip">
                            <p className="box-detail">R${price?.text}</p>
                          </div>
                          <div className="shelf-tooltip">
                            <p className="box-detail"><b>AC: </b>{getPercentage(price?.boxScore)}</p>
                            <span>Acuracidade do Preço</span>
                          </div>
                        </div>
                        <div className='box-detail'>
                          <p
                            onClick={() => openContentJsonModal('PREÇO')}
                            className={`pointer visit-details-json-view-button`}>
                            {`{;}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                )}

                <div className='price-box-con'>

                </div>

                {/* connecting shelfItems with price */}
                {image?.prices?.map(price => (
                  price.itemsIds.map(itemId => (
                    <LineTo key={`${price.id}-${itemId}`} from={price.id} to={`boxItem-${itemId}`} borderWidth={3} fromAnchor='top' toAnchor='bottom' borderColor='red' className='price-lineto-line' within='price-box-con' delay={true} />
                  ))
                ))}
              </div>)
            }
          </div>
        </>
      }
      <div className="tool-bar">
        {showIndex && <div className="index-indicator">{index}</div>}
        <p className="caption">
          {image?.products?.length > 0 && <span className="title">{image.products[productIndex]?.title}</span>}
        </p>
        <div className="button-group">
          {zoom === 0 && showProductButtons && (<div style={{ marginTop: '0.4em' }}>
            {image?.products?.map((product, i) => (
              i < 3 && (<button className={`products-btn products-btn-${i + 1} ${productIndex === i && 'products-btn-selected'}`} onClick={() => setProductIndex(i)}>
                {productIndex === i ? <Eye color="white" width="24px" height="24px" /> : <EyeOff color="white" width="24px" height="24px" />}
                <span>Produto {i + 1}</span>
              </button>)
            ))}
          </div>)}

          {!showBoxes && <>
            {zoom > 0 && <div className="zoom-out button" onClick={(e) => { zoomOut(e) }}></div>}
            <div className="zoom-in button" onClick={(e) => { zoomIn(e) }}></div>
          </>}

          {showBoxes && image?.boxes?.length > 0 && zoom === 0 && <div className="button-round-photo-view" onClick={() => setShowBoxes(!showBoxes)}>
            {showBoxes ? <Eye color="white" width="48px" height="48px" /> : <EyeOff color="white" width="48px" height="48px" />}
          </div>}
        </div>
      </div>
    </>
  )
}

export default Wrapper;
