import React, { Fragment, useState } from 'react';
import DynamicFilter from '../../components/common/filters/dynamic_filter';
import { usersColumns as columns } from "../../utils/tablesHelper";
import { getMoneyFormat, updateFilterValues } from "../../utils/helper";
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from "react-toastify";
import Datatable from "../../components/tables/table";
import { useService } from "../../hooks/useService";
import emptyImage from '../../assets/images/emptylist.png';
import paymentImage from '../../assets/images/payment.png';
import MakePaymentModal from './components/makePaymentModal';
import { usersFilters } from './components/filters';
import UserInvitationsModal from './components/userInvitationsModal';
import { ThumbsUp, ThumbsDown } from 'react-feather';

const UsersList = ({ history }) => {

    const localStorageFilterKey = 'users-filters';
    const [loadingTable, setLoadingTable] = useState(true);
    const [data, setData] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const service = useService();

    // Make payment modal state
    const [makePaymentIsOpen, setMakePaymentModalIsOpen] = React.useState(false);
    const [makePaymentUserId, setMakePaymentUserId] = React.useState(false);
    const [makePaymentUserName, setMakePaymentUserName] = React.useState(false);
    const [makePaymentWalletTotalAvailable, setMakePaymentWalletTotalAvailable] = React.useState(0);

    // User invitations details state
    const [showInviteDetailsIsOpen, setshowInviteDetailsIsOpen] = React.useState(false);
    const [inviteDetailsUserName, setInviteDetailsUserName] = React.useState('');
    const [inviteDetailsUserInvites, setInviteDetailsUserInvites] = React.useState([]);

    const [tableFilters, setTableFilters] = React.useState([...usersFilters]);

    const getUsers = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            sortColumn = null;

        setSortColumn(sortColumn);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumn, orderDirection, page, currentPageSize)

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {

            if(filter.name === 'phoneNumber' && filter.value !== ''){
                let number = filter.value.replace("(", "").replace(")", "").replace("-", "");
                filters.push({
                    name: filter.name,
                    value: number.substring(2, number.length)
                })
            } 
            else if(filter.value !== '' && filter.value != null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value !== 'empty' ? filter.value.trim() : null
                })
            }
        });

        if(filters.length === 0 && initialFilters != null){
            //is the first load
            setSortColumn('RegisterDate');
            sortColumn = 'RegisterDate';
            setSortDirection(1);
            orderDirection = 1;
        }

        var skip = 0;

        if(!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);
        
        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/user/list", payload)
        .then(async response => {
            if (response === undefined || response?.data?.data === null) {
                return;
            }

            var items = response?.data?.data?.data.map(item => {
                let newVisitsAmount = 0;
                let usedInvitesAmount = 0;

                var invitations = []

                item.invitations.forEach((invitation) => {
                    if(invitation?.usages?.length > 0) {
                        usedInvitesAmount += invitation?.usages?.length;
                        invitation.usages.map(usage => {                            
                            newVisitsAmount += usage.invitedUserVisitsAmount;
                            let invite = {
                                code: invitation.code,
                                expirationDate: new Date(invitation.expirationDate).toLocaleDateString('pt-BR'),
                                registerDate: new Date(invitation.registerDate).toLocaleDateString('pt-BR'),
                                invitedUserEmail: usage.invitedUserEmail,
                                invitedUserId: usage.invitedUserId,
                                invitedUserVisitsAmount: usage.invitedUserVisitsAmount,
                                usageDate: new Date(usage.usageDate).toLocaleDateString('pt-BR'),
                                rewardGranted: usage.rewardGranted ? <ThumbsUp className="secondary-color" /> :  <ThumbsDown className="error-color" />
                            }
                            invitations.push(invite);
                        })
                    }else {
                        let invite = {
                            code: invitation.code,
                            expirationDate: new Date(invitation.expirationDate).toLocaleDateString('pt-BR'),
                            registerDate: new Date(invitation.registerDate).toLocaleDateString('pt-BR'),
                            invitedUserEmail: '-',                            
                            invitedUserVisitsAmount: '-',
                            usageDate: '-',
                            rewardGranted: '-'
                        }
                        invitations.push(invite);
                    }                
                });

                let user = {
                    id: item.id,
                    registerDate: new Date(item.registerDate).toLocaleDateString('pt-BR'),
                    isActive: item.isActive,
                    name: <p className="clickable-p pointer" onClick={() => {history.push(`/users/details/${item.id}`)}}>{item.name}</p>,
                    username: item.isSocialGoogleUser ? (<div className='flex-no-wrap alg-center'>
                        <img className='google-icon' src={require("../../assets/images/gmail-24x24.png")} alt="gmail icon" /><p className='pl-10'>{item.username}</p>
                    </div>)
                    : item.username,
                    phoneNumber: item.phoneNumber,
                    age: `${item.age} ${item.age === 1 ? 'ano ' : ' anos'}`,
                    walletTotalAvailable: item.wallet?.totalAvailable && item.wallet?.totalAvailable !== 0 ? getMoneyFormat(item.wallet?.totalAvailable).replace('.', ',') : '-',
                    walletTotalPendingApproval: item.wallet?.totalPendingApproval && item.wallet?.totalPendingApproval !== 0 ? getMoneyFormat(item.wallet?.totalPendingApproval).replace('.', ',') : '-',
                    walletTotalPaid: item.wallet?.totalPaid && item.wallet?.totalPaid !== 0 ? getMoneyFormat(item.wallet?.totalPaid).replace('.', ',') : '-',
                    pixKey: item.wallet?.pixKey ?? '-',
                    lastRequestWalletWithdraw: item.wallet?.lastRequestWalletWithdraw ? new Date(item.wallet?.lastRequestWalletWithdraw).toLocaleDateString('pt-BR') : '-',
                    makePayment: (<div className="flex-no-wrap place-center pointer" onClick={() => {
                        setMakePaymentModalIsOpen(true);
                        setMakePaymentUserId(item.id);
                        setMakePaymentUserName(item.name);
                        setMakePaymentWalletTotalAvailable(item.wallet?.totalAvailable);
                    }}>
                        <img className='payment-icon' src={paymentImage} alt="payment icon" />
                     </div>),
                    numberOfVisits: item.numberOfVisits !== 0 ? item.numberOfVisits : '-',
                    lastVisit: item.lastVisit ? new Date(item.lastVisit).toLocaleDateString('pt-BR') : '-',
                    city: item.city ?? '-',
                    state: item.state ?? '-',
                    location: item.location ?? '-',
                    invitesList: invitations,
                    invitations: (<>
                        {
                            (item.invitations === null || item.invitations?.length === 0) 
                            ? 
                                <p>0 / 0 / 0</p> 
                            : 
                        (
                            <div className="flex-no-wrap place-center pointer" onClick={() => {
                                setshowInviteDetailsIsOpen(true);
                                setInviteDetailsUserName(item.name);
                                setInviteDetailsUserInvites(invitations);
                            }}>
                                <p>
                                    <span className="main-color-tag">
                                        {item.invitations?.length}
                                    </span>
                                    <span> / </span>
                                    <span className={usedInvitesAmount > 0 ? 'main-color-tag' : ''}>
                                        {usedInvitesAmount}
                                    </span>
                                    <span> / </span>
                                    <span className={newVisitsAmount > 0 ? 'main-color-tag' : ''}>
                                        {newVisitsAmount}
                                    </span>
                                </p>
                            </div>)
                        }                        
                    </>                    
                    )
                };
                return user;
            })

            setData(items);
            if(response?.data?.data?.recordsFiltered)
                setRecordsFiltered(response.data.data.recordsFiltered);            

        if (firstLoad)
            setFirstLoad(false);

        }).finally(() => {
            setLoadingTable(false);
        });
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);
        
        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem('users-table-config');
        
        setTableFilters(tableFilters.map(t => {

            if(t.type === 'date-range-picker') {
                t.valueEnd = null;
                t.valueStart = null;
            }else {
                t.value = '';
            }
            return t;
        }))
        
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);
        window.location.reload();
        await getUsers();
        setLoadingTable(false);
    }

    const changeActiveStatus = async (user, index) => {
        await service.post(`/api/user/active/${user.id}/${!user.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];
  
                    let item = items.find((it) => it.id === user.id);
                    user.active = !user.active;
                    user.isActive = !user.isActive;
                    items[index] = item;
                    setData(items);
  
                    let change = !user.isActive ? "inativado" : "ativado";
                    toast.success("Usuário " + change + " com sucesso!");
                } else {
                    let change = !user.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " o usuário");
                }
            })
      }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }
        localStorage.setItem('users-table-config', JSON.stringify(tableConfig));
    }

    React.useEffect(() => {

        var filtersResult = localStorage.getItem(localStorageFilterKey);
        var page = 0;
        var pageSize = 10;
        var sortColumn = null;
        var sortDirection = 0;

        var filters = [];

        if(filtersResult != null) {
            const resultFilters = JSON.parse(filtersResult);
            filters = updateFilterValues(usersFilters, resultFilters);
            setTableFilters(filters);                    
        }

        var tableConfigResult = localStorage.getItem('users-table-config');

        if(tableConfigResult != null) {

            var tableConfig;
            if(tableConfigResult)
                tableConfig = JSON.parse(tableConfigResult);

            if(tableConfig?.page){
                setCurrentPage(tableConfig.page);
                page = tableConfig.page;
            }

            if(tableConfig?.pageSize) {
                setPageSize(tableConfig.pageSize);
                pageSize = tableConfig.pageSize;
            }
            
            if(tableConfig?.ordenation?.sortColumn) {
                setSortColumn(tableConfig.ordenation.sortColumn);
                sortColumn = tableConfig.ordenation.sortColumn;
            }

            if(tableConfig?.ordenation?.sortDirection) {
                setSortDirection(tableConfig.ordenation.sortDirection);
                sortDirection = tableConfig.ordenation.sortDirection;
            }
        }
        getUsers(page, pageSize, sortColumn, sortDirection, null, filters);
    }, []);
    
    return (
        <Fragment>
            <Breadcrumb title="Usuários" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <span className="d-flex">
                                    <h5>Listagem de Usuários</h5>
                                    <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                </span>  
                                <div className="flex space-between">
                                    <DynamicFilter 
                                        handleSearch={getUsers}
                                        filters={tableFilters}
                                        localStorageName={localStorageFilterKey}
                                        handleReset={handleFilterReset}
                                        handleFilterChange={setTableFilters}
                                        saveFilterState={saveFilterConfig}
                                    />
                                </div>
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhum usuário encontrado</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                            <div className="loader-box loading" style={{minHeight: '50vh'}}>
                                <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>
                            }

                            {!loadingTable && data.length > 0 &&
                                <div className="card-body datatable-react">
                                    <div className="card-body datatable-react">
                                        <Datatable
                                            multiSelectOption={false}
                                            myData={data}
                                            loading={loadingTable}
                                            firstLoad={firstLoad}
                                            pageSize={pageSize}
                                            pagination={true}
                                            class="-striped -highlight"
                                            columns={columns}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(recordsFiltered/pageSize)}
                                            setPageSize={setPageSize}
                                            onPageChange={getUsers}
                                            selectedData={null}
                                            manual={true}
                                            sortColumn={sortColumn}
                                            sortColumnDirection={sortDirection}
                                            changeStatus={changeActiveStatus}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <MakePaymentModal isOpen={makePaymentIsOpen} toggle={ async (event, success = null, message) => {
                setMakePaymentModalIsOpen(false);           
                if(success !== null) {
                    if(success === true){
                        toast.success(`Pagamento para ${makePaymentUserName} registrado com sucesso!`);                        
                        await getUsers(currentPage, pageSize, sortColumn, sortDirection, null, tableFilters);
                    }
                    else{
                        toast.error(message ? message : "Não foi possível completar sua solicitação, por favor tente novamente mais tarde!");
                    }
                }
            }} userId={makePaymentUserId} receiver={makePaymentUserName} walletTotalAvailable={makePaymentWalletTotalAvailable} />

            <UserInvitationsModal 
                isOpen={showInviteDetailsIsOpen}
                toggle={() => setshowInviteDetailsIsOpen(false)}
                userName={inviteDetailsUserName}
                invitations={inviteDetailsUserInvites}
            />

            <ToastContainer />
        </Fragment>
    );
};

export default UsersList;