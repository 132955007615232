import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
const UsersGroupMembersModal = ({toggle, isOpen, usergroupName, users}) => {

    React.useEffect(() => {
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '85%' }}>
            <ModalHeader toggle={toggle}><span className="color-primary">{usergroupName}</span></ModalHeader>
            <ModalBody>
                <ul className='users-groups-form-userslist'>
                    {users?.map((user) => (
                        <li key={user.id}>
                            <b>{user.name}</b> <br/>
                            E-mail: {user.username} <br/>
                        </li>
                    ))}
                </ul>
            </ModalBody>
        </Modal>
    );
}

export default UsersGroupMembersModal;