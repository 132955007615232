import React from 'react';
import { Download } from 'react-feather';

const DownloadButton = ({link}) => {
    return (<>
            <button onClick={() => window.open(link)} className="btn btn-pill btn-outline-secondary btn-icon" type="button" style={{padding: '5px 12px', fontSize: '1em'}}>
                <Download style={{marginRight: '6px'}} />
                Baixar relatório de erros</button> 
    </>);
};

export default DownloadButton;