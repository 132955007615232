import React from 'react';

export const LoadingContext = React.createContext();

export const UserContext = ({ children }) => {
    const [showLoadingBar, setShowLoadingBar] = React.useState(true);

    const showGlobalLoadingBar = () => setShowLoadingBar(true);
    const hideGlobalLoadingBar = () => setShowLoadingBar(false);
    
    return (
        <LoadingContext.Provider
            value={{showLoadingBar, showGlobalLoadingBar, hideGlobalLoadingBar}}
        >
            {children}
        </LoadingContext.Provider>
    )
}