import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import { Link } from 'react-router-dom';

const Error401 = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <div className="container"><img className="img-100" src={sad} alt="sad" />
            <div className="error-heading">
              <h2 className="headline font-info">401</h2>
            </div>
              <p className="sub-content">Ops! A página que você tentou acessar possui acesso restrito.</p>
            <div><Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-info-gradien">VOLTAR PARA PÁGINA INICIAL</Link></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Error401;