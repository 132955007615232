import { states } from "../../../utils/helper";

export const pdvFilters = [
  {
      name: 'name',
      value: '',
      title: 'Nome'
  }, 
  {
      name: 'address',
      value: '',
      title: 'Endereço'
  }, 
  {
      name: 'city',
      value: '',
      title: 'Cidade'
  }, 
  {
      name: 'region',
      value: '',
      title: 'Região'
  }, 
  {
      name: 'state',
      value: -1,
      title: 'Estado',
      type: "select",
      options: states
  }
]
