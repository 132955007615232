import React from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "../components/common/modals/modals.js";
import { LoadingContext } from "../context/UserContext.js";

export const BASE_URL = window.location.host.indexOf('localhost') !== -1 ? 'https://localhost:59143' : window.location.host;

export const GOOGLE_MAPS_LINK = "https://www.google.com/maps/search/?api=1&query=";

export const useService = () => {

  let history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { showGlobalLoadingBar, hideGlobalLoadingBar } = React.useContext(LoadingContext);

  const service = window.location.host.indexOf('localhost') !== -1 ? axios.create({
    baseURL: BASE_URL
  }) : axios.create({});

  service.interceptors.request.use(async config => {
    showGlobalLoadingBar();

    var token = await getAccessTokenSilently();
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  service.interceptors.response.use((response) => {
    hideGlobalLoadingBar();
    if (response.status !== 200) {
        alert('Um erro ocorreu');
        return Promise.reject(response);
    }
    return response;
  }, function (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      return history.replace(`/401`)
    } 
    else {
      console.log('error', error);
      Alert("Um erro ocorreu!", "", "error");
    }

    return Promise.reject(error);
  });

  return service;
};
