import React from 'react';

const GondolasImageTable = ({ answer }) => {

    const styles = {
        evaluationRD: {
            fontSize: '13px'
        }
    }

    return (
        <table className="visit-details-evaluation table-images">
            <thead className="visit-details-evaluation-table-thead">
                <tr>
                    <td style={{ maxWidth: '55px' }}><div>IMG</div></td>
                    <td className="td-brand-name"><div>Marca</div></td>
                    <td><div>Qntd</div></td>
                    <td><div>Descrição do Produto</div></td>
                    <td><div>EAN do Produto</div></td>
                    <td><div>Valor</div></td>
                </tr>
            </thead>

            <tbody>
                {answer.map((evaluation, i) => (
                    <tr key={evaluation.imageId + i} style={styles.evaluationRD}>
                        <td><div>{evaluation.imageOrder}</div></td>
                        <td className="td-brand-name"><div>{evaluation.brand}</div></td>
                        <td><div>{evaluation.count}</div></td>
                        <td><div>{evaluation.productDescription != null ? evaluation.productDescription !== '' ? evaluation.productDescription : '-' : '-'}</div></td>
                        <td><div>{evaluation.productEAN != null ? evaluation.productEAN !== '' ? evaluation.productEAN : '-' : '-'}</div></td>
                        <td>R${evaluation.value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
export default GondolasImageTable;