import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { simulationTable as columns } from "../../../../utils/tablesHelper";
import Datatable from "../../../../components/tables/table";

// toggle
const SimulateRoute = ({toggle, isOpen, loading, data,setData, confirmedPDVs, setConfirmedPDVs, confirmCombo, confirmDataSelected }) => {


    const [pageCurrent, setCurrentPage] = React.useState(0);

    const isSelected = key => {
 
        return confirmedPDVs.find(item => item.id === key) !== undefined;
     };

     const handleTableCheckChange = (list, selecteds, setSelectedData) => {
        var pageSize = confirmedPDVs.length;


        // var selecteds = list?.slice(pageCurrent * pageSize, (pageCurrent * pageSize) + pageSize).filter((item) => isSelected(selecteds, item.id));
        var selecteds = list?.filter((item) => isSelected(item.id));

        if(selecteds.length === list.length){
            setConfirmedPDVs([]);
        }else{
            let newList = [...list];
        
            var tempList = []

            newList.forEach((item) => {
                if(!isSelected(selecteds, item.id))
                tempList.push(item);
            });

            setSelectedData([...tempList]);
        }
    }

    const orderList = async (currentPage = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, list, setList) => {

        var orderedList = list;


        if(orderDirection == 1)
            orderedList = orderedList.sort((a,b) => b[sortColumn] - a[sortColumn])
        else
            orderedList = orderedList.sort((a,b) => b[sortColumn] + a[sortColumn])

            // orderedList = orderedList.reverse()

        

        setList([...orderedList])

    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={()=> {
                toggle();
            }} className="modal-body center-wbk" centered={true} style={{ maxWidth: '90%' }}>
                <ModalHeader toggle={toggle}>Simulação de Criação de Combos</ModalHeader>
                <ModalBody style={{ pointerEvents: loading ? 'none' : undefined, textAlign: 'center' }}>

                    <div className="">
                        <span className="color-gray" style={{fontSize: '1.2em'}}>{data.length}</span>
                        <span className="color-primary" style={{marginLeft: '5px', fontSize: '1.2em'}}>Combos</span>
                    </div>       

                    <div className="combos-table-container center-wbk">
                        
                        <div className="table-item">
                            <p>Selecione os combos que devem ser gerados: </p>
                            <br/>
                            <div className="datatable-react" style={{width: 'fit-content'}}>
                                <Datatable
                                    multiSelectOption={true}
                                    myData={data}
                                    loading={false}
                                    setCurrentPage={setCurrentPage} 
                                    pageCurrent={pageCurrent}
                                    pageSize={data.length}
                                    pagination={false}
                                    class="-striped -highlight"
                                    columns={columns}
                                    // totalPages={Math.ceil(data.length/10)}
                                    // setPageSize={() => {}}
                                    selectedData={confirmedPDVs}
                                    // handleCustomOnRemoveItem={true}
                                    manual={true}
                                    setSelectedData={setConfirmedPDVs}
                                    removeOption={false}
                                    checkboxDelete={false}    
                                    handleTableCheckChange={() => {
                                        handleTableCheckChange(data, confirmedPDVs, setConfirmedPDVs)
                                    }}
                                    onCheckItemCustom={true}
                                    // onRemoveItem={(row) => {

                                    //     let list = [...confirmedPDVs];
                                    //     list.push(row);
                                    //     setConfirmedPDVs([...list]);

                                    //     let newList = data.filter((item) => item.id !== row.id)
                                    //     setData([...newList])
                                    // }

                                    // }
                                    onCheckItem={(selectedItem, newItem) => {
                                        if(selectedItem){
                                            let list = confirmedPDVs.filter((item) => item.id !== newItem.id);
                                            setConfirmedPDVs([...list]);

                                            // let tempList = [...data];
                                            // tempList.push(newItem)
                                            // setData([...tempList])
                                            
                                        }else{
                                            let list = [...confirmedPDVs];
                                            list.push(newItem);
                                            setConfirmedPDVs([...list]);
        
                                            // let newList = data.filter((item) => item.id !== newItem.id)
                                            // setData([...newList])
                                        }
                                    }}
                                    onPageChange={(page, pageSize, orderColumn, orderColumnFilter) => orderList(page, pageSize, orderColumn, orderColumnFilter, data, setData)}

                                />
                            </div>
                        </div>

                        <div className="table-center">
                            <div className="arrow-right"></div>
                        </div>

                        <div className="table-item">
                            <div className="datatable-react" style={{width: 'fit-content'}}>
                                <h2>Combos a serem gerados</h2> 
                                <Datatable
                                    myData={confirmedPDVs}
                                    showToast={false}
                                    loading={loading}
                                    firstLoad={true}
                                    // pageSize={confirmedPDVs.length}
                                    pagination={false}
                                    class="-striped -highlight"
                                    columns={columns}
                                    // totalPages={Math.ceil(confirmedPDVs.length/10)}
                                    listOf="PDV"
                                    handleTableCheckChange={() => {
                                        handleTableCheckChange(confirmedPDVs, [], setConfirmedPDVs)
                                    }}
                                    setSelectedData={setConfirmedPDVs}
                                    onRemoveItem={(row) => {
                                        var list = confirmedPDVs.filter((item) => item.id !== row.id);
                                        setConfirmedPDVs(list);

                                        var originalList = [...data];
                                        originalList.push(row)
                                        // setData([...originalList]);
                                        // if(showToast)
                                            // toast.success("PDV removido");
                                    }

                                    }
                                    handleCustomOnRemoveItem={true}
                                    manual={false}
                                    removeOption={true}
                                />
                            </div>
                        </div>
                        
                    </div>       

                </ModalBody>

                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }

                <ModalFooter className="modal-footer">
                    <div className="centralize">
                        <button disabled={loading} className="btn btn-pill btn-outline-secondary " type="button" onClick={confirmCombo}>Confirmar</button>
                    </div>
                </ModalFooter>
            </Modal>
            
        </>
    );
}

export default SimulateRoute;