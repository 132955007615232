import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useParams } from 'react-router-dom';
import { useService } from "../../hooks/useService";
import { Alert } from "../../components/common/modals/modals.js";

const PowerBiReport = () => {
    const service = useService();
    const params = useParams();
    const [lastUpdate, setLastUpdate] = React.useState();

    const [powerbiReportConfig, setpowerbiReportConfig] = React.useState({
        type: 'report',
        id: undefined, 
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        pageName: params.pageName,
        accessToken: undefined, // Keep as empty string, null or undefined
        settings: {	
          panes: {
            filters: {
              expanded: false,
              visible: true
            }
          },
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth
          }
        }
      });

      const getPowerBIAccessToken = async () => {
        let reportId = params.reportId;
        await service.get(`/api/powerbi/embedToken/${reportId}`)
        .then(async response => {
          var embedToken = response?.data?.data;
          if (embedToken === null) {
            return await Alert("", "Falha ao buscar token", "error");
          }
    
          const reportConfig = {
            EmbedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&ctid=7f2f75d8-4e75-4ac6-946f-a62ac439c07f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1iLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`,
            EmbedToken: embedToken
          }
    
          setpowerbiReportConfig({
            ...powerbiReportConfig,
            embedUrl: reportConfig.EmbedUrl,
            accessToken: reportConfig.EmbedToken,
            filters: [],
          });
        })
      };

      React.useEffect(() => {
        getPowerBIAccessToken();
      }, [])

    return (
      <div>
        <PowerBIEmbed
          embedConfig = { powerbiReportConfig }
          eventHandlers = {
            new Map([
              ['error', function (event) { 
                if (event) {
                  console.error(event.detail);
                }
              }]
            ])
          }
          cssClassName = { "report-style-class" }
          getEmbeddedComponent = { async (embedObject) => {} }
        />
      </div>
    )
}

export default PowerBiReport;