import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import DownloadButton from '../buttons/download';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DoughnutReport from './doughnutReport';
import 'react-dropzone-uploader/dist/styles.css';

const UploadModal = ({value, isOpen, toggle, sendImport, error, loading, data, init, setInit}) => {
    const [excel, setExcel] = React.useState();

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }) => {
        setExcel(file);
    }

    const handleSend = () => {
        sendImport(excel);
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '90%' }}>
            <ModalHeader toggle={toggle}>Importar {value}</ModalHeader>
            <ModalBody style={{ pointerEvents: loading ? 'none' : undefined, textAlign: 'center' }}>
            
                <a href={'/'+value + '.xlsx'} style={{float: 'left'}}>Baixar modelo Excel</a>
                {init && <div className="dz-message needsclick">
                        <Dropzone
                        accept={".xlsx, .csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"}
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent="Arraste um arquivo ou clique para adicionar"
                            styles={{
                                dropzone: { color: '#999999', height: '200px', border: '3px dashed #999999 !important', backgroundColor: 'white',
                                margin: '30px 0px' },
                                dropzoneActive: { borderColor: 'green' },
                            }}
                        />
                    </div>
                }

                {!init && <div className="card-body" style={{textAlign: 'center'}}>                                    
                    <DoughnutReport data={data} />
                    { data !== undefined && data.errorsCount > 0 && <DownloadButton link={data.logFileUrl} /> }
                </div>}

                <p style={{ color: '#F44336', paddingLeft: '10px'}}>{error}</p>              
                </ModalBody>
                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }
            {init && <ModalFooter className="modal-footer">
                <button disabled={loading} className="btn btn-pill btn-danger" type="button" onClick={toggle}>Cancelar</button>
                <button disabled={loading} className="btn btn-pill btn-primary btn-air-primary" type="button" onClick={handleSend}>Importar</button>
            </ModalFooter>}
        </Modal>
    );
}

export default UploadModal;