import React from 'react';
import { Route } from 'react-router-dom';
import Error404 from '../pages/errors/error404';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getPagePermission, hasFullAccess } from '../utils/helper';
import Loader from '../components/common/loader';

const PrivateRoute = ({ component, path, ...args }) => {
  let permission = getPagePermission(args.location.pathname);
  if(!args.location.pathname !== '/' && !hasFullAccess() && !permission) {
    if (args.location.pathname !==  401 && permission !== undefined)
      return <Error404 />
  }

  return (
    <Route component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loader />
      })}
      {...args}
    />
  );
};

export default PrivateRoute;