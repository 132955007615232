import React, { Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Label, Input } from 'reactstrap';
import { Trash } from 'react-feather';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch } from '../common/switch/switch.js';

export const Datatable = (props) => {

    const [sortData, setSortData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    function removeItem(row) {

        if (handleCustomOnRemoveItem) {
            onRemoveItem(row);
        } else {
            var list = myData?.filter((item) => item.id !== row.id);
            setSelectedData(list);
            if (showToast)
                toast.success(listOf + " removido");
        }

    }

    const { pageSize, myClass, multiSelectOption, pagination, myData, loading, firstLoad, totalPages, changeStatus, setPageSize, onPageChange, selectedData, setSelectedData, removeOption, setCurrentPage, checkboxDelete, manual, listOf, sortable = true, showToast = true, handleTableCheckChange, onCheckItemCustom, onCheckItem, handleCustomOnRemoveItem, onRemoveItem, currentPage = 0, sortColumn, sortColumnDirection, MultiSelectAction1 } = props;
    const columns = [];

    const isSelected = key => {
        if (selectedData === undefined)
            return false;

        return selectedData.find(item => item.id === key) !== undefined;
    };

    if (multiSelectOption === true) {

        columns.push(
            {
                Header:
                    checkboxDelete === true ?
                        <span className="remove-item-table">
                            <Trash />
                        </span>
                        :
                        (<Label check className="custom-checkbox pos-relative">
                            <Input
                                type="checkbox" className="custom-checkbox"
                                checked={manual === true ? myData?.filter((item) => isSelected(item.id)).length === pageSize : myData?.slice(props.pageCurrent * pageSize, (props.pageCurrent * pageSize) + pageSize).filter((item) => isSelected(item.id)).length === pageSize}
                                onChange={() => handleTableCheckChange()} />
                        </Label>)
                ,
                id: 'selection',
                width: 48,
                Cell: (row) => {
                    return (
                        <Label check className="custom-checkbox pos-relative">
                            <Input type="checkbox" className="custom-checkbox" style={{ marginLeft: '6px' }} checked={isSelected(row.original.id)} onChange={() => {
                                var selectedItem = isSelected(row.original.id);

                                if (onCheckItemCustom) {
                                    onCheckItem(selectedItem, row.original)
                                } else {
                                    if (selectedItem) {
                                        let list = selectedData.filter((item) => item.id !== row.original.id);
                                        setSelectedData([...list]);
                                    } else {
                                        let list = [...selectedData];
                                        list.push(row.original);
                                        setSelectedData([...list]);
                                    }
                                }
                            }} />
                        </Label>
                    )
                },
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )
    }

    // add toggle
    if (changeStatus) {
        columns.push(
            {
                Header: <b>Ativo</b>,
                id: 'isActive',
                width: 70,
                Cell: (row, index) => (
                    <div style={{ height: '100%', verticalAlign: 'text-top', display: 'inline-grid' }}>
                        <Switch isOn={row.original.isActive}
                            id={row.original.id}
                            handleToggle={() => { changeStatus(row.original, row.index) }} />
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true
            }
        )
    }

    props.columns.map((header) => {
        if (header && !header.Header && header.columns) {

            header.columns.map((column) => {
                columns.push({
                    Header:
                        (<div className="table-header table-tooltip">
                            <div className="table-header-title">{column.Header}</div>
                            {column.hint && <div className="table-tooltip-text">{column.hint}</div>}
                        </div>),

                    // (<div className={props.twoLinesHeader == true ? 'table-header-2lines table-header table-tooltip' : 'table-header'}>
                    //     {column.Header}
                    //     <span class="table-tooltip-text">Tooltip text</span>
                    // </div>),
                    accessor: column.accessor,
                    width: column.width,
                    style: {
                        textAlign: "left",
                        paddingLeft: "10px !important"
                    },
                });
            });
        }
    });

    if (removeOption === true)
        columns.push(
            {
                id: 'remove',
                width: 50,
                canSort: false,
                Cell: (row) =>
                (
                    <div className="remove-item-table" onClick={() => removeItem(row.original)}>
                        <Trash />
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )

    React.useEffect(() => {
        if (sortColumn != null) {
            setSortData([
                {
                    id: sortColumn.charAt(0).toLowerCase() + sortColumn.slice(1),
                    desc: sortColumnDirection
                }
            ])
        }
    }, [])

    return (
        <Fragment>
            {loading && myData.length === 0 ?
                <div className="loader-box loading">
                    <div className="loader">
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                    </div>
                </div>
                :
                <Fragment>

                    {multiSelectOption === true && <div className={`selection-bar ${selectedData?.length > 0 ? 'active' : ''}`}>
                        <div className="selected-count">{selectedData?.length} {selectedData?.length === 1 ? 'item selecionado' : 'itens selecionados'}</div>

                        {multiSelectOption && MultiSelectAction1 && <MultiSelectAction1 />}
                    </div>}

                    <ReactTable
                        data={myData}
                        columns={columns}
                        className={myClass}
                        showPagination={pagination}
                        previousText="Anterior"
                        rowsText="linhas"
                        ofText="de"
                        pageText="Página"
                        nextText="Próximo"
                        defaultPageSize={pageSize}
                        loading={loading}
                        pages={totalPages}
                        defaultSorted={sortData}
                        page={currentPage}
                        onPageSizeChange={async (pageSize, page) => {
                            if (pageSize !== undefined)
                                setPageSize(pageSize);
                        }}
                        onPageChange={(page) => {
                            if (setCurrentPage !== undefined)
                                setCurrentPage(page)
                        }}
                        sortable={sortable}
                        manual={manual}
                        pagination={pagination}
                        onFetchData={(state, instance) => {
                            if (firstLoad === true || loading === true || isLoading === true) {
                                setIsLoading(false)
                                return;
                            }

                            let orderColumnFilter = sortColumn;
                            let orderColumnDirectionFilter = sortColumnDirection ?? 1;

                            if (state.sorted.length > 0) {
                                orderColumnFilter = state.sorted[0].id.charAt(0).toUpperCase() + state.sorted[0].id.slice(1);
                                orderColumnDirectionFilter = state.sorted[0].desc ? 1 : 0;
                            }

                            if (setCurrentPage !== undefined) {
                                setCurrentPage(state.page);
                            }

                            if (onPageChange !== undefined && (state.page !== currentPage || state.pageSize !== pageSize || orderColumnDirectionFilter !== sortColumnDirection || orderColumnFilter !== sortColumn)) {
                                onPageChange(state.page, state.pageSize, orderColumnFilter, orderColumnDirectionFilter);
                            }
                        }}
                    />

                </Fragment>
            }


        </Fragment>
    );
}

export default Datatable;

export const isSelected = (items, key) => {
    if (items === undefined)
        return false;

    return items.find(item => item.id === key) !== undefined;
};