import React from 'react';
import axios from "axios";
import { Alert } from "../../../components/common/modals/modals";
import { useAuth0 } from "@auth0/auth0-react";
import Datatable, { isSelected } from "../../../components/tables/table";
import ApproveModal from './approveModal';
import { useService } from "../../../hooks/useService";
import DownloadVisitsPDFButton from './pdf/DownloadVisitsPDFButton.js';
import { getVisitsReportBaseURL } from '../../../config'
import { LoadingContext } from "../../../context/UserContext.js";
import { hasFullAccess } from "../../../utils/helper";

// import { renderToStaticMarkup } from 'react-dom/server';
// import VisitsReportTemplate from './pdf/components/VisitsReportTemplate';

import "react-table/react-table.css";

function leftZero(num) {
    return num < 10 ? "0" + num : num.toString();
}
function formatTime(date) {
    return `<${leftZero(date.getDate())}-${leftZero(date.getMonth() + 1)}-${date.getFullYear()} ${leftZero(date.getHours())}h:${leftZero(date.getMinutes())}m:${leftZero(date.getSeconds())}s>`;
}

export const approveTooltip = (approval) => {
    return (<div className="hasTooltip">
        {approval.approved ? <span className="success-color">Aprovado</span> : <span className="error-color">Rejeitado</span>}
        <div className="tooltip-pvd-parent">
            <p style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}> <b className="color-primary">Observação: </b>{approval.observation}</p>
        </div>
    </div>);
}

export const VisitsTable = ({ loadingTable, data, setData, pageSize, setPageSize, tableColumns, setCurrentPage,
    currentPage, recordsFiltered, getVisits, canManage, sortColumn, sortDirection, toast, approveModalIsOpen, setApproveModalIsOpen, approveModalVisitId, selectedVisits, setSelectedVisits, multiSelectOption }) => {

    const [firstLoad, setFirstLoad] = React.useState(true);
    const [loadingExportPDF, setLoadingExportPDF] = React.useState(false);
    const [isUserMaster, setIsUserMaster] = React.useState(false);

    const service = useService();
    const { getAccessTokenSilently, user } = useAuth0();
    const { showGlobalLoadingBar, hideGlobalLoadingBar } = React.useContext(LoadingContext);


    const pdfService = axios.create({
        baseURL: getVisitsReportBaseURL()
    });

    const getVisitDetails = async () => {
        var visitIds = selectedVisits.map(v => v.id);
        // visitIds = ['f54d655a-4f7b-497e-b73d-013f70257d39'];
        // visitIds = ['1e2b849b-ff17-4c70-9477-0004086b8a77'];

        if (visitIds.length === 0) return;
        //history.push(`/api/pdf?ids=${visitIds}`)
        showGlobalLoadingBar();
        const exportTime = formatTime(new Date());

        let payload = {
            visitIds: visitIds,
            exportTime: exportTime,
            userId: user.sub,
        }
        setLoadingExportPDF(true);

        const pdfURL = `/visits/report/pdf`;
        var token = await getAccessTokenSilently();

        if (visitIds.length <= 5) {
            await pdfService.post(pdfURL, payload, { headers: { 'authorization': `Bearer ${token}` }, responseType: 'blob' })
                .then((response) => {
                    if (!response.data)
                        return;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `visitas${exportTime}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((e) => {
                    console.log('e', e)
                    Alert('Ops, não foi possível fazer o download das visitas em PDF. Por favor tente novamente mais tarde.');
                })
                .finally(() => {
                    setLoadingExportPDF(false);
                    hideGlobalLoadingBar();
                });
        } else {
            await pdfService.post(pdfURL, payload, { headers: { 'authorization': `Bearer ${token}` } })
                .then((response) => {
                    Alert('Em alguns minutos o relatório ficará disponível na aba Visitas exportadas,por favor aguarde!');
                })
                .catch((e) => {
                    console.log('e', e)
                    Alert('Ops, não foi possível fazer o download das visitas em PDF. Por favor tente novamente mais tarde.');
                })
                .finally(() => {
                    setLoadingExportPDF(false);
                    hideGlobalLoadingBar();
                });
        }




    }

    React.useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            let isMaster = hasFullAccess();
            setIsUserMaster(isMaster);
        }
    }, [])

    React.useEffect(() => {
    }, [selectedVisits])

    const handleSetSelectedData = (newList) => {
        console.log('isUserMaster', isUserMaster)
        if (isUserMaster === false && newList.length > 10) {
            toast.error('Você pode exportar para PDF até 10 visitas de uma vez.')
            return;
        }
        setSelectedVisits([...newList]);
    }

    const changeActiveStatus = async (visit, index) => {
        await service.post(`/api/visit/active/${visit.id}/${!visit.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];

                    let item = items.find((it) => it.id === visit.id);
                    visit.active = !visit.active;
                    visit.isActive = !visit.isActive;
                    items[index] = item;
                    setData(items);

                    let change = !visit.isActive ? "inativada" : "ativada";
                    toast.success("Visita " + change + " com sucesso!");
                } else {
                    let change = !visit.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " a visita");
                }
            })
    }

    const handleTableCheckChange = (list, selecteds, setSelectedData) => {

        var currentList = list;
        var selecteds = currentList.filter((item) => isSelected(selecteds, item.id));

        if (selecteds.length === pageSize) {
            let filteredList = [...selectedVisits];

            selecteds.forEach((item) => {

                const index = filteredList.indexOf(item);

                if (index !== undefined) {
                    filteredList.splice(index, 1);
                }
            });

            if (isUserMaster === false && filteredList.length > 10) {
                console.log('isUserMaster', isUserMaster)
                toast.error('Você pode exportar para PDF até 10 visitas de uma vez.')
                return;
            }
            setSelectedData(filteredList);
        }
        else {
            var tempList = [...selectedVisits]

            currentList.forEach((item) => {
                if (!isSelected(selecteds, item.id))
                    tempList.push(item);
            });

            if (isUserMaster === false && tempList.length > 10) {
                console.log('isUserMaster', isUserMaster)
                toast.error('Você pode exportar para PDF até 10 visitas de uma vez.')
                return;
            }
            setSelectedData([...tempList]);
        }
    }

    return (
        <>
            {loadingTable && <div className="loader-box loading" style={{ minHeight: '50vh' }}>
                <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                </div>
            </div>
            }

            {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
                <Datatable
                    multiSelectOption={multiSelectOption}
                    myData={data}
                    loading={loadingTable}
                    firstLoad={firstLoad}
                    pageCurrent={currentPage}
                    pageSize={pageSize}
                    pagination={true}
                    class="-striped -highlight"
                    columns={tableColumns}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    totalPages={Math.ceil(recordsFiltered / pageSize)}
                    setPageSize={setPageSize}
                    onPageChange={getVisits}
                    selectedData={selectedVisits}
                    changeStatus={canManage ? changeActiveStatus : undefined}
                    manual={true}
                    sortColumn={sortColumn}
                    setSelectedData={(newList) => handleSetSelectedData(newList)}
                    sortColumnDirection={sortDirection}
                    handleTableCheckChange={() => handleTableCheckChange(data, selectedVisits, setSelectedVisits)}
                    MultiSelectAction1={() => <DownloadVisitsPDFButton exportPDF={getVisitDetails} loadingExportPDF={loadingExportPDF} />}
                    checkboxDelete={false}
                />
            </div>}

            <ApproveModal isOpen={approveModalIsOpen} toggle={async (event, success = null) => {
                setApproveModalIsOpen(false);

                if (success !== null) {
                    if (success === true) {
                        toast.success("Status de aprovação da visita alterado com sucesso!");
                        await getVisits(currentPage, pageSize);
                    }
                    else {
                        toast.error("Não foi possível completar sua solicitação, por favor tente novamente mais tarde!");
                    }
                }
            }} visitId={approveModalVisitId} />
        </>
    );
}
