import React, { Fragment } from "react";
import { User, ThumbsUp, ThumbsDown, Mail, Phone } from 'react-feather';
import authorizedIcon from '../../assets/images/ic_authorized.png';
import { getAiStatus, getAiClass } from "../../utils/validations";
import { Switch } from '../../components/common/switch/switch.js';
import GondolasImageTable from "./components/gondolaImagesTable";
import AuthorizePDVModal from '../pdv/components/authorizeModal.js';
import GoogleApiWrapper from '../../components/common/pointer-locations-map/map-filter';
import { Alert } from "../../components/common/modals/modals";
import { Edit3 as Edit } from 'react-feather';
import Breadcrumb from '../../components/common/breadcrumb';
import { getPercentColor } from "../../utils/validations";
import { ToastContainer, toast } from "react-toastify";
import Input from '../../components/common/form/input';
import ApproveModal from "./components/approveModal";
import { hasFullAccess, getMoneyFormat } from "../../utils/helper";
import { useService } from "../../hooks/useService";
import { useAuth0 } from "@auth0/auth0-react";
import { getAIAnalysisBlobUrl } from "../../config.js";
import { useParams } from 'react-router-dom';
import PhotoViewer from '../../components/photo-viewer';
import { Chart } from "react-google-charts";
import CountUp from 'react-countup';
import axios from "axios";
import ProgressiveImage from "../../components/common/progressiveImage";
import { getAnswerType, getImagePath, getImageRateBackground, getImageRate, getAnswerValue } from './components/visitsHelper';
import { SURVEY_ANSWERS_TYPE } from "../../utils/enums";
import './visit.css';

const VisitDetails = ({ history }) => {
    const { user } = useAuth0();
    const service = useService();
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [chart, setChart] = React.useState([]);
    const [canManage, setcanManage] = React.useState(false);
    const [showEditButton, setShowEditButton] = React.useState(false);

    // onclick image
    const [imagesViewBox, setImagesViewBox] = React.useState([]);
    const [photoView, setPhotoView] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState();
    const [editedAnswers, setEditedAnswers] = React.useState([]);

    const open = (index) => {
        setModalVisible(true)
        setActiveIndex(index || 0)
    }

    const params = useParams();

    // Approve modal state
    const [approveModalIsOpen, setApproveModalIsOpen] = React.useState(false);
    const [approveModalVisitId, setApproveModalVisitId] = React.useState(false);

    // authorize pdv modal state
    const [showAuthorizeModal, setShowAuthorizeModal] = React.useState(false);
    const [approveModalPDVId, setApproveModalPDVId] = React.useState(false);

    const convertDate = (date) => {
        if (date === 'InvalidDate' || date === null)
            return "-";
        else
            return new Date(date).toLocaleDateString('pt-BR')
    }

    const getBrand = (productName) => {
        let brands = productName?.split("|");
        let brandsJoined = "";

        if (brands?.length > 0) {
            brands.forEach((brand, i) => {

                if (i < brands.length - 1 && brand !== "") {
                    brandsJoined += brand;
                    // if(i < brands.length -1) {
                    //     brandsJoined += brand;
                    if (i < brands.length - 2)
                        brandsJoined += ", ";
                    // }

                }
            });
        }
        return brandsJoined;
    }

    const setTextAnswerValue = (answerId, newValue) => {
        let visitPayload = { ...data };
        let answer = visitPayload?.visit?.answers.filter(a => a.id === answerId);
        if (answer.length > 0) {
            answer[0].answer = newValue;
        }
        setData({ ...visitPayload });
        if (!showEditButton)
            setShowEditButton(true);

        if (!editedAnswers.includes(answerId)) {
            setEditedAnswers([...editedAnswers, answerId]);
        }
    }

    const setDecimalAnswerValue = (answerId, newValue) => {
        let visitPayload = { ...data };
        let answer = visitPayload?.visit?.answers.filter(a => a.id === answerId);
        if (answer.length > 0) {
            answer[0].answerDecimal = newValue;
        }
        setData({ ...visitPayload });
        if (!showEditButton)
            setShowEditButton(true);

        if (!editedAnswers.includes(answerId)) {
            setEditedAnswers([...editedAnswers, answerId]);
        }
    }

    const setBoolAnswerValue = (answerId, newValue) => {
        let visitPayload = { ...data };
        let answer = visitPayload?.visit?.answers.filter(a => a.id === answerId);
        if (answer.length > 0) {
            switch (newValue) {
                case 'true':
                    answer[0].answerBool = true;
                    break;
                case 'false':
                    answer[0].answerBool = false;
                    break;
                default:
                    answer[0].answerBool = null;
                    break;
            }
        }

        setData({ ...visitPayload });
        if (!showEditButton)
            setShowEditButton(true);

        if (!editedAnswers.includes(answerId)) {
            setEditedAnswers([...editedAnswers, answerId]);
        }
    }

    const saveVisitsEdit = async () => {
        let payload = {
            visitId: data.visit.id,
            answers: data.visit.answers.map(a => ({
                answerId: a.id,
                answerSimpleValue: a.answer,
                boolAnswerValue: a.answerBool,
                decimalAnswerValue: a.answerDecimal
            }))
        };

        await service.post(`/api/visit/edit`, payload)
            .then(response => {
                const success = response?.data?.status === 200;

                if (success) {
                    Alert("Visita editada com sucesso!", "", "success");
                    setEditedAnswers([]);
                    setShowEditButton(false);
                } else {
                    Alert("Não foi possível editar a visita", "", "error");
                }
            })
            .catch(error => {
                Alert("Não foi possível editar a visita", "", "error");
            })
    }

    const convertObjectToLowerCase = (obj) => {
        let lowerCaseObj = { ...obj }
        Object.keys(lowerCaseObj).forEach(key => {
            const value = lowerCaseObj[key];
            delete lowerCaseObj[key];
            lowerCaseObj[key.toLowerCase()] = value;
        });
        return lowerCaseObj
    }

    const getProductPrices = (priceResults) => {
        let prices = [];
        priceResults.forEach(function (price, index) {
            if (price.box !== null) {
                prices.push({
                    x: price.box?.Xmin,
                    y: price.box?.Ymin,
                    w: price.box?.Xmax - price.box?.Xmin,
                    h: price.box?.Ymax - price.box?.Ymin,
                    i: index + 1,
                    boxScore: price.box?.Score * 100,
                    order: index,
                    itemsIds: price.ItemsIds ?? [],
                    amount: price.amount,
                    text: price.text,
                    id: 'price-item-' + index + 1
                });
            }
        })
        return prices;
    }

    const getProductBox = (boxes) => {
        var rects = boxes?.map((b, i) => {
            let box = convertObjectToLowerCase(b.box || b.Box)
            return {
                x: box.xmin,
                y: box.ymin,
                w: box.xmax - box.xmin,
                h: box.ymax - box.ymin,
                i: i + 1,
                boxScore: box.score * 100,
                order: i,
                products: convertObjectToLowerCase(b).products?.map(p => {
                    let product = convertObjectToLowerCase(p);
                    return {
                        title: `${product?.name?.split('|')[0]}`,
                        ean: product.name.split('|').pop(),
                        productScore: product.score * 100,
                        name: getBrand(product.name)
                    }
                }),
                itemId: b.ItemId || b.itemId // parameter used to connect with price box
            }
        })
        return rects;
    }

    const getImageBoxObject = (id, src, boxes, [], prices = []) => ({
        id: id,
        src: src,
        boxes: boxes,
        prices: prices
    })

    const getDetail = async (id) => {
        let config = {
            headers: {
                userId: user.sub,
            }
        }

        await service.get(`/api/visit/Detail/${id}`, config)
            .then(async response => {

                if (response.data.status === 56)
                    return history.replace(`/404`)
                var detail = response.data.data;

                if (detail === null)
                    return history.replace(`/404`)

                detail.visit.survey.registerDate = new Date(detail?.visit?.survey?.registerDate).toLocaleDateString('pt-BR');

                detail.visit.registerDate = convertDate(detail?.visit?.registerDate);
                detail.visit.finishDate = convertDate(detail?.visit?.finishDate);

                var chartItems = [['Marca', 'Quantidade de Produtos'], ...detail?.chart?.map(c => [c.brand, c.findings])]
                setChart(chartItems)
                setData({ ...detail });
                setLoading(false);

                var gondolas = [];

                // configuring images to the gallery view
                (detail?.visit?.answers).forEach(answer => {
                    (answer?.answerGondolas).forEach(gondola => {
                        (gondola?.images).forEach(image => {
                            gondolas.push(getImageBoxObject(image.id, getImagePath(image.imagePath), [], []));
                        });
                    });
                });
                setImagesViewBox(gondolas);

                if (detail?.visit?.aiResultEvaluatedBy === "MMAI") {
                    const imageAnalysis = await axios(`${getAIAnalysisBlobUrl()}${detail?.visit?.aiResultFilePath}`);
                    if (imageAnalysis?.data) {
                        (imageAnalysis?.data?.Answers).forEach(answer => {
                            (answer?.AnswerCameraValue?.Gondolas).forEach(gondola => {
                                (gondola?.Images).forEach(image => {
                                    const gondolaImage = {
                                        id: image.Id,
                                        src: imageAnalysis.data.ImagesBaseURL + image.ImagePath,
                                        boxes: getProductBox(image.YoloResults),
                                        boxesJson: image.YoloResults,
                                        prices: getProductPrices(image.PriceResults),
                                        pricesJson: image.PriceResults,
                                        products: []
                                    }
                                    if (gondolaImage.boxes.length > 0) {
                                        gondolaImage.products = gondolaImage.boxes[0].products
                                    }
                                    const analyzedGondolaIndex = gondolas.findIndex(g => g.id === image.Id);
                                    if (analyzedGondolaIndex !== -1) {
                                        gondolas[analyzedGondolaIndex].boxes = gondolaImage.boxes;
                                        gondolas[analyzedGondolaIndex].prices = gondolaImage.prices;
                                        gondolas[analyzedGondolaIndex].products = gondolaImage.products;
                                        gondolas[analyzedGondolaIndex].boxesJson = gondolaImage.products;
                                        gondolas[analyzedGondolaIndex].pricesJson = gondolaImage.pricesJson;
                                    }
                                })

                            })
                        })
                    }
                }
                setImagesViewBox([...gondolas]);
            })
    }

    const handleChangeVisitStatus = async () => {

        let item = { ...data };
        let visit = data.visit;

        await service.post(`/api/visit/active/${visit.id}/${!visit.isActive}`)
            .then(response => {
                if (response.status === 200) {

                    visit.active = !visit.active;
                    visit.isActive = !visit.isActive;
                    item.visit = visit;
                    setData(item);

                    let change = !visit.isActive ? "inativada" : "ativada";
                    toast.success("Visita " + change + " com sucesso!");
                } else {
                    let change = !visit.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " a visita");
                }
            })
    }

    React.useEffect(() => {
        let id = params.id;
        if (id) {
            loadPermission()
            if (user)
                getDetail(id);
        } else {
            history.push(`/visits`);
        }
    }, [user]);

    const loadPermission = () => {
        let isMaster = hasFullAccess();
        setcanManage(isMaster);
    }

    const renderGondola = (imgs, question) => {
        var itens = imgs?.sort((a, b) => b.order - a.order).reverse();

        return (<div className="flex wrap">
            {itens?.map((img, i) => (
                img.imagePath !== "" && <div className="gondolas-images" key={img.id} onClick={() => {
                    setPhotoView(true);
                }}>
                    <div className="image-count main-color-background">{img.order}</div>
                    <div className="image-gallery image-gallery-gondola" key={i} onClick={() => {
                        let imageIndex = imagesViewBox.findIndex(g => g.id === img.id);
                        let images = [...imagesViewBox];
                        if (imageIndex !== -1) {
                            images[imageIndex].title = question;
                            setImagesViewBox([...images]);
                            open(imageIndex)
                        } else {
                            Alert('Ops, não foi possível carregar a imagem');
                        }
                    }}>
                        <ProgressiveImage src={getImagePath(img.imagePath)} />
                    </div>

                    {img.evaluationStatus !== 0 && <div>
                        <div className={`image-rate ${getImageRateBackground(img.evaluationStatus)}`}>
                            <div>
                                {getImageRate(img.evaluationStatus)}
                            </div>
                        </div>
                    </div>}
                </div>
            ))}
        </div>)
    }

    return (
        <Fragment>
            <Breadcrumb title="Detalhes" parent="Visita" />

            {canManage && <div className="action-header">
                <button className="btn btn-pill btn-outline-secondary btn-icon" type="button" onClick={() => {
                    setApproveModalIsOpen(true);
                    setApproveModalVisitId(data.visit.id);
                }}>
                    <ThumbsUp className="success-color" />&nbsp; &nbsp;Aprovar</button>
            </div>}

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">

                        <section className="container-fluid">
                            <div className="row">

                                <div className="col-sm-6 col-xl-3 col-md-4  no-right no-left">
                                    <div className="card card-centered">
                                        <div className="card-body">
                                            <div className="media chart-university flex">
                                                <div className="media-body">
                                                    <b>Quantidade de Gôndolas/Imagens</b>
                                                    <h2 className="tile-title mb-0 color-primary">
                                                        {data == null ? '...' : (
                                                            <>
                                                                <span>{data.numberOfGondolas}</span>
                                                                <span>/</span>
                                                                <span>{data.numberOfImages}</span>
                                                            </>
                                                        )}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xl-3 col-md-4 no-right no-left">
                                    <div className="card card-centered">
                                        <div className="card-body">
                                            <div className="media chart-university flex">
                                                <div className="media-body">
                                                    <p>
                                                        <b>{data?.visit?.approval?.approved === null && 'Status da '}</b>
                                                        <b>Visita</b>
                                                        <b>{data?.visit?.approval === null && ' não avaliada'}</b>
                                                        <b>{data?.visit?.approval?.approved === true && ' Aprovada'}</b>
                                                        <b>{data?.visit?.approval?.approved === false && ' Reprovada'}</b>
                                                    </p>
                                                    <h2 className="tile-title mb-0">
                                                        {data?.visit?.approval?.approved === null && <p className="waiting-color waiting-text">Aguardando Aprovação</p>}
                                                        {data?.visit?.approval?.approved === true && <ThumbsUp className="success-color" />}
                                                        {data?.visit?.approval?.approved === false && <ThumbsDown className="error-color" />}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xl-3 col-md-4 no-right no-left">
                                    <div className="card card-centered">
                                        <div className="card-body">
                                            <div className="media chart-university flex">
                                                <div className="media-body">
                                                    <b>Avaliação Imagens</b>
                                                    <h2 className={`tile-title mb-0 ${data != null && getPercentColor(data.visit.evaluationPercent)}`}>
                                                        {data == null ? '...' : <span><CountUp className="counter" end={data.visit.evaluationPercent} />%</span>}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xl-3 col-md-4 no-right no-left">
                                    <div className="card card-centered">
                                        <div className="card-body">
                                            <div className="media chart-university flex">
                                                <div className="media-body">
                                                    <b>Status</b>
                                                    <h3 className="tile-title mb-0">
                                                        {loading ? '...' : <p className={getAiClass(data.visit.status)}>{getAiStatus(data.visit.status)}</p>}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <div className="projectmore row">

                            {/* GROUPONE */}
                            <div className="col-xl-7 xl-100">

                                <h3 className="color-primary align-center pt-15">Ponto de Venda</h3>

                                <div className="card pt-15 pb-15 pl-15">
                                    <div className="card-body">

                                        {canManage && <div className="pointer" style={{ textAlign: 'end' }}>
                                            <img onClick={() => {
                                                setApproveModalPDVId(data?.visit?.pointOfSale?.Id);
                                                setShowAuthorizeModal(true);
                                            }}
                                                className={`authorization-image ${data?.visit?.pointOfSale?.authorization == null && 'authorization-image-opacity'}`}
                                                src={authorizedIcon}>
                                            </img>
                                        </div>}

                                        <div className="custom-input">
                                            <label htmlFor="pdvName">Nome</label>
                                            <br />
                                            <input name="pdvName" className="custom-input-item w-100" type="text" defaultValue={data?.visit?.pointOfSale?.name} id="pdvName" disabled={true} />
                                        </div>

                                        {data?.visit?.pointOfSale?.pointOfSaleType &&
                                            (
                                                <div className="custom-input">
                                                    <label>Tipo</label>
                                                    <br />
                                                    <div className="tag-pdv">{loading ? '...' : data.visit.pointOfSale.pointOfSaleType.name}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <h3 className="color-primary align-center pt-15">Localização</h3>

                                <div className="card pt-15 pb-15 pl-15">
                                    <div className="card-body">
                                        <div className="custom-input">
                                            <label htmlFor="pdvAddress">Endereço</label>
                                            <br />
                                            <input name="pdvAddress" className="custom-input-item w-100" type="text" defaultValue={data?.visit?.pointOfSale?.address} id="pdvAddress" disabled={true} />
                                        </div>

                                        <div className="flex">
                                            <div className="flex">
                                                <div className="custom-input" style={{ width: '50%' }}>
                                                    <label htmlFor="pdvCity">Cidade</label>
                                                    <br />
                                                    <input name="pdvCity" className="custom-input-item w-100" type="text" defaultValue={data?.visit?.pointOfSale?.city} id="pdvCity" disabled={true} />
                                                </div>

                                                <div className="custom-input pl-10">
                                                    <label htmlFor="pdvState">Estado</label>
                                                    <br />
                                                    <input name="pdvState" className="custom-input-item w-100" type="text" defaultValue={data?.visit?.pointOfSale?.state} id="pdvState" disabled={true} />
                                                </div>

                                                <div style={{ width: '50%' }} className="custom-input">
                                                    <label htmlFor="pdvRegion">Região</label>
                                                    <br />
                                                    <input name="pdvRegion" className="custom-input-item w-100" type="text" defaultValue={data?.visit?.pointOfSale?.region} id="pdvRegion" disabled={true} />
                                                </div>
                                            </div>

                                        </div>

                                        <br />

                                        {data?.visit?.pointOfSale && (<GoogleApiWrapper setLatitude={() => { }} setLongitude={() => { }} lat={data?.visit?.pointOfSale.latitude} lon={data?.visit?.pointOfSale.longitude} searchedItems={[]}></GoogleApiWrapper>)}

                                    </div>
                                </div>
                            </div>


                            {/* GROUP 2 */}
                            <div className="col-xl-5 xl-100">
                                {data?.visit?.user && <>
                                    <h3 className="color-primary align-center pt-15">Dados do Responsável</h3>
                                    <div className="card">
                                        <div className="card-body flex">

                                            <div className="m-auto">
                                                {/* avatar */}
                                                <img className="avatar-image" src={require("../../assets/images/user-solid.svg")} alt="empty list" />
                                            </div>

                                            <div className="media-body pl-30 pt-15">

                                                <div className="flex pb-10">
                                                    <User className="contact-icon" />
                                                    <p>{data.visit.user.name}</p>
                                                </div>

                                                <div className="flex pb-10">
                                                    <Phone className="contact-icon" />
                                                    <p>{data.visit.user.phoneNumber}</p>
                                                </div>
                                                <div className="flex pb-15">
                                                    <Mail className="contact-icon" />
                                                    <p>{data.visit.user.username ? data.visit.user.username : data.visit.user.socialGoogleUsername}</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </>}

                                {data?.chart?.length > 0 && data?.chart != null &&
                                    <>
                                        <h3 className="color-primary align-center pt-15">Marcas encontradas</h3>
                                        <div className="card">
                                            <div className="card-body">
                                                {!loading && <Chart
                                                    width={'100%'}
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={chart}
                                                    options={{
                                                        title: '',
                                                        chartArea: { width: '50%' },
                                                        colors: ["#01edca", "#1ea6ec"],
                                                        hAxis: {
                                                            // title: 'Quantidade de Produtos',
                                                        },
                                                        vAxis: {
                                                            title: 'Marca',
                                                            minValue: 0,
                                                        },
                                                    }}
                                                    // For tests
                                                    rootProps={{ 'data-testid': '1' }}
                                                />}
                                            </div>
                                        </div>
                                    </>
                                }

                                <h3 className="color-primary align-center pt-15">Detalhes da Visita</h3>

                                <div className="card">
                                    <div className="card-body">
                                        {canManage && <div className="custom-input">
                                            <label htmlFor="registerDate">Status da Visita</label>
                                            <br />
                                            <div className="flex">
                                                <Switch
                                                    handleToggle={handleChangeVisitStatus}
                                                    isOn={data?.visit?.isActive}
                                                    isOnColor="#01edca"
                                                    isOffColor="#ffc30f"
                                                    id="user-toggle" />
                                                <label style={{ paddingLeft: '15px', fontStyle: 'italic' }}>
                                                    {data?.visit?.isActive ? 'Ativada' : 'Inativada'}
                                                </label>
                                            </div>
                                        </div>}

                                        {!canManage && <div className="custom-input">
                                            <p>Visita {data?.visit?.isActive ? 'Ativada' : 'Inativada'}</p>
                                        </div>}

                                        {canManage && <div className="custom-input">
                                            <label htmlFor="registerDate">Data de Criação</label>
                                            <br />
                                            <input name="registerDate" className="custom-input-item" type="text" defaultValue={data?.visit?.survey?.registerDate} id="registerDate" disabled={true} />
                                        </div>}

                                        <div className="custom-input">
                                            <label htmlFor="finishDate">Data de Finalização</label>
                                            <br />
                                            <input name="finishDate" className="custom-input-item" type="text" defaultValue={data?.visit?.finishDate} id="finishDate" disabled={true} />
                                        </div>

                                        {canManage && (
                                            <>
                                                <div className="custom-input">
                                                    <label htmlFor="visitValue">Valor da pesquisa</label>
                                                    <br />
                                                    <input className="custom-input-item" name="visitValue" type="text" defaultValue={data?.visit?.survey?.value.toFixed(2)} id="visitValue" disabled={true} />
                                                </div>

                                                <div className="custom-input">
                                                    <label htmlFor="routeIdentifier">Identificação do Combo</label>
                                                    <br />
                                                    <input className="custom-input-item" name="routeIdentifier" type="text" defaultValue={data?.visit?.route?.identifier} id="routeIdentifier" disabled={true} />
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>



                            {/* GROUP 3 */}
                            {/* <div class="col-xl-6">
                            <div class="card">
                                <div class="card-header-border card-header">
                                    <h5>Project Files</h5>
                                </div>
                                <div class="card-body">
                                    
                                </div>
                            </div>
                        </div> */}


                            {/* GROUP 4 */}
                            {/* <div class="col-xl-6">
                            <div class="card">
                                <div class="card-header-border card-header">
                                    <h5>Activitys</h5>
                                </div>
                                <div class="recent-notification card-body">
                                    
                                </div>
                            </div>
                        </div> */}

                        </div>

                        <div className="card">
                            {loading &&
                                <div className="loader-box loading">
                                    <div className="loader">
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                    </div>
                                </div>
                            }
                        </div>


                        <h2 className="color-primary align-center pt-15 pb-15">Questionário</h2>

                        <div className="row">
                            {data?.visit?.answers?.map((answer) => (
                                <div className="col-xl-6 xl-100" key={answer.id}>

                                    <div className="card">
                                        <div className="mt-4 card-body">
                                            <div className="tab-content">
                                                <div className="tab-pane active">

                                                    <div className="project-box">
                                                        {canManage && <>
                                                            {editedAnswers.includes(answer.id) && <b className="waiting-color btn-icon">Alteração não salva</b>}
                                                            <span className="main-color pl-5 col-12"><b>{answer.surveyQuestion.number})&nbsp;</b>{answer.surveyQuestion.title}</span>
                                                        </>}

                                                        <div className="mt-4 col-sm-12">
                                                            <div className="details">
                                                                <p>Marca:&nbsp;<span className="color-primary">{answer?.surveyQuestion?.brand?.name ? (<span className="tag-pdv">{answer.surveyQuestion.brand.name}</span>) : '-'}</span></p>
                                                                <p>Tipo de resposta:&nbsp;<span className="color-primary">{getAnswerType(answer?.surveyQuestion?.answerType)}</span></p>
                                                                {answer?.surveyQuestion?.answerType === 1 && <p>Quantidade de gôndolas:&nbsp;<span className="color-primary">{answer.answerGondolas.length}</span></p>}
                                                            </div>

                                                            <div>
                                                                <p>
                                                                    <b>Resposta: </b>
                                                                    {answer?.surveyQuestion?.answerType !== SURVEY_ANSWERS_TYPE.Camera && answer?.surveyQuestion?.answerType !== SURVEY_ANSWERS_TYPE.InBulk &&
                                                                        answer?.surveyQuestion?.answerType === SURVEY_ANSWERS_TYPE.Bool ?
                                                                        <>
                                                                            <br />
                                                                            {canManage &&
                                                                                <>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            value={null}
                                                                                            checked={getAnswerValue(answer?.surveyQuestion?.answerType, answer) === null}
                                                                                            onChange={({ target }) => setBoolAnswerValue(answer?.id, target.value)}
                                                                                        />
                                                                                        Não informado
                                                                                    </label>
                                                                                    <br />
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            value={true}
                                                                                            checked={getAnswerValue(answer?.surveyQuestion?.answerType, answer) === true}
                                                                                            onChange={({ target }) => setBoolAnswerValue(answer?.id, target.value)}
                                                                                        />
                                                                                        Sim
                                                                                    </label>
                                                                                    <br />
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            value={false}
                                                                                            checked={getAnswerValue(answer?.surveyQuestion?.answerType, answer) === false}
                                                                                            onChange={({ target }) => setBoolAnswerValue(answer?.id, target.value)}
                                                                                        />
                                                                                        Não
                                                                                    </label>
                                                                                </>
                                                                            }


                                                                            {!canManage && <select
                                                                                id={`${answer?.surveyQuestionId}`}
                                                                                style={{ width: '200px' }}
                                                                                disabled={true}
                                                                                onChange={({ target }) => setBoolAnswerValue(answer?.id, target.value)}
                                                                                value={getAnswerValue(answer?.surveyQuestion?.answerType, answer)}
                                                                            >
                                                                                <option disabled={undefined} value={null}>Não informado</option>
                                                                                <option value="true">Sim</option>
                                                                                <option value="false">Não</option>
                                                                            </select>}
                                                                        </>
                                                                        :
                                                                        answer?.surveyQuestion?.answerType !== SURVEY_ANSWERS_TYPE.Camera && answer?.surveyQuestion?.answerType !== SURVEY_ANSWERS_TYPE.InBulk &&
                                                                        <>
                                                                            { answer?.surveyQuestion?.answerType == SURVEY_ANSWERS_TYPE.Text && 
                                                                                <Input
                                                                                    label=''
                                                                                    type='text'
                                                                                    name={`${answer?.surveyQuestionId}`}
                                                                                    disabled={!canManage}
                                                                                    value={getAnswerValue(answer?.surveyQuestion?.answerType, answer)}
                                                                                    onChange={({ target }) => setTextAnswerValue(answer?.id, target.value)}
                                                                                    inputClassName='w-100'
                                                                                />
                                                                            }

                                                                            { answer?.surveyQuestion?.answerType == SURVEY_ANSWERS_TYPE.OptionalDecimal &&
                                                                                <Input
                                                                                    label=''
                                                                                    type='number'
                                                                                    name={`${answer?.surveyQuestionId}-decimal`}
                                                                                    disabled={!canManage}
                                                                                    required={true}
                                                                                    error={answer?.answerDecimal === null || answer?.answerDecimal === ""}
                                                                                    value={getAnswerValue(answer?.surveyQuestion?.answerType, answer)}
                                                                                    onChange={({ target }) => setDecimalAnswerValue(answer?.id, target.value)}
                                                                                    inputClassName='w-20vw'
                                                                                />
                                                                            }

                                                                        </>
                                                                    }
                                                                </p>
                                                            </div>

                                                            {<div>
                                                                {answer?.answerGondolas?.map(gondola => (
                                                                    <div key={gondola.id}>
                                                                        {renderGondola(gondola.images, answer.surveyQuestion.title)}

                                                                        {data?.table?.filter(i => i.gondolaId === gondola.id).length > 0 && (<div className="flex wrap col-12" style={{ overflowX: 'auto' }} key={gondola.id}>
                                                                            <GondolasImageTable answer={data?.table?.filter(i => i.gondolaId === gondola.id)} />
                                                                        </div>)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            }

                                                            {answer?.answerInBulkImagePath !== null && answer?.answerInBulkImagePath !== '' &&
                                                                (<div className="gondolas-images" onClick={() => setPhotoView(true)}>
                                                                    <div className="image-gallery" onClick={() => {
                                                                        var imagesList = [];
                                                                        imagesList.push(getImageBoxObject(answer?.id, getImagePath(answer?.answerInBulkImagePath), [], []));
                                                                        setImagesViewBox([...imagesList]);
                                                                        open(0);
                                                                    }}
                                                                    >
                                                                        <ProgressiveImage src={getImagePath(answer.answerInBulkImagePath)} loadedClass='visit_details_inbulk_loaded' />
                                                                    </div>
                                                                </div>)
                                                            }

                                                            <div className="flex wrap">
                                                                {answer?.answersInBulk?.map(inBulkAnswer => (
                                                                    <div key={inBulkAnswer.id} className="col-6">
                                                                        <p>Marca:&nbsp;{inBulkAnswer?.brand?.name ? <span className="color-primary tag-pdv">{inBulkAnswer.brand.name}</span> : '-'}</p>
                                                                        <p>Valor:&nbsp;<span className="color-primary col-6">{getMoneyFormat(inBulkAnswer.price)}</span></p>
                                                                        <br />
                                                                    </div>
                                                                ))
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex place-center">
                            {showEditButton && 
                                data?.visit?.answers?.filter(a => a?.surveyQuestion?.answerType == SURVEY_ANSWERS_TYPE.OptionalDecimal).every(b => b.answerDecimal !== null && b.answerDecimal !== "" && b.answerDecimal !== undefined) &&
                                <button className="btn btn-pill btn-outline-secondary btn-icon" type="button" onClick={saveVisitsEdit}>
                                    <Edit /> <hr />Salvar alterações
                                </button>
                            }
                        </div>

                    </div>
                </div>
                <ToastContainer />

                {photoView && (
                    <PhotoViewer
                        images={imagesViewBox}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        showProductButtons={canManage}
                    />
                )}
            </div>

            <ApproveModal isOpen={approveModalIsOpen} toggle={(event, success = null, approved) => {
                setApproveModalIsOpen(false);

                if (success != null) {
                    if (success === true) {
                        toast.success("Status da visita alterado com sucesso!");

                        let item = { ...data };
                        if (item.visit.approval) {
                            item.visit.approval.approved = approved;
                        } else {
                            item.visit.approval = {
                                approved: approved
                            }
                        }
                        setData(item);
                    }
                    else {
                        toast.error("Não foi possível completar sua solicitação, por favor tente novamente mais tarde!");
                    }
                }
            }} visitId={approveModalVisitId} />

            <AuthorizePDVModal
                isOpen={showAuthorizeModal}
                id={approveModalPDVId}
                toggle={async (event, success = null) => {
                    setShowAuthorizeModal(false);

                    if (success != null) {
                        if (success === true) {
                            toast.success("Autorização de Ponto de Venda registrada com sucesso!");

                            let id = params.id;
                            setLoading(true);
                            if (id)
                                getDetail(id);

                        }
                        else {
                            toast.error("Não foi possível completar sua solicitação, por favor tente novamente mais tarde!");
                        }
                    }
                }}
            />

        </Fragment>
    );
};

export default VisitDetails;