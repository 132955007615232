import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// toggle
const SimulationResponse = ({toggle, isOpen, loading, data, status}) => {

    // modal confirmation
    
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '90%' }}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody style={{ pointerEvents: loading ? 'none' : undefined, textAlign: 'center' }}>

                {status === 200 && <h2 className="color-primary">Combo gerado com sucesso!</h2>}   

                <h3 className="color-primary align-left">Questionário</h3>

                {data.map((item, index) => (
                    <div className="align-left">{item.number} {item.title}</div>
                ))}

            </ModalBody>
        </Modal>
    );
}

export default SimulationResponse;