import React, { Fragment, useState } from 'react';
import AddButton from '../../components/common/buttons/default';
import { Alert } from "../../components/common/modals/modals.js";
import { Edit3 as Edit } from 'react-feather';
import { campaignsColumns as columns } from "../../utils/tablesHelper";
import { hasFullAccess, updateFilterValues } from "../../utils/helper";
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer } from "react-toastify";
import Datatable from "../../components/tables/table";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useService } from "../../hooks/useService";
import DynamicFilter from '../../components/common/filters/dynamic_filter.js';
import { Plus } from 'react-feather';
import { campaignsFilters } from './components/filters';

const CampaignsList = (props) => {
    // table state
    const { user } = useAuth0();
    const service = useService();
    const localStorageFilterKey = 'campaigns-filters';
    const tableFilterConfigurations = 'campaigns-table-config';
    const [loadingTable, setLoadingTable] = useState(true);
    const [canManageCampaign, setcanManageCampaign] = useState(false);
    const [data, setData] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [tableFilters, setTableFilters] = React.useState([...campaignsFilters]);

    React.useEffect(() => {

        async function init() {
            const filtersResult = await localStorage.getItem(localStorageFilterKey);
            let filters = [];

            if(filtersResult != null) {
                const resultFilters = JSON.parse(filtersResult);
                filters = updateFilterValues(campaignsFilters, resultFilters);
                setTableFilters(filters);
            }

            var tableConfigResult = localStorage.getItem(tableFilterConfigurations);
            var page = 0;
            var pageSize = 10;
            var sortColumn = null;
            var sortDirection = 0;

            if(tableConfigResult != null) {

                var tableConfig;
                if(tableConfigResult)
                    tableConfig = JSON.parse(tableConfigResult);
    
                if(tableConfig?.page){
                    setCurrentPage(tableConfig.page);
                    page = tableConfig.page;
                }
    
                if(tableConfig?.pageSize) {
                    setPageSize(tableConfig.pageSize);
                    pageSize = tableConfig.pageSize;
                }
                
                if(tableConfig?.ordenation?.sortColumn) {
                    setSortColumn(tableConfig.ordenation.sortColumn);
                    sortColumn = tableConfig.ordenation.sortColumn;
                }
    
                if(tableConfig?.ordenation?.sortDirection) {
                    setSortDirection(tableConfig.ordenation.sortDirection);
                    sortDirection = tableConfig.ordenation.sortDirection;
                }
            }

            loadTableColumns()

            let filter = [...tableFilters];
            let clientFilter = filter.find(f => f.name === 'clientId');
            let clients = await getClients();

            if(clients?.length > 1) {
                clientFilter.options = clients;
            }else {
                filter = filter.filter(c => c.name !== 'clientId');
            }
            setTableFilters([...filter]);

            if(user)
                await getCampaigns(page, pageSize, sortColumn, sortDirection, null, filters);
        }

        init();
    }, [user]);

    const loadTableColumns = () => {
        let isMaster = hasFullAccess();
        setcanManageCampaign(isMaster)
        if(isMaster){
            setTableColumns(columns);
        }
        else {
            var c = columns;
            c[0].columns = columns[0].columns.filter(c => c.accessor !== 'details')
            setTableColumns(c)
        }
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);
        
        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem(tableFilterConfigurations);
        
        setTableFilters(tableFilters.map(t => {
            t.value = '';
            return t;
        }))
        
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);
        
        await getCampaigns();
        setLoadingTable(false);
    }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }

        localStorage.setItem(tableFilterConfigurations, JSON.stringify(tableConfig));
    }

    const getStatus = (status, value) => {
        return <span className={status === 0 ? 'success-tag' : 'late-tag'}>{value}%</span>
    }

    const getClients = async () => {
        let list;
        await service.get("/api/client/list")
        .then(async response => {
            list = response?.data?.data?.map(c => ({
                name: c.name,
                value: c.id
            }));
        });
        return list;
    }

    const getCampaigns = async(page = 0, currentPageSize = 10, sortColumnFilter = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            sortColumnFilter = null;

        setSortColumn(sortColumnFilter);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumnFilter, orderDirection, page, currentPageSize);

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {
            if(filter.value != '' && filter.value != null && filter.value != -1) {
                filters.push({
                    name: filter.name,                    
                    value: filter.value
                })
            }
        })

        var skip = 0;

        if(!isNaN(page)) {
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);            
        }

        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumnFilter,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/campaign/list", payload)
        .then(async response => {
            if (response?.data?.data === null) {
                return await Alert("", "Falha ao buscar os dados", "error");
            }
            var items = response?.data?.data?.data.map((c, index) => {
                let surveysMedia = c.surveysAnswered > 0 
                    ? 
                    <>
                        <span className="align-center pointer underline executionStatus" onClick={() => {props.history.push(`/visits/list?campaign=${c.id}`)}}>
                            {c.surveysAnswered}</span>/
                        <span className='creationStatus'>{c.surveysCreated}</span>/
                        <span className='planStatus'>{c.surveysAmount}</span>
                    </>
                    : 
                    <></>
                    ;
                return {
                    id: c.id,
                    name: <p className="clickable-p pointer" onClick={() => {props.history.push(`/campaigns/details/${c.id}`)}}>{c.name}</p>,
                    startDate: new Date(c.startDate).toLocaleDateString('pt-BR'),
                    endDate: new Date(c.endDate).toLocaleDateString('pt-BR'),
                    pdvsVisited: <>
                        <span className='executionStatus'>{c.pointsOfSaleVisited}</span>/
                        <span className='creationStatus'>{c.pointsOfSaleCreated}</span>/
                        <span className='planStatus'>{c.pointsOfSaleAmount}</span>
                        </>,
                    surveysVisited: c.surveysAnswered > 0 ? <div>{surveysMedia}</div> : surveysMedia,
                    // surveysVisited: c.surveysAnswered > 0 ? <a href={`/visits/list/campaign/${c.id}`}>{surveysMedia}</a> : surveysMedia,
                    expectedStatusPercentage:  <span className="main-color-tag">{c.surveysAmount === 0 && c.pointsOfSaleAmount === 0 ? '-' : `${c.expectedStatusPercentage}%`}</span>,
                    createdStatusPercentage: <span className={c.creationStatus >= 100 ? 'success-tag' : 'late-tag'}>{c.creationStatus}%</span>,
                    currentStatusPercentage: c.surveysAmount === 0 && c.pointsOfSaleAmount === 0 ? '-' : getStatus(c.status, c.currentStatusPercentage),
                    details: <div className="align-center pointer" onClick={() => {props.history.push(`/campaigns/${c.id}`)}}><Edit /></div>,
                    recognizedItems: c.productsRecognizedAmount === 0 && c.valuesRecognizedAmount === 0 ? '-' : <><span className='campaigns_list_recognition_products'>{c.productsRecognizedAmount}</span>/<span className='campaigns_list_recognition_prices'>{c.valuesRecognizedAmount}</span></>
                }
            })
            setData(items);
            if(response?.data?.data?.recordsFiltered)
                setRecordsFiltered(response.data.data.recordsFiltered);

        if (firstLoad)
            setFirstLoad(false);
            

        }).finally(() => {
            setLoadingTable(false);
        });

    }

    return (
        <Fragment>
            <Breadcrumb title="Campanhas" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                        
                               <div className="flex space-between">
                               <span className="d-flex">                                    
                                    <h5>Listagem de Campanhas</h5>
                                    <p className="pl-10 pt-3 total-legend">({recordsFiltered} itens)</p>
                                </span>
                                {canManageCampaign &&
                                    (<AddButton 
                                        description="Adicionar campanha"
                                        icon={<Plus/>}
                                        onClick={() => props.history.push("/campaigns/new")}
                                    />)}
                               </div>
                                <div className="flex space-between">
                                    <DynamicFilter 
                                        handleSearch={getCampaigns}
                                        filters={tableFilters}
                                        localStorageName={localStorageFilterKey}
                                        handleReset={handleFilterReset}
                                        handleFilterChange={setTableFilters}
                                        saveFilterState={saveFilterConfig}
                                    />
                                </div>
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhuma campanha encontrada</div>
                                    <img className="empty-list-img" src={require("../../assets/images/none-selected.svg")} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                            <div className="loader-box loading" style={{minHeight: '50vh'}}>
                                <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>
                            }

                            {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
                                    <Datatable
                                        multiSelectOption={false}
                                        firstLoad={firstLoad}
                                        loading={loadingTable}
                                        setLoading={setLoadingTable}
                                        pageSize={pageSize}
                                        columns={tableColumns}
                                        twoLinesHeader ={true}
                                        myData={data}
                                        totalPages={Math.ceil(recordsFiltered/pageSize)}
                                        setPageSize={setPageSize}
                                        pagination={true}
                                        manual={true}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        class="-striped -highlight"
                                        onPageChange={getCampaigns}
                                        sortColumn={sortColumn}
                                        sortColumnDirection={sortDirection}
                                    />
                            </div>}
                            
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </Fragment>
    );
};

export default CampaignsList;