export const saveValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
}

export const getValue = (key) => {
  let value = localStorage.getItem(key);

  if(value)
    return JSON.parse(value)
  return undefined;
}