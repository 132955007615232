import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ThumbsUp, ThumbsDown } from 'react-feather';
import { useAuth0 } from "@auth0/auth0-react";
import { useService } from "../../../hooks/useService";
import "react-table/react-table.css";

const ApproveModal = ({toggle, isOpen, visitId}) => {

    const [loading, setLoading] = React.useState(false);
    const [observation, setObservation] = React.useState("");
    const [obsError, setObsError] = React.useState(false);
    const [feedbackBlurryPicture, setFeedbackBlurryPicture] = React.useState(false);
    const [feedbackPictureInPerspective, setFeedbackPictureInPerspective] = React.useState(false);
    const [feedbackPictureOutOfFrame, setFeedbackPictureOutOfFrame] = React.useState(false);
    const { user } = useAuth0();
    const service = useService();

    React.useEffect(() => {
        setObservation('');
        setFeedbackBlurryPicture(false);
        setFeedbackPictureInPerspective(false);
        setFeedbackPictureOutOfFrame(false);
    }, [isOpen])

    const approveVisit = async (approved) => {

        if(loading)
            return;

        if(observation === ''){
            setObsError(true);
            return;
        }

        let backofficeUser = user?.email;

        if(!backofficeUser) {
            setObservation("Não foi possível salvar o nome do usuário do logado, por favor tente fazer login novamente.");
            setObsError(true);
            return;
        }

        const payload = {
            id: visitId,
            approved: approved,
            ApprovalByUserMail: backofficeUser,
            observation: observation,
            feedbackBlurryPicture: feedbackBlurryPicture,
            feedbackPictureInPerspective: feedbackPictureInPerspective,
            feedbackPictureOutOfFrame: feedbackPictureOutOfFrame
        }

        setLoading(true);

        await service.post(`/api/visit/approve`, payload)
            .then(response => {
                let success = false;
                if (response.status === 200 && response.data.status === 200) {
                    success = true;
                }

                setObservation("");
                setLoading(false);

                toggle(null, success, approved);
            })
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '50%' }}>
                <ModalHeader toggle={toggle}>Aprovação de Visita</ModalHeader>
                <ModalBody>

                    <p>Observações</p>
                    <textarea className="w-100 mb-20" disabled={loading} value={observation} onChange={({target}) => setObservation(target.value)} style={{border: obsError && observation === '' ? '2px solid red' : 'none'}} />

                    <label className="checkmark-container">Imagem desfocada
                        <input type="checkbox" checked={feedbackBlurryPicture} onChange={() => setFeedbackBlurryPicture(!feedbackBlurryPicture)} />
                        <span className="checkmark"></span>
                    </label>
                    
                    <label className="checkmark-container">Imagem em perspectiva
                        <input type="checkbox" checked={feedbackPictureInPerspective} onChange={() => setFeedbackPictureInPerspective(!feedbackPictureInPerspective)} />
                        <span className="checkmark"></span>
                    </label>

                    <label className="checkmark-container">Imagem fora do quadro
                        <input type="checkbox" checked={feedbackPictureOutOfFrame} onChange={() => setFeedbackPictureOutOfFrame(!feedbackPictureOutOfFrame)} />
                        <span className="checkmark"></span>
                    </label>

                    {obsError && observation === '' && <p className="error-color">*Campo obrigatório</p>}
                     
                </ModalBody>

                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }

                <ModalFooter className="modal-footer-around">                        
                    <div className="error-thumb-btn" onClick={() => approveVisit(false)}  style={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
                        <ThumbsDown className="error-color" /> Não Aprovar
                    </div>
                    
                    <div className="success-thumb-btn"  style={{ cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => approveVisit(true)}>
                        <ThumbsUp className="secondary-color" /> Aprovar
                    </div>
                </ModalFooter>
            </Modal>
            
        </>
    );
}

export default ApproveModal;