import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Datatable from "../../../components/tables/table";
import { userInvitationsColumns as columns } from "../../../utils/tablesHelper";

const UserInvitationsModal = ({toggle, isOpen, userName, invitations}) => {

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);

    React.useEffect(() => {
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '85%' }}>
            <ModalHeader toggle={toggle}>Convites enviados por <span className="color-primary">{userName}</span></ModalHeader>
            <ModalBody>
                <Datatable
                    multiSelectOption={false}
                    myData={invitations}                                        
                    loading={false}
                    pageSize={invitations?.length}
                    pagination={false}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    className="-striped -highlight"
                    columns={columns}
                    totalPages={Math.ceil(invitations?.length/pageSize)}
                    setPageSize={setPageSize}
                    manual={true}
                />
            </ModalBody>
        </Modal>
    );
}

export default UserInvitationsModal;