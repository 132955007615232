import React from 'react';
import { LoadingContext } from '../../../context/UserContext';

const LoadingBar = () => {
    const { showLoadingBar } = React.useContext(LoadingContext);

    return (
        <div className="loading-container">
            {showLoadingBar && <div className="loading-container-bar"></div>}
        </div>
    )
}

export default LoadingBar;