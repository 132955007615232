import React from "react";

const Loading = () => (
    
    <div className="loader-box loading">
        <div className="loader">
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
        </div>
    </div>
)

export default Loading;