import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useService } from "../../../../hooks/useService";
import { XCircle, X } from 'react-feather';
import "react-table/react-table.css";
import './usersSelectionModal.css';

const UsersSelectionModal = ({toggle, isOpen, users = []}) => {

    const searchInputRef = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [userQuery, setUserQuery] = React.useState("");
    const [usersSelected, setUsersSelected] = React.useState([]);
    const [usersOptions, setUsersOptions] = React.useState([]);
    const [usersFilterError, setUsersFilterError] = React.useState(false);

    const service = useService();

    React.useEffect(() => {
        setUserQuery("");
        setUsersFilterError(false);
        setUsersSelected([...users]);
    }, [isOpen]);

    const getUsers = async () => {
        if(loading)
            return;

        const payload = {
            isActive: true,
            searchValue: userQuery,
            pageSize: 100,
            skip: 0
        }

        setLoading(true);

        await service.post(`/api/user/simplelist`, payload)
            .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    let users = response.data.data.data.map(u => ({
                        id: u.id,
                        name: u.name,
                        username: u.username ?? u.socialGoogleUsername,
                        isSocialGoogleUser: u.socialGoogleUsername != null,
                        city: u.city,
                        state: u.state
                    }));
                    setUsersOptions([...users])
                    setLoading(false);
                    searchInputRef.current.focus();
                }else {
                    setLoading(false);
                    setUsersFilterError(true);
                }
            })
            .catch(error => {
                setLoading(false);
                setUsersFilterError(true);
            });
    }

    React.useEffect(() => {
        if (userQuery.length > 2) {
            getUsers();
        }

    }, [userQuery]);

    const handleSelectNewUser = (user) => {
        setUsersSelected([...usersSelected, user]);
      };
    
      const handleRemoveSelectedUser = (userId) => {
        setUsersSelected([...usersSelected.filter(u => u.id !== userId)]);
      };

    const selectUsers = async () => {
        toggle(null, true, usersSelected)        
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '85%' }}>
                <ModalHeader toggle={toggle}>Selecionar usuários</ModalHeader>

                <ModalBody>
                    <div className='flex space-between'>
                        <div className='custom-input mb-20 label-filter'>
                            <label htmlFor='user-query'>Filtrar por nome ou e-mail</label>
                            <br/>
                            <div  style={{display: 'flex'}}>
                                <input 
                                    ref={searchInputRef}
                                    id='user-query' 
                                    className='w-50vw'           
                                    name='user-query'
                                    value={userQuery}
                                    type='text'
                                    maxLength={30}
                                    disabled={loading}
                                    autoFocus={true}
                                    onChange={({target}) => setUserQuery(target.value)}
                                />
                                <div className='pointer' style={{alignSelf: 'self-end'}} onClick={() => setUserQuery("")}><X /></div>
                            </div>
                        </div>

                    </div>
                    <div className='users-selection-modal-container'>
                        <div className='users-selection-modal-container-column'>

                            <ul className=''>
                                {usersOptions?.filter((user) => !usersSelected.some((u) => u.id === user.id))
                                .map((user) => (
                                <li key={user.id} className={`pointer`} onClick={() => handleSelectNewUser(user)}>
                                    <b>{user.name}</b> <br/>
                                    {user.username} <br/>
                                    {user.city} - {user.state} <br/>
                                </li>
                                ))}
                            </ul>
                        </div>

                        <div className='users-selection-modal-container-column'>
                            <h3>Selecionados</h3>
                            <ul>
                                {usersSelected?.map((user) => (
                                <li key={`selected-${user.id}`} className='selected flex space-between'>
                                    <div>
                                        <p>{user.name}</p>
                                        <p>{user.username}</p>
                                    </div>
                                    <span className='pointer' onClick={() => handleRemoveSelectedUser(user.id)}><XCircle className='error-color' /></span>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                        
                    {usersFilterError && <p className='error-color'>Um erro ocorreu, por favor tente novamente mais tarde.</p>}
                </ModalBody>

                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }

                <ModalFooter className="modal-footer">
                    <button disabled={loading} className="btn btn-pill btn-danger" type="button" onClick={toggle}>Cancelar</button>
                    <button disabled={loading} className="btn btn-pill btn-primary btn-air-primary" type="button" onClick={selectUsers}>Adicionar</button>
                </ModalFooter>
            </Modal>
            
        </>
    );
}

export default UsersSelectionModal;