import React from 'react';
import Datatable from "../../../components/tables/table";
import "react-table/react-table.css";

const ReportedErrorsTable = ({loadingTable, data, pageSize, setPageSize, tableColumns, setCurrentPage,
  currentPage, recordsFiltered, getData, sortColumn, sortDirection}) => {

    const [firstLoad, setFirstLoad] = React.useState(true);

    React.useEffect(() => {
      setFirstLoad(false);
    }, [])

    return (
        <>
          {loadingTable && <div className="loader-box loading" style={{minHeight: '50vh'}}>
              <div className="loader">
                  <div className="line bg-primary"></div>
                  <div className="line bg-primary"></div>
                  <div className="line bg-primary"></div>
                  <div className="line bg-primary"></div>
              </div>
          </div>
          }

          {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
              <Datatable
                  multiSelectOption={false}
                  myData={data}
                  loading={loadingTable}
                  firstLoad={firstLoad}
                  pageSize={pageSize}
                  pagination={true}
                  class="-striped -highlight"
                  columns={tableColumns}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  totalPages={Math.ceil(recordsFiltered/pageSize)}
                  setPageSize={setPageSize}
                  onPageChange={getData}
                  selectedData={null}
                  changeStatus={undefined}
                  manual={true}
                  sortColumn={sortColumn}
                  sortColumnDirection={sortDirection}
              />
          </div>}
        </>
    );
}

export default ReportedErrorsTable;