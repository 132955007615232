import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useService } from "../../../hooks/useService";
import { getMoneyFormat, inputCurrencyConvertion } from "../../../utils/helper";
import Input from '../../../components/common/form/input';
import "react-table/react-table.css";

const MakePaymentModal = ({toggle, isOpen, userId, receiver, walletTotalAvailable}) => {

    const [loading, setLoading] = React.useState(false);
    const [valueToBePaid, setValueToBePaid] = React.useState('');
    const [description, setDescription] = React.useState("");
    const [valueToBePaidError, setValueToBePaidError] = React.useState(false);
    const [descriptionError, setDescriptionError] = React.useState(false);
    const [addTransactionWithoutChangeWallet, setAddTransactionWithoutChangeWallet] = React.useState(false);

    const service = useService();

    React.useEffect(() => {
        setValueToBePaid(0);
        setValueToBePaidError(false);
        setDescription("");
        setAddTransactionWithoutChangeWallet(false);
        setDescriptionError(false);
    }, [isOpen]);

    React.useEffect(() => {
        if (valueToBePaidError) {
            if (valueToBePaid <= walletTotalAvailable) {
                setValueToBePaidError(false);
            }
        }
    }, [valueToBePaid]);

    const makePayment = async () => {

        if(loading)
            return;

        if(valueToBePaid === '' || valueToBePaid === 0 || valueToBePaid === '0' || valueToBePaid === '0,00'){
            setValueToBePaidError('Informe um valor válido');
            return;
        }

        if((valueToBePaid > walletTotalAvailable) && !addTransactionWithoutChangeWallet){
            setValueToBePaidError('Valor deve ser menor ou igual que o disponível na carteira');
            return;
        }

        const payload = {
            userId: userId,
            value: valueToBePaid.replace(/\./g, '').replace(',', '.'),
            description: description,
            addTransactionWithoutChangeWallet: addTransactionWithoutChangeWallet
        }

        setLoading(true);

        await service.post(`/api/userwallet/pay`, payload)
            .then(response => {
                let success = false;
                if (response.status === 200 && response.data.status === 200) {
                    success = true;
                }

                setLoading(false);
                toggle(null, success, response.data.title);
            })
            .catch(error => {
                setLoading(false);
                setValueToBePaidError('Não foi possível realizar o pagamento')
            });
    }

    const onChangeValueToBePaid = (value) => {
        setValueToBePaid(value)
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '50%' }}>
                <ModalHeader toggle={toggle}>Efetuar Pagamento</ModalHeader>
                <ModalBody>
                    {!addTransactionWithoutChangeWallet && <p><b className='error-color'>Atenção: </b>Ao efetuar o pagamento, o valor será deduzido do saldo disponível em carteira.</p>}
                    <br/>
                    <div className='flex space-between'>                        
                        <Input label='Recebedor' type='text' name='name' required={true} value={receiver} maxLength={50} inputClassName='w-20vw' disabled breakLabelLine={true} />
                        <Input label='Valor disponível na carteira' type='text' name='walletTotalAvailable' required={true} value={getMoneyFormat(walletTotalAvailable).replace('.', ',')} inputClassName='w-20vw' disabled breakLabelLine={true} />
                    </div>

                    <div className='flex space-between'>
                        <div className={`custom-input mb-20 label-filter ${valueToBePaidError && !addTransactionWithoutChangeWallet && 'field-error'}`}>
                            <label className='required'>Valor a ser pago</label>
                            <br/>
                            <span>R$</span>
                            <input 
                                className="w-19vw"
                                name={'transaction_value'}
                                type="text"
                                id={'transaction_value'} value={valueToBePaid}
                                onChange={({target}) => onChangeValueToBePaid(inputCurrencyConvertion(target.value))}
                            />

                            {valueToBePaidError && !addTransactionWithoutChangeWallet && <p className='error-field'>{valueToBePaidError}</p>}
                            {valueToBePaidError && valueToBePaid === '' && !addTransactionWithoutChangeWallet && <p className="error-color">*Campo obrigatório</p>}
                        </div>

                        <div className={`custom-input mb-20 label-filter w-20vw ${descriptionError && 'field-error'}`}>
                            <p className='required'>Notas da transação</p>
                            <textarea disabled={loading} className="w-20vw" value={description} onChange={({target}) => setDescription(target.value)} style={{border: descriptionError && description === '' ? '2px solid red' : 'none'}} />
                            {descriptionError && <p className='error-field'>{descriptionError}</p>}
                        </div>
                    </div>

                    <div className='flex space-between'>
                        <label className="checkmark-container">Pagar valor adicional, <b className='error-color'>sem abater do valor disponível na carteira</b>.
                            <input disabled={loading} type="checkbox" checked={addTransactionWithoutChangeWallet} onChange={() => setAddTransactionWithoutChangeWallet(!addTransactionWithoutChangeWallet)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </ModalBody>

                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }

                <ModalFooter className="modal-footer">
                    <button disabled={loading} className="btn btn-pill btn-danger" type="button" onClick={toggle}>Cancelar</button>
                    <button disabled={loading} className="btn btn-pill btn-primary btn-air-primary" type="button" onClick={makePayment}>Pagar</button>
                </ModalFooter>
            </Modal>
            
        </>
    );
}

export default MakePaymentModal;