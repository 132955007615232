import React from 'react';
import { GOOGLE_MAPS_LINK } from "../hooks/useService";
import { MENUITEMS } from "../constant/menu";
import { getValue } from './storage';
import { VIEWS_ROLES } from './enums';

export const getAddressLink = (address, latitude, longitude) => {
    return <a target="_blank" href={`${GOOGLE_MAPS_LINK}${latitude},${longitude}`}>{address}</a>;
}

export const getMoneyFormat = (value) => {
    return Number.isNaN(value) ? 'R$0.00' : `R$${value.toFixed(2)}`;
}

export const inputCurrencyConvertion = (value) => {
    if(value === '-') {
        return value;
    }

    if(value?.charAt(value?.length - 1) === '-') {
        return '-';
    }

    value = value.replace('.', '').replace(',', '').replace(/[^\d-]/g, '');

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    )
    console.log('result', result)
    return isNaN(result.replace(',', '')) ? '' : result;
}

export const getLocation = (setLatitude, setLongitude) => {
    navigator.geolocation.getCurrentPosition(function(position) {
        let lat = position?.coords?.latitude;
        let lon = position?.coords?.longitude;

        if(lat === 0 || lat === undefined){
            lat = -23.5489;
            lon = -46.6388;
        }
        setLatitude(lat);
        setLongitude(lon);
      });
}

export const formatDate = (d = new Date()) => {
    return [d?.getDate(), d?.getMonth()+1, d?.getFullYear().toString().slice(2,4)]
        .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
}

export const getPagePermission = (path) => {
    if(path === '/')
        return true
    var permissions = getValue("authorization");

    if(permissions === undefined) {
        return undefined;
    }
    
    if(permissions?.includes(VIEWS_ROLES.ALL)) {
        return true;
    }
        
    var menuItem = MENUITEMS.find(m => m.path === path)

    if (path.startsWith("/report/") && permissions.includes(1)) {
        return true
    }

    if(!menuItem && (!path.startsWith('/visits/details/') && !path.startsWith('/users/details/') && !path.startsWith('/campaigns/details/'))) {
        return false
    }

    if(!permissions?.includes(menuItem?.permission) && (!path.startsWith('/visits/details/') && !path.startsWith('/users/details/') && !path.startsWith('/campaigns/details/'))) {
        return false
    }

    return true
}

export const hasFullAccess = () => {
    var permissions = getValue("authorization");
    if(permissions?.includes(VIEWS_ROLES.ALL))
        return true;
    return false
}

export const getTimeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " anos atrás";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " meses atrás";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " dias atrás";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " horas atrás";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutos atrás";
    }
    return Math.floor(seconds) + " segundos atrás";
}

export const getPercentage = (originalValue, partialValue) => {
    return (100 * partialValue) / originalValue;
}

export const states = [
    { id:'AC', value: 'AC', name: 'Acre'},
    { id:'AL', value: 'AL', name: 'Alagoas'},
    { id:'AP', value: 'AP', name: 'Amapá'},
    { id:'AM', value: 'AM', name: 'Amazonas'},
    { id:'BA', value: 'BA', name: 'Bahia'},
    { id:'CE', value: 'CE', name: 'Ceará'},
    { id:'DF', value: 'DF', name: 'Distrito Federal'},
    { id:'ES', value: 'ES', name: 'Espírito Santo'},
    { id:'GO', value: 'GO', name: 'Goiás'},
    { id:'MA', value: 'MA', name: 'Maranhão'},
    { id:'MT', value: 'MT', name: 'Mato Grosso'},
    { id:'MS', value: 'MS', name: 'Mato Grosso do Sul'},
    { id:'MG', value: 'MG', name: 'Minas Gerais'},
    { id:'PA', value: 'PA', name: 'Pará'},
    { id:'PB', value: 'PB', name: 'Paraíba'},
    { id:'PR', value: 'PR', name: 'Paraná'},
    { id:'PE', value: 'PE', name: 'Pernambuco'},
    { id:'PI', value: 'PI', name: 'Piauí'},
    { id:'RJ', value: 'RJ', name: 'Rio de Janeiro'},
    { id:'RN', value: 'RN', name: 'Rio Grande do Norte'},
    { id:'RS', value: 'RS', name: 'Rio Grande do Sul'},
    { id:'RO', value: 'RO', name: 'Rondônia'},
    { id:'RR', value: 'RR', name: 'Roraima'},
    { id:'SC', value: 'SC', name: 'Santa Catarina'},
    { id:'SP', value: 'SP', name: 'São Paulo'},
    { id:'SE', value: 'SE', name: 'Sergipe'},
    { id:'TO', value: 'TO', name: 'Tocantins'}
];

export const statesWithId = [
    { value: '12', name: 'Acre'},
    { value: '27', name: 'Alagoas'},
    { value: '16', name: 'Amapá'},
    { value: '13', name: 'Amazonas'},
    { value: '29', name: 'Bahia'},
    { value: '23', name: 'Ceará'},
    { value: '53', name: 'Distrito Federal'},
    { value: '32', name: 'Espírito Santo'},
    { value: '52', name: 'Goiás'},
    { value: '21', name: 'Maranhão'},
    { value: '51', name: 'Mato Grosso'},
    { value: '50', name: 'Mato Grosso do Sul'},
    { value: '31', name: 'Minas Gerais'},
    { value: '15', name: 'Pará'},
    { value: '25', name: 'Paraíba'},
    { value: '41', name: 'Paraná'},
    { value: '26', name: 'Pernambuco'},
    { value: '22', name: 'Piauí'},
    { value: '33', name: 'Rio de Janeiro'},
    { value: '24', name: 'Rio Grande do Norte'},
    { value: '43', name: 'Rio Grande do Sul'},
    { value: '11', name: 'Rondônia'},
    { value: '14', name: 'Roraima'},
    { value: '42', name: 'Santa Catarina'},
    { value: '35', name: 'São Paulo'},
    { value: '28', name: 'Sergipe'},
    { value: '17', name: 'Tocantins'}
];

export const updateFilterValues = (filterFields, filterValueFields) => {
    var updatedFilter = [...filterFields];
    for (let i = 0; i < updatedFilter.length; i++) {
        var value;
        const filteredField = filterValueFields?.find(f => f.name === updatedFilter[i]?.name);
        if(filteredField) {
            value = filteredField.value;
        }
        if(value !== undefined && value !== null && value !== '' && value !== -1) {      
            updatedFilter[i].value = value;
        }
      }
    return updatedFilter;
}

export const isJSONString = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        console.log('error', e)
        return false;
    }
    return true;
}

export const insertCaractere = function(initialString, index, string) {
    let caractere = initialString.slice();
    if (index > 0) {
      return caractere.substring(0, index) + string + caractere.substring(index);
    }
    return caractere;
};

export const getConvertedDateTimeFromUTC = (datetime) => {
    if (datetime) {
        // Check if datetime ends with 'Z', if not, append it
        if (!datetime.endsWith('Z')) {
            datetime += 'Z';
        }

        const parsedDate = new Date(datetime);

        if (!isNaN(parsedDate.getTime())) {
            const options = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            };

            return parsedDate.toLocaleDateString('pt-BR', options);
        }
    }

    return '';
};
