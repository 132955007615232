import alert from 'sweetalert2';

const AlertConfirmation = (title = '', text = '') => {
    return alert.fire({
        title,
        text,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
    })
}

const Alert = (text = '', title = '', icon = '') => {
    return alert.fire({
        title,
        text,
        type: icon,
        allowOutsideClick: true,
        confirmButtonColor: "#1ea6ec"
    });
}

export { AlertConfirmation, Alert };