import React, { Fragment, useState } from 'react';
import DynamicFilter from '../../components/common/filters/dynamic_filter';
import { surveysColumns as columns } from "../../utils/tablesHelper";
import { getAddressLink, getMoneyFormat, updateFilterValues, formatDate } from "../../utils/helper";
import { Alert } from "../../components/common/modals/modals.js";
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from "react-toastify";
import Datatable from "../../components/tables/table";
import { useService } from "../../hooks/useService";
import GoogleApiWrapper from '../../components/common/pointer-locations-map/map-filter';
import emptyImage from '../../assets/images/emptylist.png';
import { surveysFilter } from './components/filters';

const SurveysList = () => {

    const localStorageFilterKey = 'survey-filters';
    const [loadingTable, setLoadingTable] = useState(true);
    const [data, setData] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    var surveysTableFilterKey = 'survey-table-config';
    const service = useService();

    const [tableFilters, setTableFilters] = React.useState([...surveysFilter]);
    let [, setState] = useState(); // used to re-render the page (to the date-picker update when invalid)
    const forceUpdate = React.useCallback(() => setState({}), []);

    const getCampaigns = async () => {
        let list;
        await service.get("/api/campaign/simplelist")
            .then(async response => {
                list = response?.data?.data.map(c => ({
                    name: c.name,
                    value: c.id,
                    text: c.name,
                    startDate: c.startDate,
                    endDate: c.endDate,
                    clientName: c.clientName,
                    clientId: c.clientId,
                    disabled: false // used by dynamicFilter component
                }));
            });
        return list;
    }

    const getSurveys = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            sortColumn = null;

        setSortColumn(sortColumn);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumn, orderDirection, page, currentPageSize)

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {

            if (filter.name === 'value' && filter.value !== '') {
                filters.push({
                    name: filter.name,
                    value: filter.value
                })
            }
            if (filter.type === 'multiselect') {
                if (filter.value.length > 0) {
                    if (filter.value[0]?.value !== undefined && filter.value[0] !== 0) {
                        filters.push({
                            name: filter.name,
                            values: filter.value.map(v => v.value)
                        })
                    }
                }
            }
            else if (filter.name === 'registerDate') {

                if (filter.valueStart != null && filter.valueEnd != null && filter.valueStart !== "1970-01-01T00:00:00.000Z" && filter.valueEnd !== '' && filter.valueEnd !== "1970-01-01T00:00:00.000Z") {
                    filters.push({
                        name: filter.name,
                        value: filter.valueStart + '|' + filter.valueEnd
                    })
                } else {
                    // clean the fields cause they are only considered when the both are filled
                    if ((filter.valueStart != null && (filter.valueEnd == null || filter.valueEnd === "1970-01-01T00:00:00.000Z")) || ((filter.valueStart == null || filter.valueStart === "1970-01-01T00:00:00.000Z") && filter.valueEnd != null)) {
                        var filtersCopy = [...tableFilters];
                        filter.valueStart = null;
                        filter.valueEnd = null;

                        setTableFilters([...filtersCopy]);
                        localStorage.setItem(localStorageFilterKey, JSON.stringify([...filtersCopy]));
                    }
                }
            }
            else if (filter.value !== '' && filter.value != null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value !== 'empty' ? filter.value : null
                })
            }
        });

        if (filters.length === 0 && initialFilters != null) {
            //is the first load
            setSortColumn('RegisterDate');
            sortColumn = 'RegisterDate';
            setSortDirection(1);
            orderDirection = 1;
        }

        var skip = 0;

        if (!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);

        if (skip === 0) {
            setCurrentPage(0);

            if (filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/survey/list", payload)
            .then(async response => {
                if (response?.data?.data === null) {
                    return await Alert("", "Falha ao buscar os dados", "error");
                }

                var items = response?.data?.data?.data.map(item => {
                    item.registerDate = new Date(item.registerDate).toLocaleDateString('pt-BR');
                    item.routeExpirationDate = new Date(item.routeExpirationDate).toLocaleDateString('pt-BR');
                    item.address = getAddressLink(item.address, item.latitude, item.longitude);
                    item.value = getMoneyFormat(item.value);
                    item.pointOfSaleName = item.pointOfSaleName;
                    item.pointOfSaleType = item.pointOfSaleType;
                    item.isActive = item.isActive;
                    return item;
                })

                setData(items);
                if (response?.data?.data?.recordsFiltered)
                    setRecordsFiltered(response.data.data.recordsFiltered);

                if (firstLoad)
                    setFirstLoad(false);

            }).finally(() => {
                setLoadingTable(false);
            });
    }

    const getFilterData = async (filters, filtersOfTable = tableFilters) => {
        // campaigns filtering
        var campaigns = await getCampaigns();

        let result = campaigns?.map(c => ({
            label: (<div className='combo-panel-select' style={{ wordBreak: c.name.split(' ')[0].length > 18 ? 'break-all' : 'normal' }}>
                <p>{c.name}&nbsp;</p>
                <b>{formatDate(new Date(c.startDate))} a {formatDate(new Date(c.endDate))}</b>
            </div>),
            value: c.value,
            text: c.text,
            clientId: c.clientId,
            clientName: c.clientName,
            show: true
        }))

        var validCampaignsIds = [];

        // delete current filter if campaign id does not exist in response list
        (campaigns).forEach(campaign => {
            var campaignSavedFilter = filters?.find(f => f.name === 'campaigns')?.value ?? [];
            if (campaignSavedFilter) {
                campaignSavedFilter.forEach(campaignIdSelected => {
                    if (campaignIdSelected.value === campaign?.id)
                        validCampaignsIds.push(campaignIdSelected);
                });
            }
        });

        let campaignFilter = filtersOfTable.find(f => f.name === 'campaigns');
        campaignFilter.options = result;
        campaignFilter.value = validCampaignsIds;

        setTableFilters([...filtersOfTable]);
    }

    const changeActiveStatus = async (survey, index) => {

        // can't active survey because the survey disabled could actually be enabled and his combo disabled or already visited
        if (!survey.isActive)
            return;

        await service.post(`/api/survey/active/${survey.id}/${!survey.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];

                    let item = items.find((it) => it.id == survey.id);
                    survey.active = !survey.active;
                    survey.isActive = !survey.isActive;
                    items[index] = item;
                    setData(items);

                    let change = !survey.isActive ? "inativada" : "ativada";
                    toast.success("Pesquisa " + change + " com sucesso!");
                } else {
                    let change = !survey.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " a pesquisa");
                }
            })
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);

        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem(surveysTableFilterKey);
        // campaigns filtering
        var campaigns = await getCampaigns();

        let result = campaigns?.map(c => ({
            label: (<div className='combo-panel-select' style={{ wordBreak: c.name.split(' ')[0].length > 18 ? 'break-all' : 'normal' }}>
                <p>{c.name}&nbsp;</p>
                <b>{formatDate(new Date(c.startDate))} a {formatDate(new Date(c.endDate))}</b>
            </div>),
            value: c.value,
            text: c.text,
            clientId: c.clientId,
            clientName: c.clientName,
            show: true
        }))

        let temporaryFilters = tableFilters.map(t => {
            if (t.name === 'campaigns') {
                t.value = []
                t.options.forEach(c => {
                    c.show = true;
                }
                );
            }
            else if (t.name === 'registerDate') {
                t.valueStart = null;
                t.valueEnd = null;
            }
            else
                t.value = '';
            return t;
        });
        var validCampaignsIds = [];

        // delete current filter if campaign id does not exist in response list
        (campaigns).forEach(campaign => {
            var campaignSavedFilter = temporaryFilters?.find(f => f.name === 'campaigns')?.value ?? [];
            if (campaignSavedFilter) {
                campaignSavedFilter.forEach(campaignIdSelected => {
                    if (campaignIdSelected.value === campaign?.id)
                        validCampaignsIds.push(campaignIdSelected);
                });
            }
        });

        let campaignFilter = temporaryFilters.find(f => f.name === 'campaigns');
        campaignFilter.options = result;
        campaignFilter.value = validCampaignsIds;

        console.log('temporaryFilters', temporaryFilters)

        temporaryFilters = setTableFilters([...temporaryFilters]);
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);
        await getSurveys();
        setLoadingTable(false);
    }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }
        localStorage.setItem(surveysTableFilterKey, JSON.stringify(tableConfig));
    }

    React.useEffect(() => {
        async function init() {
            var filtersResult = localStorage.getItem(localStorageFilterKey);
            var page = 0;
            var pageSize = 10;
            var sortColumn = null;
            var sortDirection = 0;

            var filters = [];

            if (filtersResult !== null) {
                const resultFilters = JSON.parse(filtersResult);
                filters = updateFilterValues(surveysFilter, resultFilters);
                setTableFilters(filters);
            }

            var tableConfigResult = localStorage.getItem(surveysTableFilterKey);

            if (tableConfigResult != null) {

                var tableConfig;
                if (tableConfigResult)
                    tableConfig = JSON.parse(tableConfigResult);

                if (tableConfig?.page) {
                    setCurrentPage(tableConfig.page);
                    page = tableConfig.page;
                }

                if (tableConfig?.pageSize) {
                    setPageSize(tableConfig.pageSize);
                    pageSize = tableConfig.pageSize;
                }

                if (tableConfig?.ordenation?.sortColumn) {
                    setSortColumn(tableConfig.ordenation.sortColumn);
                    sortColumn = tableConfig.ordenation.sortColumn;
                }

                if (tableConfig?.ordenation?.sortDirection) {
                    setSortDirection(tableConfig.ordenation.sortDirection);
                    sortDirection = tableConfig.ordenation.sortDirection;
                }
            }

            getFilterData();
            getSurveys(page, pageSize, sortColumn, sortDirection, null, filters);
        };
        init();
    }, []);

    return (
        <Fragment>
            <Breadcrumb title="Pesquisas" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <span className="d-flex">
                                    <h5>Listagem de Pesquisas</h5>
                                    <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                </span>
                                <div className="flex space-between">
                                    <DynamicFilter
                                        handleSearch={getSurveys}
                                        filters={tableFilters}
                                        localStorageName={localStorageFilterKey}
                                        handleReset={handleFilterReset}
                                        handleFilterChange={setTableFilters}
                                        saveFilterState={saveFilterConfig}
                                    />

                                    <div style={{ width: '100%' }}>
                                        {data != null && data.length > 0 && <GoogleApiWrapper
                                            showPDVTypeLegend={true}
                                            showCurrentLocation={false}
                                            setLatitude={() => { }}
                                            setLongitude={() => { }}
                                            lat={data[0].latitude} lon={data[0].longitude}
                                            searchedItems={data.map(i => { i.name = i.pointOfSaleName; i.PDVType = i.pointOfSaleType; return i; })}
                                            alwaysRender={true}></GoogleApiWrapper>}
                                    </div>
                                </div>
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhuma pesquisa encontrada</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                                <div className="loader-box loading" style={{ minHeight: '50vh' }}>
                                    <div className="loader">
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                    </div>
                                </div>
                            }

                            <div className="card-body datatable-react">
                                {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
                                    <Datatable
                                        multiSelectOption={false}
                                        myData={data}
                                        loading={loadingTable}
                                        firstLoad={firstLoad}
                                        pageSize={pageSize}
                                        pagination={true}
                                        class="-striped -highlight"
                                        columns={columns}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(recordsFiltered / pageSize)}
                                        setPageSize={setPageSize}
                                        onPageChange={getSurveys}
                                        changeStatus={() => { }}
                                        selectedData={null}
                                        manual={true}
                                        sortColumn={sortColumn}
                                        sortColumnDirection={sortDirection}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </Fragment>
    );
};

export default SurveysList;