import React, { Fragment, useState } from 'react';
import { Alert } from "../../components/common/modals/modals.js";
import axios from "axios";

import authorizedIcon from '../../assets/images/ic_authorized.png';
import { BASE_URL, useService } from "../../hooks/useService";
import { useAuth0 } from "@auth0/auth0-react";
import { Edit3 as Edit } from 'react-feather';
import { getAddressLink, updateFilterValues } from "../../utils/helper";
import { pdvsColumns as columns } from "../../utils/tablesHelper";
import ImportButton from '../../components/common/buttons/import';
import AuthorizePDVModal from './components/authorizeModal.js';
import ReportedErrorsModal from '../../components/common/reported-errors/reportedErrorsModal.js';

import UploadModal from '../../components/common/upload/modal';
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from "react-toastify";
import Datatable from "../../components/tables/table";
import "react-toastify/dist/ReactToastify.css";
import DynamicFilter from '../../components/common/filters/dynamic_filter.js';
import { pdvFilters } from './components/filters';
import { getPointOfSaleStatus, getPointOfSaleStatusClass } from "../../utils/validations";


const PDVList = (props) => {
    const service = useService();
    const { getAccessTokenSilently } = useAuth0();

    // import modal configuration
    const [uploadModal, setUploadModal] = useState();
    const [modalData, setModalData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [init, setInit] = React.useState(true);

    // authorize pdv modal state
    const [showAuthorizeModal, setShowAuthorizeModal] = useState(false);
    const [approveModalPDVId, setApproveModalPDVId] = React.useState(false);

    // errors modal state
    const [showReportErrorsModal, setShowReportErrorsModal] = useState(null);
    const [reportErrorPDVName, setReportErrorPDVName] = useState(null);

    // table state
    const localStorageFilterKey = 'pdv-filters';
    const [loadingTable, setLoadingTable] = useState(true);
    const [data, setData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const [itemsToDesconsider, setItemsToDesconsider] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [tableFilters, setTableFilters] = React.useState([...pdvFilters]);

    React.useEffect(() => {

        async function init() {
            const filtersResult = await localStorage.getItem(localStorageFilterKey); 
            let filters = [];
            if(filtersResult != null) {
                const resultFilters = JSON.parse(filtersResult);
                filters = updateFilterValues(pdvFilters, resultFilters);
                setTableFilters(filters);
            }

            var tableConfigResult = localStorage.getItem('pdv-table-config');
            var page = 0;
            var pageSize = 10;
            var sortColumn = null;
            var sortDirection = 0;

            if(tableConfigResult != null) {

                var tableConfig;
                if(tableConfigResult)
                    tableConfig = JSON.parse(tableConfigResult);
    
                if(tableConfig?.page){
                    setCurrentPage(tableConfig.page);
                    page = tableConfig.page;
                }
    
                if(tableConfig?.pageSize) {
                    setPageSize(tableConfig.pageSize);
                    pageSize = tableConfig.pageSize;
                }
                
                if(tableConfig?.ordenation?.sortColumn) {
                    setSortColumn(tableConfig.ordenation.sortColumn);
                    sortColumn = tableConfig.ordenation.sortColumn;
                }
    
                if(tableConfig?.ordenation?.sortDirection) {
                    setSortDirection(tableConfig.ordenation.sortDirection);
                    sortDirection = tableConfig.ordenation.sortDirection;
                }
            }            
            await getPDVs(page, pageSize, sortColumn, sortDirection, null, filters);
        }

        init();        
    }, []);

    const getAuthorizationColumn = (pdvId, authorization) => {

        return (<div className="hasTooltip">
            <div className="align-center pointer">
                <img onClick={() => {
                        setApproveModalPDVId(pdvId);
                        setShowAuthorizeModal(true);
                    }}
                    alt='authorize icon'
                    className={`authorization-image ${authorization == null && 'authorization-image-opacity'}`}
                    src={authorizedIcon}>                    
                </img>
            </div>

            {authorization != null && <div className="tooltip-pvd-parent ml-50">
                <div className="tooltip-pdv-item">
                    <h3>Detalhes</h3>
                    <span>Autorizado por: </span>
                    <span><b className="main-color">{authorization.authorizedByName}</b></span>

                    <br/>
                    <span>Em: </span>
                    <span><b className="main-color">{new Date(authorization.whenDate).toLocaleDateString('pt-BR')}</b></span>
                </div>
            </div>}
        </div>);
    }
    
    const getErrorsStyle = (pdvId, amount, pdvName) => {
        return <p className={amount > 0 && 'clickable-p pointer'}
            onClick={() => {
                if(amount > 0) {
                    setReportErrorPDVName(pdvName)
                    setShowReportErrorsModal(pdvId)
                }
            }}>{amount}</p>;
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);
        
        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem('pdv-table-config');
        
        setTableFilters(tableFilters.map(t => {
            t.value = '';
            return t;
        }))
        
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);
        
        await getPDVs();
        setLoadingTable(false);
    }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }
        localStorage.setItem('pdv-table-config', JSON.stringify(tableConfig));
    }

    const getPDVs = async(page = 0, currentPageSize = 10, sortColumnFilter = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            sortColumnFilter = null;

        setSortColumn(sortColumnFilter);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumnFilter, orderDirection, page, currentPageSize);

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {
            if(filter.value !== '' && filter.value !== null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value
                })
            }
        })

        var skip = 0;

        if(!isNaN(page)) {
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);            
        }

        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumnFilter,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/PointOfSale/list", payload)
        .then(async response => {
            if (response?.data?.data === null) {
                return await Alert("", "Falha ao buscar os dados", "error");
            }
            var items = response?.data?.data?.data.map((c, index) => {
                return {
                    id: c.id,
                    isActive: c.isActive,
                    name: c.name,
                    authorization: getAuthorizationColumn(c.id, c.authorization),
                    address: getAddressLink(c.address, c.latitude, c.longitude),
                    city: c.city,
                    state: c.state,
                    region: c.region,
                    latitude: c.latitude,
                    longitude: c.longitude,
                    reportedErrorsAmount: getErrorsStyle(c.id, c.reportedErrorsAmount, c.name),
                    ownerEmail: c.ownerEmail || '-',
                    ownerPhoneNumber: c.ownerPhoneNumber || '-',
                    status: <p className={`${getPointOfSaleStatusClass(c.pointOfSaleStatus)}`}>{getPointOfSaleStatus(c.pointOfSaleStatus)}</p>,
                    details: <div className="align-center pointer" onClick={() => {props.history.push(`/pdv/${c.id}`)}}><Edit /></div>,
                }
            })

            setData(items);
            if(response?.data?.data?.recordsFiltered)
             setRecordsFiltered(response.data.data.recordsFiltered);

        if (firstLoad)
            setFirstLoad(false);
            

        }).finally(() => {
            setLoadingTable(false);
        });

    }
    
    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
        setModalData(undefined);
        setLoading(false);
        setError(undefined);
        setInit(true);
    }
    
    const importProducts = async(file) => {
        setLoading(true);
        var formData = new FormData();
        formData.append("file", file);
        const accessToken = await getAccessTokenSilently();

        axios.put(`/api/PointOfSale/Import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              }
        })
        .then(response => {
            setLoading(false);
            if(response.data.status === 200){
                setModalData(response.data.data)
            }else{
                setError(response.data.title);
            }
            setInit(false);
          })
      };

    const changeActiveStatus = async (pointOfSale, index) => {

        await service.post(`/api/PointOfSale/active/${pointOfSale.id}/${!pointOfSale.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];

                    let item = items.find((it) => it.id == pointOfSale.id);
                    pointOfSale.active = !pointOfSale.active;
                    pointOfSale.isActive = !pointOfSale.isActive;
                    items[index] = item;
                    setData(items);

                    let change = !pointOfSale.isActive ? "inativado" : "ativado";
                    toast.success("Ponto de venda " + change + " com sucesso!");
                } else {
                    let change = !pointOfSale.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " o ponto de venda");
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb title="Pontos de Venda" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                        
                               <div className="flex space-between">
                               <span className="d-flex">                                    
                                    <h5>Listagem de Pontos de Venda</h5>
                                    <p className="pl-10 pt-3 total-legend">({recordsFiltered} itens)</p>
                                </span>
                                    <ImportButton value="Pontos de Venda" onclick={toggleUploadModal} />
                               </div>
                                <div className="flex space-between">
                                    <DynamicFilter 
                                        handleSearch={getPDVs}
                                        filters={tableFilters}
                                        localStorageName={localStorageFilterKey}
                                        handleReset={handleFilterReset}
                                        handleFilterChange={setTableFilters}
                                        saveFilterState={saveFilterConfig}
                                    />
                                </div>
                            </div>

                            {!loadingTable && data.length == 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhum PDV encontrado</div>
                                    <img className="empty-list-img" src={require("../../assets/images/none-selected.svg")} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                                <div className="loader-box loading" style={{ minHeight: '50vh' }}>
                                <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>
                            }

                            {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
                                    <Datatable
                                        multiSelectOption={false}
                                        firstLoad={firstLoad}
                                        loading={loadingTable}
                                        setLoading={setLoadingTable}
                                        pageSize={pageSize}
                                        columns={columns}
                                        myData={data}
                                        totalPages={Math.ceil(recordsFiltered/pageSize)}
                                        setPageSize={setPageSize}
                                        pagination={true}
                                        manual={true}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        class="-striped -highlight"
                                        changeStatus={changeActiveStatus}
                                        onPageChange={getPDVs}
                                        sortColumn={sortColumn}
                                        sortColumnDirection={sortDirection}
                                    />
                            </div>}
                            
                            <UploadModal value="Pontos de Venda" itemsToDesconsider={itemsToDesconsider} setItemsToDesconsider={setItemsToDesconsider} isOpen={uploadModal} loading={loading} toggle={toggleUploadModal} sendImport={importProducts} error={error} data={modalData} init={init} setInit={setInit} />
                        </div>
                    </div>
                </div>
            </div>

            <AuthorizePDVModal 
                isOpen={showAuthorizeModal}
                id={approveModalPDVId}
                toggle={ async (event, success = null) => {
                    setShowAuthorizeModal(false);
                    
                    if(success != null) {
                        if(success == true){
                            toast.success("Autorização de Ponto de Venda registrada com sucesso!");

                            await getPDVs(currentPage, pageSize);

                        }
                        else{
                            toast.error("Não foi possível completar sua solicitação, por favor tente novamente mais tarde!");
                        }
                    }
                }}
            />

            <ReportedErrorsModal 
                isOpen={showReportErrorsModal != null}
                id={showReportErrorsModal}
                pointOfSaleName={reportErrorPDVName}
                toggle={ async (event, success = null) => {
                    setShowReportErrorsModal(null);
                }}
            />

            <ToastContainer />
        </Fragment>
    );
};

export default PDVList;