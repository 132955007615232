import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Loading from "../../components/common/loading";
import { Alert } from "../../components/common/modals/modals";
import { ToastContainer } from "react-toastify";
import useForm from '../../hooks/useForm';
import { useParams, useLocation } from 'react-router-dom';
import { ROLES } from "../../utils/enums";
import { useService } from "../../hooks/useService";
import { getStorageUrl } from "../../config";
import Input from '../../components/common/form/input';
import TextArea from '../../components/common/form/textarea';
import MultiSelect from "../../components/common/form/multi-select/index";
import Select from "../../components/common/form/select";
import CampaignReport from "./report";
import UploadFile from "./components/upload-file";

const CategoryForm = ({ history }) => {

    const params = useParams();
    const location = useLocation();
    const service = useService();
    const [role, setRole] = React.useState(ROLES.CREATE);
    const [isNewRegister, setIsNewRegister] = React.useState(true);

    const name = useForm('name');
    const goal = useForm('goal');
    const endDate = useForm('endDate');
    const registerDate = useForm('date');
    const startDate = useForm('startDate');
    const description = useForm('description');
    const surveysAmount = useForm('surveysAmount');
    const pointOfSaleAmount = useForm('pointOfSaleAmount');
    const [authorizationFileUrl, setAuthorizationFileUrl] = React.useState();
    const [authorizationFile, setAuthorizationFile] = React.useState();
    const client = useForm('client', -1);
    const [showPowerBiDashboard, setShowPowerBiDashboard] = React.useState(false);

    const [pdvsOptions, setPdvsOptions] = React.useState([]);
    const [clientOptions, setClientOptions] = React.useState([]);
    const [productCategories, setProductCategories] = React.useState([]);
    const [selectedPdvsOptions, setSelectedPdvsOptions] = React.useState([]);
    const [selectedProductCategoriesOptions, setSelectedProductCategoriesOptions] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    const handleSubmit = async event => {
        event.preventDefault();

        name.validate();
        goal.validate();
        description.validate();
        surveysAmount.validate();
        pointOfSaleAmount.validate();
        startDate.validate();
        endDate.validate();
        client.validate();

        if (name.validate() && goal.validate() && description.validate() && surveysAmount.validate() && pointOfSaleAmount.validate() && startDate.validate() && endDate.validate() && client.validate()) {
            isNewRegister ? create() : update()
        }
    };

    const convertToSelectType = (obj) => obj?.map(o => ({
        label: o.name,
        value: o.id
    }))

    const getClients = async () => {
        var list;
        await service.get("/api/client/list")
            .then(async response => {
                list = response?.data?.data;
                setClientOptions([...list]);
            });
        return list;
    }

    const getCampaign = async (id, clients) => {
        await service.get(`/api/campaign/details/${id}`)
            .then(async (response) => {
                const result = response?.data?.data;

                if (result) {
                    name.setValue(result.name)
                    goal.setValue(result.goal)
                    description.setValue(result.description)
                    surveysAmount.setValue(parseInt(result.surveysAmount))
                    pointOfSaleAmount.setValue(parseInt(result.pointOfSaleAmount))
                    console.log(clientOptions)
                    if (clients?.some(client => client.id === result.clientId))
                        client.setValue(result.clientId)

                    let rd = new Date(result.registerDate)
                    registerDate.setValue(`${rd.getFullYear()}-${(rd.getMonth() + 1).toString().padStart(2, '0')}-${rd.getDate().toString().padStart(2, '0')}`)

                    let sd = new Date(result.startDate)
                    startDate.setValue(`${sd.getFullYear()}-${(sd.getMonth() + 1).toString().padStart(2, '0')}-${sd.getDate().toString().padStart(2, '0')}`)

                    let ed = new Date(result.endDate)
                    endDate.setValue(`${ed.getFullYear()}-${(ed.getMonth() + 1).toString().padStart(2, '0')}-${ed.getDate().toString().padStart(2, '0')}`)

                    setSelectedProductCategoriesOptions(convertToSelectType(result.productCategories))
                    setSelectedPdvsOptions(convertToSelectType(result.pointOfSaleTypes))
                    setAuthorizationFileUrl(result.authorizationFileUrl);
                    setShowPowerBiDashboard(result.showPowerBiDashboard);
                } else {
                    history.replace('/campaigns/list')
                }

                setLoading(false);
            })
            .catch(e => {
                history.replace('/campaigns/list')
            })
    }

    const getProductCategories = async () => {
        await service.get(`/api/ProductCategory/list`)
            .then(response => {
                const result = response?.data?.data;
                if (result) {
                    setProductCategories(result.map(r => ({
                        label: r.name,
                        text: r.name,
                        value: r.id
                    })))
                }
            })

        //TODO pointOfSaleTypes
    }
    const getPDVTypes = async () => {
        await service.get(`/api/PointOfSaleType/list`)
            .then(response => {
                const result = response?.data?.data;
                if (result) {
                    setPdvsOptions(result.map(r => ({
                        label: r.name,
                        text: r.name,
                        value: r.id
                    })))
                }
            })
    }

    const getPayload = () => {
        var formData = new FormData()
        formData.append("name", name.value);
        formData.append("goal", goal.value);
        formData.append("description", description.value);
        formData.append("surveysAmount", surveysAmount.value);
        formData.append("pointsOfSaleAmount", pointOfSaleAmount.value);
        formData.append("startDate", startDate.value);
        formData.append("endDate", endDate.value);
        formData.append("clientId", client.value);
        formData.append("authorizationFile", authorizationFile);
        selectedProductCategoriesOptions.forEach((option, i) => formData.append(`productCategoriesIds[${i}]`, option.value));
        selectedPdvsOptions.forEach((option, i) => formData.append(`pointOfSaleTypesIds[${i}]`, option.value));
        return formData;
    }

    const create = async () => {
        var payload = getPayload();
        await service.post(`/api/campaign/create`, payload)
            .then(response => {
                const success = response?.data?.status === 200;

                if (success) {
                    Alert("Campanha criada com sucesso!", "", "success");
                    history.replace(`/campaigns/list`);
                } else {
                    Alert("Não foi possível cadastrar a campanha", "", "error");
                }
            })
            .catch(error => {
                Alert("Não foi possível cadastrar a campanha", "", "error");
            })
    }

    const update = async () => {
        var payload = getPayload();
        payload.append("id", params.id);

        await service.put(`/api/campaign/edit`, payload)
            .then(response => {
                const success = response?.data?.status === 200;

                if (success) {
                    Alert("Campanha atualizada com sucesso!", "", "success");
                    //   history.replace(`/campaigns/list`);
                } else {
                    Alert(response.data.title ? response.data.title : "Não foi possível atualizar a campanha", "", "error");
                }
            })
    }

    React.useEffect(() => {
        async function init() {

            const create = location.pathname.indexOf("new") !== -1;
            let id = create ? undefined : params.id;
            getProductCategories();
            getPDVTypes();
            let clients = await getClients();

            if (id) {
                const edit = location.pathname.indexOf("details") === -1;
                setIsNewRegister(false);
                if (edit)
                    setRole(ROLES.EDIT);
                else
                    setRole(ROLES.READ);

                await getCampaign(id, clients);
            } else {
                setLoading(false);
            }
        }
        init()
    }, []);

    return (
        <main className='form-container campaign-form'>
            <div className="ml-5p">
                <Breadcrumb title={isNewRegister ? "Cadastro" : role === ROLES.READ ? 'Relatórios' : "Edição"} parent="Campanhas" marginLeft={role === ROLES.EDIT ? true : false} />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className={`card ${role !== ROLES.READ && 'form-card'}`}>
                            <div className="card-body">

                                {loading && <Loading />}

                                {!loading && <Fragment>
                                    <h1 className='align-center pt-15'>{role === ROLES.READ ? name.value : 'Campanha'}</h1>
                                    <div className="card-body">
                                        <form className="needs-validation" noValidate="" onSubmit={handleSubmit}>

                                            {role !== ROLES.READ && <Input label='Nome' type='text' name='name' required={true} {...name} maxLength={50} inputClassName='w-50vw' />}

                                            <TextArea label='Objetivo' type='text' rows={2} maxLength={100} name='goal' required={true} {...goal} disabled={role === ROLES.READ} />

                                            {showPowerBiDashboard && role === ROLES.READ && <CampaignReport campaignId={params.id} />}

                                            <Select
                                                label='Cliente contratante'
                                                name='client'
                                                required={true}
                                                {...client}
                                                options={clientOptions}
                                                disabled={role === ROLES.READ}
                                            />

                                            {/* <Select 
                                                label='Marca contratante'
                                                name='brand'
                                                required={true}
                                                {...brand}
                                                options={brandsOptions}
                                                disabled={role === ROLES.READ}
                                            /> */}

                                            <div className='grid-col2 mt-20 mb-20'>
                                                <div>
                                                    <label className='label-filter'>Categorias de Produto</label>
                                                    <div className={`${selectedProductCategoriesOptions.length > 0 && 'mt-10'} flex mb-10`}>
                                                        {selectedProductCategoriesOptions.map(p => <p className='tag-primary' key={p.value}>{p.label}</p>)}
                                                    </div>

                                                    {role !== ROLES.READ && <MultiSelect
                                                        options={productCategories}
                                                        overrideStrings={{
                                                            selectSomeItems: "Selecione",
                                                            allItemsAreSelected: "Todas as categorias",
                                                            selectAll: "Selecionar todas",
                                                            search: "Buscar",
                                                        }}
                                                        isLoading={false}
                                                        value={selectedProductCategoriesOptions}
                                                        onChange={setSelectedProductCategoriesOptions}
                                                        disabled={role === ROLES.READ}
                                                    />}
                                                </div>

                                                <div>
                                                    <label className='label-filter'>Tipos de Ponto de Venda</label>
                                                    <div className={`${selectedPdvsOptions.length > 0 && 'mt-10'} flex mb-10`}>
                                                        {selectedPdvsOptions.map(p => <p className='tag-primary' key={p.value}>{p.label}</p>)}
                                                    </div>
                                                    {role !== ROLES.READ && <MultiSelect
                                                        options={pdvsOptions}
                                                        overrideStrings={{
                                                            selectSomeItems: "Selecione",
                                                            allItemsAreSelected: "Todos os PDVs",
                                                            selectAll: "Selecionar todos",
                                                            search: "Buscar",
                                                        }}
                                                        isLoading={false}
                                                        value={selectedPdvsOptions}
                                                        onChange={setSelectedPdvsOptions}
                                                    />}
                                                </div>
                                            </div>


                                            {!isNewRegister && <Input label='Data de cadastro' type='date' name='registerDate' disabled={true} {...registerDate} inputClassName='w-20vw' />}

                                            <TextArea label='Descrição' type='text' name='description' rows={4} maxLength={250} required={true} {...description} disabled={role === ROLES.READ} />

                                            <div className="grid-col2">
                                                <Input label='Data de Início' type='date' name='startDate' required={true} {...startDate} inputClassName='w-20vw' disabled={role === ROLES.READ} />
                                                <Input label='Data de Término' type='date' name='endDate' required={true} {...endDate} inputClassName='w-20vw' disabled={role === ROLES.READ} />
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='Quantidade de Pesquisas' type='number' name='surveysAmount' required={true} {...surveysAmount} inputClassName='w-20vw' disabled={role === ROLES.READ} />
                                                <Input label='Quantidade de PDVs' type='number' name='pdvsAmount' required={true} {...pointOfSaleAmount} inputClassName='w-20vw' disabled={role === ROLES.READ} />
                                            </div>

                                            <div className="grid-col2">
                                                <div>
                                                    <label className='label-filter'>Documento de autorização (PDF)</label>
                                                    <br />
                                                    {authorizationFileUrl && <a href={`${getStorageUrl()}${authorizationFileUrl}`.replace('//campaign', '/campaign')} target='_blank'>Abrir documento</a>}

                                                    {role === ROLES.READ && !authorizationFileUrl && <p>Nenhum documento de autorização foi cadastrado para essa campanha</p>}
                                                    {role === ROLES.READ && !authorizationFileUrl && <a href={`${getStorageUrl()}campaign/DefaultAuthorizationForVisits.pdf`} target='_blank'>Abrir documento padrão</a>}

                                                    {role !== ROLES.READ && <UploadFile
                                                        handleFile={(file) => setAuthorizationFile(file)}
                                                        labelMessage={authorizationFileUrl ? 'Arraste o arquivo ou clique para alterar' : 'Arraste o arquivo ou clique para adicionar'}
                                                    />}
                                                </div>
                                            </div>

                                            <br />

                                            <div className='align-center'>
                                                {role !== ROLES.READ && <button className="btn btn-pill btn-outline-secondary" type="submit">{role === ROLES.EDIT ? "Atualizar" : "Cadastrar"}</button>}
                                            </div>

                                        </form>
                                    </div>
                                </Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </main>
    );
};

export default CategoryForm;