import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { X } from 'react-feather';

import './date-ranger-picker.css';
  
const DatePicker = ({ setCurrentStartDate, setCurrentEndDate, currentStartDate, currentEndDate}) => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [focusedInput, setFocusedInput] = React.useState();

  const handleWeekDays = (day) => {
    //Change week day with custom day array
    day._locale._weekdaysMin = ['D','S','T','Q','Q ','S ','S  '];
    day._locale._months = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
    return (<div className="day-container day-container-range">
      <div className="day-element">{(day.format('D'))}</div>
    </div>);
  }

  const falseFunc = ()=>false;

    return (
      <div className="calendar-data-range">
            <DateRangePicker
              isOutsideRange={falseFunc}
              customCloseIcon={<span className="close-icon-picker-date"><X /></span>}
              renderDayContents={handleWeekDays}
              daySize={35}
              hideKeyboardShortcutsPanel={true}
              enableOutsideDays={false}
              startDatePlaceholderText={currentStartDate}
              endDatePlaceholderText={currentEndDate}
              anchorDirection="left"
              startDate={startDate}
              endDate={endDate}
              minimumNights={0}
              showClearDates={true}
              displayFormat="DD/MM/YYYY"
              showDefaultInputIcon={true}
              startDateId="date-range-picker-start"
              endDateId="date-range-picker-end"
              onDatesChange={(target) => {
              setStartDate(target.startDate);
              setEndDate(target.endDate);

              setCurrentStartDate(new Date(target.startDate).toISOString());
              setCurrentEndDate(new Date(target.endDate).toISOString());
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              phrases={{
                chooseAvailableEndDate: function noRefCheck() {},
                chooseAvailableStartDate: function noRefCheck() {},
                dateIsSelected: function noRefCheck() {},
                dateIsSelectedAsEndDate: function noRefCheck() {},
                dateIsSelectedAsStartDate: function noRefCheck() {},
                dateIsUnavailable: function noRefCheck() {},
              }}
            />
      </div>
    )
}
export default DatePicker;