import React from 'react';
import ImageViewer from  './ImageViewer'

function Modal({ images, prefixCls, activeIndex, setActiveIndex, className, showIndex, showPreview, visible, setVisible, showProductButtons }) {

  const close = () => {
    setVisible(false);
    setActiveIndex(undefined);
  }

  return (
    <>
      {visible && (
        <div className="modal-images-gallery">
          <ImageViewer showPreview={showPreview} showIndex={showIndex} prefixCls={prefixCls} activeIndex={activeIndex} setActiveIndex={setActiveIndex} images={images} showProductButtons={showProductButtons} />
          <div className="close-button" onClick={close}></div>
        </div>
      )}
    </>
  )
}

export default Modal;