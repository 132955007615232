export const routeFilters = [
  {
      name: 'name',
      value: '',
      title: 'Nome do combo'
  }, 
  {
      name: 'POSName',
      value: '',
      title: 'Nome do PDV'
  }, 
  {
      name: 'numberOfSurveys',
      value: '',
      title: 'Pesquisas',
      type: 'number'
  }, 
  {
      name: 'registerDate',
      value: null,
      type: 'date',
      title: 'Data do cadastro'
  }, 

  {
      name: 'expirationDate',
      value: null,
      title: 'Data de expiração',
      type: 'date'
  }, 
]
