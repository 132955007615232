import React from "react";
import { useParams} from 'react-router-dom';
import CampaignReport from "../campaigns/report";

const CampaignReportPage = () => {

    const params = useParams();

    React.useEffect(() => {
    }, []);

    return (
        <main id="campaign-report">
          <CampaignReport campaignId={params.id} />
        </main>
    );
};

export default CampaignReportPage;