import React, { Fragment, useState } from 'react';
import { PlusCircle, XCircle } from 'react-feather';
import './style.css';

import authorizedIcon from '../../../assets/images/ic_authorized.png';

import Breadcrumb from '../../../components/common/breadcrumb';
import { Alert } from "../../../components/common/modals/modals.js";
import { generateCombosColumns as columns } from "../../../utils/tablesHelper";
import MultiSelect from "../../../components/common/form/multi-select/index";
import SimulationResponse from './simulation/simulation-response.js';
import SimulationModal from './simulation/simulation-modal.js';
import emptyList from '../../../assets/images/emptylist.png';
import Datatable from "../../../components/tables/table";
import { ToastContainer, toast } from "react-toastify";
import { useService } from "../../../hooks/useService";
import { getLocation, formatDate, states } from "../../../utils/helper.js";
import { useAuth0 } from "@auth0/auth0-react";
import "react-toastify/dist/ReactToastify.css";
import GoogleApiWrapper from '../../../components/common/pointer-locations-map/map-filter';
import Surveys from './surveys';

const CombosGenerate = () => {

    const [loading, setLoading] = useState(false);

    // table state
    const { user } = useAuth0();
    const [data, setData] = React.useState([]);
    const [selectedPdvs, setSelectedPdvs] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageCurrent, setCurrentPage] = React.useState(0);
    const [selectedTablePage, setSelectedTablePage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);

    // filter state
    const [pvdTypes, setPvdTypes] = React.useState([]);
    const [cityFilter, setCityFilter] = React.useState("");
    const [stateFilter, setStateFilter] = React.useState(-1);
    const [regionFilter, setRegionFilter] = React.useState("");
    const [typeFilter, setTypeFilter] = React.useState("0");
    const [distanceFilter, setDistanceFilter] = React.useState(0);
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);

    const [pdvValue, setPdvValue] = React.useState("");
    const [maxDistance, setMaxDistance] = React.useState(0);
    const [expirationDate, setExpirationDate] = React.useState("");
    const [selectedCampaigns, setSelectedCampaigns] = React.useState([]);
    const [selectedUserGroups, setSelectedUserGroups] = React.useState([]);

    // surveys
    const [surveys, setSurveys] = React.useState([]);
    const [brands, setBrands] = React.useState([]);
    const [campaigns, setCampaigns] = React.useState([]);
    const [usersGroups, setUsersGroups] = React.useState([]);

    // simulation
    const [simulationModal, setSimulationModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [confirmedModalData, setConfirmedModalData] = useState([]);
    const [confirmedSurveys, setConfirmedSurveys] = useState([]);
    const [openResponseModal, setOpenResponseModal] = useState(false);
    const service = useService();


    const addSurvey = () => {
        let dt = new Date();
        let newSurvey = {
            id: "newSurvey" + surveys.length + dt.getMilliseconds() + dt.getMinutes() + dt.getHours(),
            number: surveys.length +1,
            title: '',
            answerType: 0,
            brandId: null
        };

        var items = [...surveys];
        items.push(newSurvey);
        setSurveys([...items]);
    }

    const getBrands = async () => {
        await service.get("/api/brand/list")
        .then(async response => {
            setBrands(response.data.data);
        });
    } 

    const getCampaigns = async () => {
        await service.get("/api/campaign/simplelist")
        .then(async response => {

            let result = response?.data?.data?.map(c => ({
                label: (<div className='combo-panel-select'>
                    <p>{c.name}&nbsp;</p>
                    <b>{formatDate(new Date(c.startDate))} a {formatDate(new Date(c.endDate))}</b>
                </div>),
                value: c.id,
                text: c.name,
            }))

            setCampaigns(result);
        });
    }

    const getUsersGroups = async () => {
        await service.get("/api/usersgroup/simplelist")
        .then(async response => {

            let result = response?.data?.data?.map(c => ({
                label: c.name + ' usuários',
                value: c.id,
                text: c.name + ' usuários',
            }))

            setUsersGroups(result);
        });
    }

    const getAuthorizationColumn = (pdvId, authorization) => {

        return (<div className="hasTooltip">
            <div className="align-center">
                {/* // onClick={() => {
                //     setApproveModalPDVId(pdvId);
                //     setShowAuthorizeModal(true);
                // }} */}
                <img 
                    className={`authorization-image ${authorization == null && 'authorization-image-opacity'}`}
                    src={authorizedIcon}>                    
                </img>
            </div>

            {authorization != null && <div className="tooltip-pvd-parent ml-50">
                <div className="tooltip-pdv-item">
                    <h3>Detalhes</h3>
                    <span>Autorizado por: </span>
                    <span><b className="main-color">{authorization.authorizedByName}</b></span>

                    <br/>
                    <span>Em: </span>
                    <span><b className="main-color">{new Date(authorization.whenDate).toLocaleDateString('pt-BR')}</b></span>
                </div>
            </div>}
        </div>);
    }

    React.useEffect(() => {
        async function init() {
            getLocation(setLatitude, setLongitude);
            getBrands();
            addSurvey();
            getTypes();
            if(user){
                getCampaigns();
                getUsersGroups();
            }
        }
            init()
    }, [user]); 

    const getTypes = async () => {

        await service.get("/api/pointofsaletype/list")
        .then(async response => {
            if (response?.data?.status !== 200) {
                return await Alert("Por favor tente novamente mais tarde!", "Falha ao buscar os tipos de PDVs", "error");
            }
            setPvdTypes(response.data.data);
        });
    }

    const sendSimulation = async() => {

        if(selectedCampaigns.length === 0) {
            return await Alert("Por favor, selecione ao menos 1 campanha", "Dados inválidos", "error");
        }

        if(surveys.length === 0) {
            return await Alert("Por favor, cadastre ao menos 1 pergunta", "Dados inválidos", "error");
        }

        if(selectedPdvs.length === 0){
            return await Alert("Por favor, selecione ao menos 1 Ponto de Venda", "Dados inválidos", "error");
        }

        if(pdvValue === ""){
            await Alert("O valor por ponto de venda deve ser informado", "", "error");
            return;
        }

        if(maxDistance === 0 || maxDistance < 1){
            await Alert("Informe uma distância máxima entre Pontos de Venda em um mesmo combo válida", "", "error");
            return;
        }

        if(expirationDate === ""){
            await Alert("Por favor, informe uma data de expiração válida", "", "error");
            return;
        }

        var error = surveys.filter((s) => s.title === "");

        if(error.length > 0){
            error = [];
            return await Alert("Por favor, insira uma pergunta válida!", "Dados inválidos", "error");
        }

        if(surveys.filter((s) => s.productHeight < 0).length > 0) {
            return await Alert("A altura do produto deve ser maior ou igual à zero", "Dados inválidos", "error");
        }

        if(surveys.filter((s) => s.productWidth < 0).length > 0) {
            return await Alert("A largura do produto deve ser maior ou igual à zero", "Dados inválidos", "error");
        }

        var pointOfSales = selectedPdvs.map((pvd) =>(
            {
                id: pvd.id,
                latitude: pvd.latitude,
                longitude: pvd.longitude,
                city: pvd.city
            }
        ));

        var payload = {
            pointOfSales: pointOfSales,
            maxDistance: parseFloat(maxDistance)
        }

        setLoading(true);

        await service.post("/api/route/simulatecombos", payload)
        .then(async response => {

            if(response.status === 200){

                if(response.data.data === null){
                    Alert("Um erro ocorreu!", response.data.title, "error");
                    return;
                }

                var items = response?.data?.data?.map((item, i) => {

                    return {
                        id: item.name,
                        city: item.city,
                        pointOfSaleNumber: pdvsTooltip(item.pointOfSales, item.pointOfSales.length),
                        pointOfSales: item.pointOfSales,
                        distanceFix: item.distance.toFixed(2),
                        distance: item.distance,                        
                    }
                })
                setAllData(items);
                setSimulationModal(true);
            }
        }).finally(() => {
            setLoading(false);            
        });

    }

    const confirmCombo = async() => {                   
        
        if(confirmedModalData.length == 0){
            await Alert("Selecione ao menos um combo para prosseguir!", "", "error");
            closeSimulationModal();
            return;
        }

        if(confirmedModalData.length == 0){
            await Alert("Selecione ao menos um combo para prosseguir!", "", "error");
            closeSimulationModal();
            return;
        }

        var items = confirmedModalData.map((item) => {
            return {
                name: item.id,
                city: item.city,
                typeName: item.typeName,
                pointOfSales: item.pointOfSales,
                distance: item.distance
            }
        })

        var surveyList = surveys.map((survey) => 
        {
            return {
                title: survey.title,
                number: survey.number,
                answerType: parseInt(survey.answerType),
                value: parseInt(pdvValue),
                brandId: survey.brandId,
                productHeight: parseFloat(survey.productHeight),
                productWidth: parseFloat(survey.productWidth),
            }
        });
        
        var payload = {
            routes: items,
            questions: surveyList,
            expirationDate: expirationDate,
            campaignsIds: selectedCampaigns.map(c => c.value),
            usersGroupsIds: selectedUserGroups.map(c => c.value)
        }

        await service.post("/api/route/createcombos", payload)
        .then(async response => {

            if(response.status === 200){
                if(response.data.status == 200)
                    Alert("Combo(s) gerados com sucesso!", "", "success");
                else
                    Alert("Um erro ocorreu", response.data?.title, "error");
                // setConfirmedSurveys(response.data.data.questions);
                // setOpenResponseModal(true);

                var items = [];

                selectedPdvs.map(pdvsSelected => {

                    var dontSelected = true;
                    confirmedModalData.map(combo => {
                        combo.pointOfSales.map(pdvId => {

                            if(pdvsSelected.id == pdvId)
                                dontSelected = false;
                        })
                    })
                    
                    if(dontSelected)
                        items.push(pdvsSelected)
                })

                setSelectedPdvs([...items]);
                setConfirmedModalData([]);

            }else{ 
                Alert("Um erro ocorreu!", response.data.title, "error");
            }
            closeSimulationModal();
        })
    }

    const closeSimulationModal = () => {
        setAllData([...data, ...confirmedModalData])
        setConfirmedModalData([])
        setSimulationModal(false);
    }

    const pdvsTooltip = (pdvs, amount) => {

        return (<div className="hasTooltip">
            <p className="">{amount}</p>
            <div className="tooltip-pvd-parent">
            {
                pdvs.map((pvd, i) => {
                    var pvdInfo = selectedPdvs.find(slctd => slctd.id == pvd);
                    
                    return (<div className="tooltip-pdv-item" key={pvd.id}>
                        <h3>{pvdInfo.name}</h3>
                        <span>{pvdInfo.address}, {pvdInfo.city} - {pvdInfo.state} - {pvdInfo.region}</span>
                        <p>{pvdInfo.type}</p>
                    </div>)
                })
            }
            </div>
        </div>);
    }

    
    const getPVDs = async (currentPage = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0) => {

        setLoading(true);

        var filters = [];

        if(cityFilter !== "")
            filters.push({ name: 'city', value: cityFilter })

        if(regionFilter !== "")
            filters.push({ name: 'region', value: regionFilter })

        if(typeFilter !== "0")
            filters.push({ name: 'type', value: typeFilter })

        if(stateFilter !== -1 && stateFilter !== '-1')
            filters.push({ name: 'state', value: stateFilter })    

        if((latitude !== 0 && longitude !== 0) && (distanceFilter > 0)){
            filters.push({
                name: 'latitude',
                value: latitude.toString()
            })

            filters.push({
                name: 'longitude',
                value: longitude.toString()
            })

            filters.push({
                name: 'range',
                value: distanceFilter.toString()
            })
        }

        filters.push({ name: 'isActive', value: true })
            
        var payload = {
            "sortColumn": null,
            "sortColumnDirection": 0,
            // "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "pageSize": 1000,
            "skip": currentPage * (currentPageSize !== null ? currentPageSize : pageSize),
            "filters": filters
        }

        await service.post("/api/pointofsale/list", payload)
        .then(async response => {

            if(response.data.data == null){
                Alert("Um erro ocorreu!", "", "error");
                return;
            }

            var items = response?.data?.data?.data.map(c => {
                return {
                    id: c.id,
                    name: c.name,
                    region: c.region,
                    city: c.city,
                    type: c.typeName,
                    PDVType: c.typeName,
                    latitude: c.latitude,
                    longitude: c.longitude,
                    address: c.address,
                    state: c.state,
                    authorization: getAuthorizationColumn(c.id, c.authorization)
                }
            })

            setData(items);
            var totalAmount = response.data.data.recordsFiltered;
            setRecordsFiltered(totalAmount);   

            if (firstLoad)
                setFirstLoad(false);

        }).finally( () => {
            setLoading(false);            
        });
    }

    const isSelected = (items, key) => {
        if(items === undefined)
            return false;
 
        return items.find(item => item.id === key) !== undefined;
     };

    const handleTableCheckChange = (list, selecteds, setSelectedData) => {
        var currentList = list?.slice(pageCurrent * pageSize, (pageCurrent * pageSize) + pageSize);

        var selecteds = currentList.filter((item) => isSelected(selecteds, item.id));
        if(selecteds.length === pageSize){
            let filteredList = [...selectedPdvs];          

            selecteds.forEach((item) => {
                
                const index = filteredList.indexOf(item);
                
                if(index != undefined) {
                    filteredList.splice(index, 1);
                }
            });
            
            setSelectedData(filteredList);
            // toast.success("PDVs removidos da seleção");
        }      
        else{
            var tempList = [...selectedPdvs]

            currentList.forEach((item) => {
                if(!isSelected(selecteds, item.id))
                tempList.push(item);
            });

            setSelectedData([...tempList]);
            // toast.success("PDVs adicionados à seleção");
        }
    }
    
    return (
        <Fragment>
            <Breadcrumb parent="Combos" title="Gerar" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header color-primary">
                                <h2>Gerar Combos</h2> 
                                <div className="flex">                                
                                <div className="fields-filter">
                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="state">Estado</label>
                                        <br/>
                                        <select onChange={({target}) => setStateFilter(target.value)} defaultValue={stateFilter} style={{width: '190px'}}>
                                            <option value={-1}>Selecione</option>
                                            {states.map((option, index) =>
                                                <option key={option.value} value={option.value}>
                                                    {option.name}
                                                </option>
                                            )}
                                        </select>
                                        <br/>
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="city">Cidade</label>
                                        <br/>
                                        <input className="form-control" name="city" type="text" placeholder="Cidade" id="city" value={cityFilter} onChange={({target}) => setCityFilter(target.value)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="region">Região</label>
                                        <br/>
                                        <input className="form-control" name="region" type="text" placeholder="Região"  id="region" value={regionFilter} onChange={({target}) => setRegionFilter(target.value)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="type">Tipo</label>
                                        <br/>
                                        <select onChange={({target}) => setTypeFilter(target.value)} style={{width: '190px'}} defaultValue="0">
                                            <option value="0">Selecione</option>
                                            {pvdTypes.map((option, index) =>
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                            )}
                                        </select>
                                    </div>  

                                    <div className="centralize">
                                        <button className="btn btn-pill btn-outline-secondary" type="button" onClick={() => {
                                            setCurrentPage(0);
                                            getPVDs();
                                        }}>Filtrar PDVs</button>
                                    </div>
                                    
                                    </div>

                                    <div style={{width: '73%'}}>
                                        
                                        {latitude !== 0 && <GoogleApiWrapper showPDVTypeLegend={true} setLatitude={setLatitude} setLongitude={setLongitude} lat={latitude} lon={longitude} searchedItems={data} alwaysRender={true}></GoogleApiWrapper> }

                                        <div className="field-filter-item" style={{ marginTop: '15px' }}>
                                            <label className="label-filter" htmlFor="distance">Raio (metros)</label>

                                            <input className="form-control" name="distance" type="number" placeholder="Distância"  id="type" value={distanceFilter} onChange={({target}) => setDistanceFilter(target.value)} lat={{latitude}} lon={longitude} />
                                        </div>

                                    </div>
                               
                                </div>

                            </div>

                            {/* TABLES */}
                            <div className="combos-table-container">
                                <div className="table-item">
                                    <h3 className="color-primary">Pontos de Venda</h3>

                                    {data === null && !loading &&
                                        <div className="align-center">
                                            <div className="empty-list-txt">Realize uma busca para realizar a seleção!</div>
                                            <img className="empty-list-img" style={{paddingTop: '20px'}} src={emptyList} alt="empty list" />
                                        </div>
                                    }

                                    {data != null && !loading && data.length === 0 &&
                                    <div className="align-center">
                                        <div className="empty-list-txt">Nenhum Ponto de Venda encontrado</div>
                                        <br/>
                                        <img className="empty-list-img" src={emptyList} alt="empty list" />
                                    </div>
                                    }

                                    {loading &&
                                        <div className="loader-box loading">
                                            <div className="loader">
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                            </div>
                                        </div>
                                    }

                                    <div className="datatable-react" style={{display: loading ? 'none' : 'block'}}>
                                        {/* totalPages={Math.ceil(recordsFiltered/pageSize)} */}
                                        {data?.length > 0 && <Datatable
                                            multiSelectOption={true}
                                            myData={data}
                                            loading={false}
                                            pageCurrent={pageCurrent}
                                            pageSize={pageSize}
                                            pagination={true}
                                            class="-striped -highlight"
                                            columns={columns}
                                            totalPages={Math.ceil(data.length/pageSize)}
                                            setPageSize={setPageSize}
                                            selectedData={selectedPdvs}
                                            manual={false}
                                            handleTableCheckChange={() => handleTableCheckChange(data, selectedPdvs, setSelectedPdvs)}
                                            setSelectedData={setSelectedPdvs}  
                                            setCurrentPage={setCurrentPage} 
                                            currentPage={pageCurrent}
                                            checkboxDelete={false}     
                                        />}
                                    </div>
                                </div>

                                <div className="table-center">
                                    <div className="arrow-right"></div>
                                </div>

                                <div className="table-item">
                                    <h3 className="color-primary">Pontos de Venda Selecionados</h3>

                                    {selectedPdvs.length === 0 &&
                                    <div className="align-center">
                                        <div className="empty-list-txt">Nenhum PDV selecionado</div>
                                        <img className="empty-list-img" src={require("../../../assets/images/none-selected.svg")} alt="empty list" />
                                    </div>
                                    }

                                    {selectedPdvs.length > 0 && <div className="datatable-react">
                                        <br/>
                                        <br/>
                                        <Datatable
                                            myData={selectedPdvs}
                                            loading={loading}
                                            firstLoad={firstLoad}
                                            pageSize={pageSize}
                                            pagination={true}
                                            currentPage={selectedTablePage}
                                            setCurrentPage={setSelectedTablePage}
                                            class="-striped -highlight"
                                            columns={columns}
                                            totalPages={Math.ceil(selectedPdvs.length/pageSize)}
                                            listOf="PDV"
                                            setSelectedData={setSelectedPdvs}
                                            setPageSize={setPageSize}
                                            manual={false}
                                            removeOption={true}
                                            handleTableCheckChange={() => handleTableCheckChange(selectedPdvs, [], setSelectedPdvs)}
                                        />
                                    </div>}
                                </div>
                            </div>    

                            <br/>

                            <div className="table-item">
                                <div className="flex">
                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="value">Valor por PDV(R$)</label>
                                        <br/>
                                        <input className="form-control" name="value" type="number" placeholder="0.00" id="value" value={pdvValue} onChange={({target}) => setPdvValue(target.value)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="maxDistance">Distância máx. PDVs (m)</label>
                                        <br/>
                                        <input className="form-control" name="maxDistance" type="number" placeholder="Raio (metros)" id="maxDistance" value={maxDistance} onChange={({target}) => setMaxDistance(target.value)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="expirationDate">Expiração dos combos</label>
                                        <br/>
                                        <div className="flex">
                                            <input className="form-control" name="expirationDate" min={new Date().toISOString().split('T')[0]} type="date" id="expirationDate" value={expirationDate} onChange={({target}) => setExpirationDate(target.value)} />
                                            <span className="add-item" onClick={({target}) => setExpirationDate('')}> 
                                                <XCircle />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-item">
                                <h3 className="color-primary">Campanha{selectedCampaigns.length !== 1 && 's'}</h3>
                                {selectedCampaigns.length > 0 &&
                                    <div className='flex mb-10 mt-10'>
                                        {selectedCampaigns.map(p => <p className='tag-primary' key={p.value}>{p.label}</p>)}
                                    </div>
                                }

                                <div style={{maxWidth: '600px'}}>
                                    <MultiSelect
                                        options={campaigns}
                                        overrideStrings={{
                                            selectSomeItems: "Selecione",
                                            allItemsAreSelected: "Todas as campanhas",
                                            selectAll: "Selecionar todas",
                                            search: "Buscar",
                                        }}
                                        isLoading={false}
                                        value={selectedCampaigns}
                                        onChange={setSelectedCampaigns}
                                    />
                                </div>
                            </div>
                            
                            <div className="table-item">
                                <h3 className="color-primary">Grupo{selectedUserGroups.length !== 1 && 's'} de Usuários</h3>
                                {selectedUserGroups.length > 0 &&
                                    <div className='flex mb-10 mt-10'>
                                        {selectedUserGroups.map(p => <p className='tag-primary' key={p.value}>{p.label}</p>)}
                                    </div>
                                }

                                <div style={{maxWidth: '600px'}}>
                                    <MultiSelect
                                        options={usersGroups}
                                        overrideStrings={{
                                            selectSomeItems: "Selecione",
                                            allItemsAreSelected: "Todas os grupos de usuários",
                                            selectAll: "Selecionar todos",
                                            search: "Buscar",
                                        }}
                                        isLoading={false}
                                        value={selectedUserGroups}
                                        onChange={setSelectedUserGroups}
                                    />
                                </div>
                            </div>
                                                        
                            <div className="table-item">
                                <div className="flex pl-10">
                                    <h3 className="color-primary">Questionário</h3>
                                    <span className="add-item" onClick={addSurvey}> 
                                        <PlusCircle />
                                    </span>
                                </div>
                                
                                <Surveys items={surveys} setItems={setSurveys} brands={brands} />
                            </div>

                            <div className="centralize">
                                <button className="btn btn-pill btn-outline-secondary" type="button" onClick={sendSimulation}>Simular</button>
                            </div>
                            <br/>

                        </div>
                    </div>

                    {/* Simulation Modal */}
                    <SimulationModal 
                    isOpen={simulationModal} 
                    loading={false} 
                    toggle={closeSimulationModal} 
                    data={allData} 
                    setData={setAllData}
                    confirmCombo={confirmCombo} 
                    confirmedPDVs={confirmedModalData} 
                    setConfirmedPDVs={setConfirmedModalData} 
                    />

                    {/* Confirmation Modal */}
                    <SimulationResponse toggle={() => {setOpenResponseModal(false)}} isOpen={openResponseModal} loading={false} data={confirmedSurveys} status={200} />

                </div>
            </div>
            
            <ToastContainer />
        </Fragment>
    );
};

export default CombosGenerate;