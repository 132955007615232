import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Upload } from 'react-feather';

const UploadFile = ({labelMessage, handleFile}) => {
  const [file, setFile] = React.useState();

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }) => {
    setFile(file);
    handleFile(file);
  }

  return (
    <div className="import-file-button dz-message needsclick">
      <Dropzone
        accept={".pdf"}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent={<div key='file-uploader' className='flex alg-center'><Upload /> <p className='pl-10' style={{fontSize: '0.7em'}}>{labelMessage}</p></div>}
        styles={{
            dropzone: { minHeight: '60px', backgroundColor: 'white', margin: '5px 0px', textAlign: 'left', width: '20vw' },
            dropzoneActive: { color: '#4466f2' },
        }}
      />
    </div>
  );
}

export default UploadFile;

