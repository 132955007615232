import React from "react";

const Footer = props => {
    return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="footer-copyright">
                <p>Copyright 2020 © MerchanMe All rights reserved.</p>
            </div>
        </div>
</footer>
)};

export default Footer;