import React, { Fragment } from "react";
import { useParams, useLocation } from 'react-router-dom';
import Breadcrumb from "../../../components/common/breadcrumb";
import Loading from "../../../components/common/loading";
import { Alert } from "../../../components/common/modals/modals";
import { Switch } from '../../../components/common/switch/switch.js';
import { ToastContainer, toast } from "react-toastify";
import useForm from '../../../hooks/useForm';
import { ROLES } from "../../../utils/enums";
import { useService } from "../../../hooks/useService";
import Input from '../../../components/common/form/input';
import UsersSelectionModal from './components/usersSelectionModal'

const UsersGroupsForm = ({ history }) => {

    const params = useParams();
    const location = useLocation();
    const service = useService();
    const [role, setRole] = React.useState(ROLES.CREATE);
    const [isNewRegister, setIsNewRegister] = React.useState(true);

    const name = useForm('name');
    const [allowedToWorkOnRoute, setAllowedToWorkOnRoute] = React.useState(true);
    const registerDate = useForm('date');
    const [users, setUsers] = React.useState([]);

    const [showUsersSelectionModal, setShowUsersSelectionModal] = React.useState(false);

    const [loading, setLoading] = React.useState(true);

    const handleSubmit = async event => {
        event.preventDefault();

        name.validate();

        if (name.validate()) {
            isNewRegister ? create() : update()
        }
    };

    const getUsersGroup = async (id) => {
        await service.get(`/api/usersgroup/detail/${id}`)
            .then(async (response) => {
                const result = response?.data?.data;

                if (result) {
                    name.setValue(result.name);
                    setAllowedToWorkOnRoute(result.allowedToWorkOnRoute);

                    let rd = new Date(result.registerDate);
                    registerDate.setValue(`${rd.getFullYear()}-${(rd.getMonth() + 1).toString().padStart(2, '0')}-${rd.getDate().toString().padStart(2, '0')}`);

                    let usersResult = result.users.map(u => ({
                        id: u.id,
                        name: u.name,
                        username: u.username
                    }));
                    setUsers([...usersResult]);
                } else {
                    history.replace('/users/groups');
                }

                setLoading(false);
            })
            .catch(e => {
                history.replace('/users/groups');
            })
    }

    const getPayload = () => {
        return {
            name: name.value,
            allowedToWorkOnRoute: allowedToWorkOnRoute,
            usersId: users?.map(u => u.id)
        }
    }

    const create = async () => {
        var payload = getPayload();
        console.log('payload', payload)
        
        await service.post(`/api/usersgroup/create`, payload)
            .then(response => {
                const success = response?.data?.status === 200;

                if (success) {
                    Alert("Grupo de usuários criado com sucesso!", "", "success");
                    history.replace(`/users/groups`);
                } else {
                    Alert("Não foi possível cadastrar o grupo de usuários", "", "error");
                }
            })
            .catch(error => {
                Alert("Não foi possível cadastrar o grupo de usuários", "", "error");
            })
    }

    const update = async () => {
        var payload = getPayload();
        payload.id = params.id;

        await service.put(`/api/usersgroup/edit`, payload)
            .then(response => {
                const success = response?.data?.status === 200;

                if (success) {
                    Alert("Grupo de usuários atualizado com sucesso!", "", "success");
                } else {
                    Alert(response.data.title ? response.data.title : "Não foi possível atualizar o grupo de usuários", "", "error");
                }
            })
    }

    React.useEffect(() => {
        async function init() {

            const create = location.pathname.indexOf("new") !== -1;
            let id = create ? undefined : params.id;

            if (id) {
                const edit = location.pathname.indexOf("details") === -1;
                setIsNewRegister(false);
                if (edit)
                    setRole(ROLES.EDIT);
                else
                    setRole(ROLES.READ);

                await getUsersGroup(id);
            } else {
                setLoading(false);
            }
        }
        init()
    }, []);

    return (
        <Fragment>
            <main className='form-container campaign-form'>
                <div className="ml-5p">
                    <Breadcrumb title={isNewRegister ? "Cadastro" : role === ROLES.READ ? 'Relatórios' : "Edição"} parent="Grupos de Usuários" marginLeft={role === ROLES.EDIT ? true : false} />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className={`card ${role !== ROLES.READ && 'form-card'}`}>
                                <div className="card-body">

                                    {loading && <Loading />}

                                    {!loading && <Fragment>
                                        <h1 className='align-center pt-15'>{role === ROLES.READ ? name.value : 'Grupo de Usuários'}</h1>
                                        <div className="card-body">
                                            <form className="needs-validation" noValidate="" onSubmit={handleSubmit}>
    
                                                {!isNewRegister && <Input label='Data de cadastro' type='date' name='registerDate' disabled={true} {...registerDate} inputClassName='w-20vw' />}

                                                {role !== ROLES.READ && <Input label='Nome' type='text' name='name' required={true} {...name} maxLength={35} inputClassName='w-50vw' />}

                                                <div className="flex">
                                                    <Switch
                                                        handleToggle={() => setAllowedToWorkOnRoute(!allowedToWorkOnRoute)}
                                                        isOn={allowedToWorkOnRoute === true}
                                                        isOnColor="#01edca"
                                                        isOffColor="#ffc30f"
                                                        id="user-toggle" />
                                                    <label style={{ paddingLeft: '15px', marginTop: '-5px' }}>
                                                        {allowedToWorkOnRoute === true ? 'Permitido ' : 'Não permitido '} trabalhar em combos
                                                    </label>
                                                </div>

                                                <br/>

                                                <div className="flex pl-10">
                                                    <h2 className="">{users.length > 1 && users.length} Usuários</h2>
                                                    <span className="add-item clickable-p" style={{color: '#2484FF'}} onClick={() => setShowUsersSelectionModal(true)}> 
                                                        <b>{users.length > 0 ? 'editar' : 'adicionar'}</b>
                                                    </span>                                                    
                                                </div>

                                                <ul className='users-groups-form-userslist'>
                                                    {users?.map((user) => (
                                                        <li key={user.id}>
                                                            <b>{user.name}</b> <br/>
                                                            E-mail: {user.username} <br/>
                                                        </li>
                                                    ))}
                                                </ul>

                                                <div className='align-center'>
                                                    {role !== ROLES.READ && <button className="btn btn-pill btn-outline-secondary" type="submit">{role === ROLES.EDIT ? "Atualizar" : "Cadastrar"}</button>}
                                                </div>

                                            </form>
                                        </div>
                                    </Fragment>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </main>
            
            <UsersSelectionModal 
                isOpen={showUsersSelectionModal}
                users={users}
                toggle={ async (event, success = null, selectedUsers) => {
                    setShowUsersSelectionModal(false);
                    if(success !== null) {
                        if(success === true){
                            setUsers([...selectedUsers]);
                        }        
                        else {
                            toast.error("Um erro ocorreu durante a seleção de usuários.");
                        }
                    }
                }}
            />
        </Fragment>
    );
};

export default UsersGroupsForm;