import React from 'react';

const Input = ({ label, type, name, required, placeholder, value, onChange, error, onBlur, maxLength = null, disabled = false, breakLabelLine = false, inputClassName }) => {
  React.useEffect(() => {
  }, [error])
  return (
    <>
        <div className={`custom-input mb-20 label-filter ${error && 'field-error'}`}>
            <label htmlFor={name} className={`${required && "required"}`}>{label}</label>
            {breakLabelLine && <br/> }
            <input 
                id={name}   
                className={inputClassName}             
                name={name}
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                maxLength={maxLength}
            />
            {error && <p className='error-field'>{error}</p>}
        </div>
    </>
  );
};

export default Input;