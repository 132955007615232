import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutReport = ({data}) => {
    let importedLines = data!== undefined ? (data.rowsAffected + (data.rowsAffected >  1 ? ' Linhas importadas' : ' Linha importada')) : '';
    let nonImportedLines = data!== undefined ? (data.errorsCount + (data.errorsCount > 1 ? ' Linhas não importadas' : ' Linha não importada')) : '';

    const doughnutData = {
        labels:[importedLines, nonImportedLines],
        datasets: [{
            data:[data!== undefined ? data.rowsAffected : 1, data!== undefined ? data.errorsCount : 1],
            backgroundColor: [data!== undefined ? "#1ea6ec" : '#c1c3c7', data!== undefined ? "#c1c3c7" : '#c1c3c7']
        }]
    }
    const doughnutOption = {
        // responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            enabled: false
            // enabled:  data!== undefined
        },
        // hover: {mode: null},
        legend: {
            display: data!== undefined,
        },
        plugins: {
            labels: data !== undefined,
            datalabels: {
                display: false,
                color: 'white'
            }
        }
    }


    return (
        <div style={{height: '60vh', marginBottom: '10px'}}>
            <Doughnut data={doughnutData} options={doughnutOption}/>
        </div>
    );
};

export default DoughnutReport;