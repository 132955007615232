import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactJson from 'react-json-view'

const JsonModal = ({ value, isOpen, toggle, jsonContent }) => (
    <Modal isOpen={isOpen} toggle={() => toggle('')} className="modal-body" centered={true} style={{ maxWidth: '90%' }}>
        <ModalHeader toggle={() => toggle('')}>{value}</ModalHeader>
        <ModalBody>
            <ReactJson src={jsonContent} theme="summerfruit:inverted" displayDataTypes={false} />
        </ModalBody>
    </Modal>
);

export default JsonModal;