import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

import { combosColumns as columns } from "../../utils/tablesHelper";
import { updateFilterValues } from "../../utils/helper";
import ImportButton from '../../components/common/buttons/import';
import { Alert } from "../../components/common/modals/modals.js";
import UploadModal from '../../components/common/upload/modal';
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from "react-toastify";
import Datatable from "../../components/tables/table";
import { useService } from "../../hooks/useService";
import "react-toastify/dist/ReactToastify.css";
import DynamicFilter from '../../components/common/filters/dynamic_filter';
import emptyImage from '../../assets/images/emptylist.png';
import { routeFilters } from './filters';

import { useAuth0 } from "@auth0/auth0-react";

const ComboList = () => {
    // import modal state
    const { getAccessTokenSilently } = useAuth0();
    const service = useService();
    const [uploadModal, setUploadModal] = useState();
    const [modalData, setModalData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [init, setInit] = React.useState(true);

    // authorize pdv modal state
    // const [showAuthorizeModal, setShowAuthorizeModal] = useState(false);
    // const [approveModalPDVId, setApproveModalPDVId] = React.useState(false);

    // table state
    const [loadingTable, setLoadingTable] = useState(true);
    const localStorageFilterKey = 'combo-filters';
    const [data, setData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [tableFilters, setTableFilters] = React.useState([...routeFilters]);

    React.useEffect(() => {

        async function init() {
            const filtersResult = await localStorage.getItem(localStorageFilterKey); 

            var filters = [];
            if(filtersResult != null) {
                const resultFilters = JSON.parse(filtersResult);
                filters = updateFilterValues(routeFilters, resultFilters);
                setTableFilters(filters);  
            }

            var tableConfigResult = localStorage.getItem('combo-table-config');
            var page = 0;
            var pageSize = 10;
            var sortColumn = null;
            var sortDirection = 0;

            if(tableConfigResult != null) {

                var tableConfig;
                if(tableConfigResult)
                    tableConfig = JSON.parse(tableConfigResult);
    
                if(tableConfig?.page){
                    setCurrentPage(tableConfig.page);
                    page = tableConfig.page;
                }
    
                if(tableConfig?.pageSize) {
                    setPageSize(tableConfig.pageSize);
                    pageSize = tableConfig.pageSize;
                }
                
                if(tableConfig?.ordenation?.sortColumn) {
                    setSortColumn(tableConfig.ordenation.sortColumn);
                    sortColumn = tableConfig.ordenation.sortColumn;
                }
    
                if(tableConfig?.ordenation?.sortDirection) {
                    setSortDirection(tableConfig.ordenation.sortDirection);
                    sortDirection = tableConfig.ordenation.sortDirection;
                }
            }

            await getCombos(page, pageSize, sortColumn, sortDirection, null, filters);
        }

        init();
    }, []);

    const handleFilterReset = async() => {
        setLoadingTable(true);

        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem('combo-table-config');

        setTableFilters(tableFilters.map(t => {
            t.value = '';
            return t;
        }))

        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);

        getCombos();
        setLoadingTable(false);
    }

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
        setModalData(undefined);
        setLoading(false);
        setError(undefined);
        setInit(true);
    }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }

        localStorage.setItem('combo-table-config', JSON.stringify(tableConfig));
    }

    const importation = async(file) => {

        if(error !== undefined)
            setError(undefined);
        setLoading(true);
        var formData = new FormData();
        formData.append("file", file);
        const accessToken = await getAccessTokenSilently();

        axios.put(`/api/Route/Import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              }
        })
        .then(response => {
            setLoading(false);
            if(response.data.status === 200){
                setModalData(response.data.data)
            }else{
                setError(response.data.title);
            }
            setInit(false);
          })
        .catch(error => {
            setLoading(false);
            setError("Não foi possível realizar o upload");
        })
      };

      const changeActiveStatus = async (combo, index) => {

        await service.post(`/api/route/active/${combo.id}/${!combo.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];

                    let item = items.find((it) => it.id == combo.id);
                    combo.active = !combo.active;
                    combo.isActive = !combo.isActive;
                    items[index] = item;
                    setData(items);

                    let change = !combo.isActive ? "inativado" : "ativado";
                    toast.success("Combo " + change + " com sucesso!");
                } else {
                    let change = !combo.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " o combo");
                }
            })
    }

      const getCombos = async (page = 0, currentPageSize = 10, sortColumnFilter = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            setSortColumn(null);

        setSortColumn(sortColumnFilter);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumnFilter, orderDirection, page, currentPageSize);

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {            
            if(filter.value !== '' && filter.value != null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value
                })
            }
        })

        var skip = 0;

        if(!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);

        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/route/list", payload)
        .then(async response => {
            if (response?.data?.data === null) {
                return await Alert("", "Falha ao buscar os dados", "error");
            }

            var items = response?.data?.data?.data.map((c, index) => {
                return {
                    id: c.id,
                    name: c.name,
                    numberOfSurveys: c.numberOfSurveys,
                    isActive: c.isActive,
                    active: c.isActive,
                    registerDate: new Date(c.registerDate).toLocaleDateString('pt-BR'),
                    expirationDate: new Date(c.expirationDate).toLocaleDateString('pt-BR'),
                }
            })

            setData(items);
            if(response?.data?.data?.recordsFiltered)
             setRecordsFiltered(response.data.data.recordsFiltered);

        if (firstLoad)
            setFirstLoad(false);
            

        }).finally(() => {
            setLoadingTable(false);
        });
    }
    
    return (
        <Fragment>
            <Breadcrumb title="Combos" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <span className="d-flex">
                                    <h5>Listagem de Combos</h5>
                                    <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                </span>

                                <div>
                                    <Link to={'/combo/generate'}>
                                        <button className="btn btn-pill btn-outline-secondary" type="button">Gerar Combos</button>
                                    </Link>

                                    <ImportButton value="Combos" onclick={toggleUploadModal} />
                                </div>

                                <DynamicFilter 
                                    handleSearch={getCombos}
                                    filters={tableFilters}
                                    localStorageName={localStorageFilterKey}
                                    handleReset={handleFilterReset}
                                    handleFilterChange={setTableFilters}
                                    saveFilterState={saveFilterConfig}
                                />
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhum combo encontrado</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                            <div className="loader-box loading" style={{minHeight: '50vh'}}>
                                <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>
                            }

                            {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
                                <Datatable
                                        multiSelectOption={false}
                                        myData={data}
                                        loading={loadingTable}
                                        firstLoad={firstLoad}
                                        pageSize={pageSize}
                                        pagination={true}
                                        class="-striped -highlight"
                                        columns={columns}
                                        totalPages={Math.ceil(recordsFiltered/pageSize)}
                                        setPageSize={setPageSize}
                                        onPageChange={getCombos}
                                        selectedData={null}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        changeStatus={changeActiveStatus}
                                        manual={true}
                                    />
                            </div>}

                            <UploadModal value="Combos" isOpen={uploadModal} loading={loading} toggle={toggleUploadModal} sendImport={importation} error={error} data={modalData} init={init} setInit={setInit} />
                        </div>
                    </div>
                </div>
            </div>

            {/* <AuthorizePDVModal 
                isOpen={showAuthorizeModal}
                id={approveModalPDVId}
                toggle={ async (event, success = null) => {
                    setShowAuthorizeModal(false);
                    
                    if(success != null) {
                        if(success == true){
                            toast.success("Autorização de Ponto de Venda registrada com sucesso!");

                            await getPDVs();
                        }
                        else{
                            toast.error("Não foi possível completar sua solicitação, por favor tente novamente mais tarde!");
                        }
                    }
                }}
            /> */}

            <ToastContainer />
        </Fragment>
    );
};

export default ComboList;