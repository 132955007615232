import React from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import Footer from './common/footer';
import LoadingBar from './common/header-component/loadingBar';
import { UserContext } from '../context/UserContext';
// import ThemeCustomizer from './common/theme-customizer'
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const AppLayout = ({children}) => {
    return (
        <div>
            <UserContext>
                {(window.location.pathname.indexOf('campaigns') === -1 || window.location.pathname.indexOf('report')  === -1) ?
                    <div className="page-wrapper">
                        <div className="page-body-wrapper">
                            <LoadingBar />
                            <Header />
                            <Sidebar />
                            <div className="page-body">
                                {children}
                            </div>
                            <Footer />
                            {/*<ThemeCustomizer />*/}
                        </div>
                    </div>
                :
                    children
                }
                {/* <ToastContainer /> */}
            </UserContext>
        </div>
    );
}

export default AppLayout;