import { statesWithId } from "../../../utils/helper";

export const usersFilters = [
    {
        name: 'name',
        value: '',
        title: 'Nome'
    },
    {
        name: 'username',
        value: '',
        title: 'E-mail'
    },
    {
        name: 'phoneNumber',
        value: '',
        title: 'Telefone',
        type: 'phoneNumber'
    },
    {
        name: 'city',
        value: '',
        title: 'Cidade'
    },
    {
        name: 'state',
        value: -1,
        title: 'Estado',
        type: "select",
        options: statesWithId
    },
    {
        name: 'lastRequestWalletWithdraw',
        value: '',
        title: 'Pedido de pagamento (a partir de)',
        type: 'date'
    },
    {
        name: 'walletTotalAvailable',
        value: '',
        title: 'Valor disponível na carteira (a partir de)',
        type: 'money',
        width: '120px'
    }
];

export const usersGroupsFilters = [
    {
        name: 'name',
        value: '',
        title: 'Nome'
    },
    {
        name: 'amountOfUsers',
        value: '',
        title: 'Quantidade de usuários',
        type: 'number'
    },
    {
        name: 'allowedToWorkOnRoute',
        value: -1,
        title: 'Trabalhar em combos',
        type: 'select',
        options: [
            {
                name: 'Todos',
                value: -1
            },
            {
                name: 'Permitido',
                value: true
            },
            {
                name: 'Não permitido',
                value: false
            }
        ]
    }
];