import { VISITSSTATUS } from "./enums";

export  const getPercentColor = (percent) => {
    
    if(isNaN(percent))
        return;
    
    if(percent < 50)
        return 'error-color';
    else if(percent < 70)
        return 'yellow-color';
    else 
        return 'success-color';
}

export const getAiStatus = (status) => {
    switch(status) {
        case 0:
            return VISITSSTATUS[0];
        case 1:
            return VISITSSTATUS[1];
        case 2:
            return VISITSSTATUS[2];
        default:
            return '-';
    }
}

export const getPointOfSaleStatus = (status) => {
    switch(status) {
        case 0:
            return 'Excel';
        case 2:
            return 'App';
        default:
            return '-';
    }
}

export const getPointOfSaleStatusClass = (status) => {
    switch(status) {
        case 0:
            return 'visit-2';
        case 2:
            return 'visit-0';
        default:
            return '';
    }
}

export const getAiClass = (status) => {

    switch(status) {
        case 0:
            return 'error-color';
        case 1:
            return 'yellow-color';
        case 2:
            return 'success-color';
        default:
            return '';
    }

}