import styles from "./multiselect-style.css";

let styleElement;

export const injectStyles = () => {
  if (typeof document !== "undefined" && !styleElement) {
    styleElement = document.createElement("style");
    styleElement.innerHTML = styles;

    document.head.appendChild(styleElement);
  }
};