import React from 'react';

const Checkbox = ({ label, checked = false, name, required, placeholder, value, onChange, error, onBlur, disabled = false, inputClassName }) => {
  React.useEffect(() => {
  }, [error])
  return (
    <>
        <div className={`custom-input mb-20 label-filter ${error && 'field-error'}`}>
            <label htmlFor={name} className={`${required && "required"} checkmark-container`}>{label}
                <input 
                    id={name}   
                    className={inputClassName}             
                    name={name}
                    value={value}
                    type='checkbox'
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    checked={checked}
                />
                <span className="checkmark"></span>
            </label>
        </div>
    </>
  );
};

export default Checkbox;